import { Component, Input, ViewChild } from '@angular/core';
import Debug from 'debug';
import { IDGoodsInputDelegate, DGoodsInputComponent } from '../dgoods-input/dgoods-input.component';
import { IDGoodsTextAreaDelegate, DGoodsTextAreaComponent } from '../dgoods-textarea/dgoods-textarea.component';
const debug = Debug('dgoods:shared:DGoodsEmailConfirmationComponent');

export interface IDGoodsEmailConfirmationDelegate {
  onChangeEmail(value: DGoodsEmailConfirmationValue, id: string, element: DGoodsEmailConfirmationComponent): void;
}
export class DGoodsEmailConfirmationValue {
  constructor(public isValid: boolean, public value: string) { }
}

@Component({
  selector: 'dgoods-email-confirmation',
  templateUrl: './dgoods-email-confirmation.component.html',
  styleUrls: ['./dgoods-email-confirmation.component.scss']
})
export class DGoodsEmailConfirmationComponent implements IDGoodsInputDelegate, IDGoodsTextAreaDelegate {

  @Input() delegate?: IDGoodsEmailConfirmationDelegate;
  @Input() id?: string;
  @Input() caption?: string;
  @Input() captionConfirm?: string;
  @Input() yourName?: string;
  @Input() yourMessage?: string;
  @Input() hidden?: boolean;
  @Input() isProductPage?: boolean;
  @Input() style?: string;


  @ViewChild('input1', { static: true }) input1: DGoodsInputComponent;
  @ViewChild('input2', { static: false }) input2: DGoodsInputComponent;
  @ViewChild('input3', { static: false }) input3: DGoodsInputComponent;
  @ViewChild('input4', { static: false }) input4: DGoodsInputComponent;

  @Input() value1 = '';
  @Input() value2 = '';
  protected value3 = '';
  protected value4 = '';
  private regex: RegExp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

  onChangeTextArea(value: string, id: string, element: DGoodsTextAreaComponent): void {
    this.onChangeInputs(value, id, element);
  }
  onChange(value: string, id: string, element: DGoodsInputComponent): void {
    this.onChangeInputs(value, id, element);
  }
  onChangeInputs(value: string, id: string, element: DGoodsInputComponent | DGoodsTextAreaComponent): void {
    if (element === this.input1) {
      this.value1 = value;
    }
    if (element === this.input2) {
      this.value2 = value;
    }
    if (element === this.input3) {
      this.value3 = value;
    }
    if (element === this.input4) {
      this.value4 = value;
    }
    if (this.delegate) {
      if (this.isProductPage && this.regex.test(this.value1)) {
        debug('onChangeEmail is valid');
        this.delegate.onChangeEmail(new DGoodsEmailConfirmationValue(true, this.value1), this.id, this);
      } else if (this.value1.toLocaleLowerCase() === this.value2.toLocaleLowerCase() && this.regex.test(this.value1)) {
        debug('onChangeEmail is valid');
        this.delegate.onChangeEmail(new DGoodsEmailConfirmationValue(true, this.value1), this.id, this);
      } else {
        debug('onChangeEmail is NOT valid');
        this.delegate.onChangeEmail(new DGoodsEmailConfirmationValue(false, null), this.id, this);
      }
    } else {
      debug('onChangeEmail event not fired. There is no delegate attached to the component.');
    }
  }

  public getSender() {
    return this.value3;
  }

  public getMessage() {
    return this.value4;
  }
}
