import { Component, Injector, OnInit } from '@angular/core';
import { AdminFraudDetectionProductsProvider, IBlockedProductModel } from '@modeso/dgoods-lib-admin-fe';
import { BasePageComponent } from '../base.page';
import { take, first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PopUpComponent } from '../popUp/popUp';
import { UnBlockProductResponse } from '@modeso/dgoods-lib-admin-fe';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UserRoles } from '../../shared/enum/userrole.enum';
import Debug from 'debug';
import { PermissionHelper } from '../../shared/util/permission.helper';

const debug = Debug('modeso:dgoods-admin:BlockedProductsPage');
@Component({
    selector: 'app-page-blockedproducts',
    templateUrl: './blockedProducts.page.html',
    styleUrls: ['./blockedProducts.page.scss'],
})
export class BlockedProductsPage extends BasePageComponent implements OnInit {
    errorMessageInGetBlockedProducts = 'Couldn\'t get blocked products';
    errorMessageInUnBlockProduct = 'Error occured in unBlocking Product';
    productUnBlockedSuccsfullyMessage = 'Product unblocked successfully';
    noBlockedProductsMessage = 'No Blocked Products Exist';
    blockedProducts: IBlockedProductModel;
    noBlockedProductsExist = true;
    errorInGetBlockedProducts: boolean;
    errorInUnBlockProduct: boolean;
    productUnBlockedSuccesfully = false;
    chosenProductIndex: number;
    constructor(private injector: Injector, private blockedProductProvider: AdminFraudDetectionProductsProvider,
                public dialog: MatDialog,
                private localStorageService: LocalStorageService
        ) {
        super(injector);
    }

    ngOnInit() {
        this.getBlockedProducts();
    }

    onClick(productId: number) {
        this.chosenProductIndex = productId;
        const dialogRef = this.dialog.open(PopUpComponent, {
            data: {
                title: 'Unblock Product',
                confirmationMessage: 'Are you sure you want to unblock ' + this.blockedProducts.products[this.chosenProductIndex] + ' ?',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const isUnblockshop = this.blockedProducts.products[this.chosenProductIndex] === 'Shop' ? true : false;
                if (!isUnblockshop) {

                    this.subscriptions.push(this.blockedProductProvider
                        .unBlockProduct$(this.blockedProducts.products[this.chosenProductIndex]).pipe(first())
                        .subscribe((response: any) => {
                            if (response.message) {

                                if (this.blockedProducts.products.length === 0) {
                                    this.noBlockedProductsExist = true;
                                }
                                this.productUnBlockedSuccesfully = true;
                                this.errorInUnBlockProduct = false;
                            } else {
                                this.blockedProductProvider.getError$().subscribe((errorResponse: any) => {
                                    if (errorResponse) {
                                        if (errorResponse.status.toString() === '404') {
                                            this.errorInUnBlockProduct = true;
                                            this.errorMessageInUnBlockProduct = errorResponse.error.error;
                                        } else if (errorResponse.status.toString() === '500') {
                                            this.errorInUnBlockProduct = true;
                                            this.errorMessageInUnBlockProduct = errorResponse.error;
                                        }

                                    }
                                });
                            }
                        }));
                } else {


                    this.subscriptions.push(this.blockedProductProvider.unblockShop$()
                        .pipe(first()).subscribe((unblockShopResponse: UnBlockProductResponse) => {

                            this.errorInUnBlockProduct = false;
                            this.noBlockedProductsExist = true;
                            this.productUnBlockedSuccesfully = true;
                            this.blockedProducts.products = [];
                        }));

                    this.subscriptions.push(this.blockedProductProvider.getError$().subscribe((errorResponse: HttpErrorResponse) => {

                        if (errorResponse.status === 400) {
                            this.errorInUnBlockProduct = true;
                            this.errorMessageInUnBlockProduct = errorResponse.error.error;
                        } else if (errorResponse.status === 404) {
                            this.errorInUnBlockProduct = true;
                            this.errorMessageInUnBlockProduct = errorResponse.error.error;
                        } else if (errorResponse.status === 500) {
                            this.errorInUnBlockProduct = true;
                            this.errorMessageInUnBlockProduct = errorResponse.error;
                        }
                    }));
                }

            }

        });

    }

    getBlockedProducts() {
        this.subscriptions.push(
            this.blockedProductProvider.getBlockedProducts$().subscribe((products: IBlockedProductModel) => {
                if (products.products) {
                    if (products.products.length === 0) {
                        this.errorInGetBlockedProducts = false;
                        this.noBlockedProductsExist = true;
                    } else {
                        this.errorInGetBlockedProducts = false;
                        this.noBlockedProductsExist = false;
                        this.blockedProducts = products;
                    }
                } else {
                    this.blockedProductProvider.getError$().subscribe((errorResponse: any) => {
                        if (errorResponse) {
                            this.noBlockedProductsExist = false;
                            this.errorInGetBlockedProducts = true;
                            if (errorResponse.status.toString() === '404') {
                                this.errorMessageInGetBlockedProducts = 'Couldn\'t get blocked products';
                            } else if (errorResponse.status.toString() === '500') {
                                this.errorMessageInGetBlockedProducts = 'Server error occured,Couldn\'t get blocked products';
                            }
                        }
                    });
                }
            }),
        );
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

}
