import { Component, Injector } from '@angular/core';
import { BasePageComponent } from '../base.page';

@Component({
  selector: 'app-page-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage extends BasePageComponent {

  constructor(injector: Injector) {
    super(injector);
  }
}
