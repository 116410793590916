<div class="markdown-editor">
  <div class="form-wrapper">
    <mat-form-field class="full-width" appearance="fill">
      <textarea
        [formControl]="control"
        matInput
        placeholder="Enter Markdown Code here"
        rows="15"
      ></textarea>
      <mat-error *ngIf="control.invalid">{{errorMessage}}</mat-error>
    </mat-form-field>
    <div class="image-upload">
      <h3 style="margin: 5px 0px;">Insert an Image into the markdown:</h3>
      <div class="image-list-wrapper">
        <div class="image-list">
          <div class="image-list-item" *ngFor="let image of images" (click)="insertImage(image)">
            <img [src]="image" />
            <button class="delete-button" (click)="openDeleteConfirmationDialog($event, image)">&#10006;</button>
          </div>

          <div class="image-list-item upload-btn" (click)="imgFileDropElement.openFileSelector()">
            <img src="assets/svgs/add_photo.svg" alt="Upload new photo" />
          </div>
          <ngx-file-drop [hidden]="true" #imgFileDrop dropZoneLabel="Drop 8 here" (onFileDrop)="imgDropped($event)" [disabled]="isReadOnly">
             <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

             </ng-template>
          </ngx-file-drop>

        </div>
      </div>
    </div>

    <div>
      <button class="button-blue-color" *ngIf="!showPreview" (click)="togglePreview()">Show Preview</button>
      <button class="button-red-color" *ngIf="showPreview" (click)="togglePreview()" style="margin-bottom: 10px;" >Hide Preview</button>

    </div>
    <div class="preview full-width" *ngIf="showPreview" [innerHtml]="getHtml()"></div>
  </div>





</div>
