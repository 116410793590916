import {
  Component,
  Input,
  Renderer2,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsCheckboxComponent');
export interface IDGoodsCheckboxDelegate {
  onSelected(): void;
  onUnselected(): void;
}

@Component({
  selector: 'dgoods-checkbox',
  templateUrl: './dgoods-checkbox.component.html',
  styleUrls: ['./dgoods-checkbox.component.scss'],
})
export class DGoodsCheckboxComponent implements OnInit {
  @Input() delegate?: IDGoodsCheckboxDelegate;
  @Input() selected?: boolean = false;
  @Input() isProductPage?: boolean = false;
  @Input() name: string;
  @Input() checked?: boolean = false;
  @Output() checkBoxIsChecked = new EventEmitter();
  @ViewChild('rootelement', { static: true }) rootelement: ElementRef;
  @ViewChild('checkbox', { static: true }) checkbox: ElementRef;
  @ViewChild('label', { static: true }) label: ElementRef;

  constructor(private renderer: Renderer2) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    if (this.checkbox) {
      this.renderer.setAttribute(this.checkbox.nativeElement, 'name', this.name);
      this.renderer.setAttribute(this.checkbox.nativeElement, 'id', this.name);
      if (this.selected) {
        this.renderer.addClass(this.rootelement.nativeElement, 'selected');
      }
      this.renderer.setAttribute(this.label.nativeElement, 'for', this.name);
    }
    if (this.checked) {
      this.checkBoxIsChecked.emit();
    }
  }

  public onToggle() {

    this.selected = !this.selected;
    if (this.checkbox) {
      this.renderer.setProperty(this.checkbox.nativeElement, 'checked', this.selected);
    }
    if (this.delegate) {
      if (this.selected) {
        this.renderer.addClass(this.rootelement.nativeElement, 'selected');
        this.delegate.onSelected();
      } else {
        this.renderer.removeClass(this.rootelement.nativeElement, 'selected');
        this.delegate.onUnselected();
      }
    } else {
      if (this.selected) {
        this.renderer.addClass(this.rootelement.nativeElement, 'selected');
        debug('onSelected event not fired. There is no delegate attached to the component.');
      } else {
        this.renderer.removeClass(this.rootelement.nativeElement, 'selected');
        debug('onUnselected event not fired. There is no delegate attached to the component.');
      }
    }
  }
}
