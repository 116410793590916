import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dgoods-view-container',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class DGoodsViewContainerComponent {

}
