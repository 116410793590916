<mat-form-field class="chip-container" appearance="outline">
  <mat-label>{{title}}</mat-label>
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let item of selectedList"
      (removed)="remove(item)">
      {{item.name}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      #chipInput
      [formControl]="chipControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList">
      <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let opt of filteredList | async" [value]="opt">
      {{opt.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>