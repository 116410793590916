<div class="center-content">
  <h2 mat-dialog-title>Oupss... something went wrong.</h2>

  <p>
    {{ error }}
  </p>

  <mat-dialog-actions>
    <button mat-button (click)="confirm()">Ok</button>
  </mat-dialog-actions>
</div>

