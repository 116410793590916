<ng-container>
  <!-- <button style="margin: 1.5rem auto" (click)="createContentPage()">Create Content Page</button> -->

  <div *ngIf="contentPages">
    <table mat-table [dataSource]="contentPages" class="mat-elevation-z8 table">
      <ng-container matColumnDef="title">
        <th class="centered" mat-header-cell *matHeaderCellDef>Title</th>
        <td class="centered" mat-cell *matCellDef="let contentPage">{{ contentPage.title.en_us }}</td>
      </ng-container>

      <ng-container matColumnDef="content">
        <th class="centered" mat-header-cell *matHeaderCellDef>Content</th>
        <td class="centered" mat-cell *matCellDef="let contentPage">{{ contentPage.content.en_us | slice:0:20 }}{{ contentPage.content.en_us.length > 20 ? '...' : '' }}</td>
      </ng-container>

      <ng-container matColumnDef="sortingOrder">
        <th class="centered" mat-header-cell *matHeaderCellDef>Sorting Order</th>
        <td class="centered" mat-cell *matCellDef="let contentPage">{{ contentPage.sortId }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="centered" mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="centered" mat-cell *matCellDef="let contentPage">
          <button class="button-blue-color" (click)="editContentPage(contentPage)">Edit</button>
          <button class="publish-btn button-blue-color" (click)="togglePublish(contentPage)">
            {{ contentPage.published ? 'Unpublish' : 'Publish' }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</ng-container>
