<div mat-dialog-content>
    <form class="update-localization-by-key-form" [formGroup]="updateLocalizationByKeyForm">
        <div class="form-group">
            <label>Key</label>
            <div class="input-wrapper">
                <input id="key" class="form-control" type="text" formControlName="key">
            </div>
        </div>

        <div class="form-group">
            <label>de-ch</label>
            <div class="input-wrapper">
                <input id="de-ch" class="form-control" type="text" formControlName="de-ch">
            </div>
            <span [innerHtml]="updateLocalizationByKeyForm.get('de-ch').value | markdownToSafeHtml"></span>
        </div>

        <div class="form-group">
            <label>en-us</label>
            <div class="input-wrapper">
                <input id="en-us" class="form-control" type="text" formControlName="en-us">
            </div>
            <span [innerHtml]="updateLocalizationByKeyForm.get('en-us').value | markdownToSafeHtml"></span>
        </div>

        <div class="form-group">
            <label>fr-ch</label>
            <div class="input-wrapper">
                <input id="fr-ch" class="form-control" type="text" formControlName="fr-ch">
            </div>
            <span [innerHtml]="updateLocalizationByKeyForm.get('fr-ch').value | markdownToSafeHtml"></span>

        </div>

        <div class="form-group">
            <label>it-ch</label>
            <div class="input-wrapper">
                <input id="it-ch" class="form-control" type="text" formControlName="it-ch">
            </div>
            <span [innerHtml]="updateLocalizationByKeyForm.get('it-ch').value | markdownToSafeHtml"></span>

        </div>


    </form>
</div>
<div mat-dialog-actions class="dialog-buttons">
    <div class="action-button">
        <button mat-button (click)="onNoClick()" class="button">Cancel</button>
        <button mat-button [mat-dialog-close]="data" [disabled]="buttonStatus()" cdkFocusInitial (click)="save()"
            class="button">Confirm</button>
    </div>

</div>
