import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromActions from '../actions/admin-manageSpotLights.actions';
import { AdminManageSpotLightService } from '../services/admin-manageSpotLight.service';
import { ISpotLight } from '@modeso/types__dgoods-products';
import { Router } from '@angular/router';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminManageSpotLightEffects');

@Injectable()
export class AdminManageSpotLightEffects {
    constructor(private actions$: Actions, private service: AdminManageSpotLightService,private router:Router) {}

    getAllSpotLights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAllSpotLights.type),
            mergeMap(() => {
                return this.service.getAllSpotLights().pipe(
                    map((response: any) => {
                        return fromActions.onGetAllSpotLightsSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onGetAllSpotLightsFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    createSpotLight$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.createSpotLight.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.createSpotlight(payload).pipe(
                    map((response: any) => {
                        return fromActions.onCreateSpotLightSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onCreateSpotLightFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    successOnCreateSpotlight$ = createEffect(
        () =>
          this.actions$.pipe(
            ofType(fromActions.onCreateSpotLightSuccessfully.type,
                   fromActions.onUpdateSpotLightSuccessfully.type),
            tap((action: fromActions.IActionWithPayload<ISpotLight>) => {
                this.router.navigate(['/de-ch/spot-lights']);
            })
          ),
        { dispatch: false }
    );

    
    editSpotLight$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateSpotLight.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.updateSpotlight(payload).pipe(
                    map((response: any) => {
                        return fromActions.onUpdateSpotLightSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onUpdateSpotLightFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    deleteSpotLight$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.deleteSpotLight.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.deleteSpotlight(payload).pipe(
                    map((response: any) => {
                        return fromActions.onDeleteSpotLightSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onDeleteSpotLightFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    getSpotLightSetting$ = createEffect(() =>
    this.actions$.pipe(
        ofType(fromActions.getSpotLightSetting.type),
        mergeMap(() => {
            return this.service.getSpotLightSetting().pipe(
                map((response: any) => {
                    return fromActions.onGetSpotLightSettingSuccessfully({ payload: response });
                }),
                catchError((error: HttpErrorResponse) => {
                    return of(fromActions.onGetSpotLightSettingFailed({ payload: error }));
                }),
            );
        }),
    ),
);

editSpotLightSetting$ = createEffect(() =>
this.actions$.pipe(
    ofType(fromActions.updateSpotLightSetting.type),
    mergeMap((payload: any) => {
        debug(payload);
        return this.service.updateSpotLightSetting(payload.payload).pipe(
            map((response: any) => {
                return fromActions.onUpdateSpotLightSettingSuccessfully({ payload: response });
            }),
            catchError((error: HttpErrorResponse) => {
                return of(fromActions.onUpdateSpotLightSettingFailed({ payload: error }));
            }),
        );
    }),
),
);
    errorOnSpotLight$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    fromActions.onGetAllSpotLightsFailed.type,
                    fromActions.onCreateSpotLightFailed.type,
                    fromActions.onUpdateSpotLightFailed.type,
                    fromActions.onDeleteSpotLightFailed.type,
                    fromActions.onUpdateSpotLightSettingFailed.type
                ),
                tap((action: fromActions.IActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllCartErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    handleOnLoadAllCartErrors(error) {
        debug(error);
        return error;
    }
}
