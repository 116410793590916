import { TextDatails } from './translationByKeyResponse.model';

export class AllTranslationsResponse {
    public translations: AllTranslationsDetails[];
}

export class AllTranslationsDetails {
    public key: string;
    public text: TextDatails;
}
