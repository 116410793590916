import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsInputComponent');

export interface IDGoodsInputDelegate {
  onChange(value: string, id: string, element: DGoodsInputComponent): void;
}

@Component({
  selector: 'dgoods-input',
  templateUrl: './dgoods-input.component.html',
  styleUrls: ['./dgoods-input.component.scss']
})
export class DGoodsInputComponent implements OnInit {

  @Input() delegate?: IDGoodsInputDelegate;
  @Input() id?: string;
  @Input() caption?: string;
  @Input() captionHidden?: boolean;
  @Input() value? = '';

  ngOnInit() {
    if (this.value) {
      this.captionHidden = false;
    } else {
      this.captionHidden = true;
    }
  }
  onFocus() {
    if (this.value) {
      this.captionHidden = false;
    } else {
      this.captionHidden = true;
    }
    debug('focus');
  }
  onKeyUp(value) {
    if (value) {
      this.captionHidden = false;
    } else {
      this.captionHidden = true;
    }
    debug('keyup');
    debug(value);
  }
  onBlur(value: string) {
    debug('blur ' + value);
    this.value = value;
    if (this.value) {
      this.captionHidden = false;
    } else {
      this.captionHidden = true;
    }

    if (this.delegate) {
      this.delegate.onChange(this.value, this.id, this);
    } else {
      debug('onChange event not fired. There is no delegate attached to the component.')
    }
  }

}
