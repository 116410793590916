import Debug from 'debug';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromFeature from '../reducers/admin.reducer';
import * as fromActions from '../actions/admin-digitalvoucher.actions';
import { filter, Observable } from 'rxjs';
import { IDigitalVoucherConfigurator } from '@modeso/types__dgoods-products';
import { HttpErrorResponse } from '@angular/common/http';
const debug = Debug('dgoods-lib-admin-fe');
@Injectable({
    providedIn: 'root'
})
export class AdminDigitalVoucherConfiguratorProvider {
    digitalVoucher$: Observable<IDigitalVoucherConfigurator>;

    constructor(
        private store: Store<fromFeature.AppState>) {
            this.digitalVoucher$ = this.getDigitalVoucherByProductLineName$();
    }
    public getDigitalVoucherByProductLineName$(): Observable<IDigitalVoucherConfigurator> {
        return this.store.pipe(select(fromFeature.selectFeatureDigitalVoucher));
    }

    public dispatchGetDigitalVoucherByProductLineName(productLineName: string)  {
        this.store.dispatch(fromActions.onGetDigitalVoucherByProductLine({ payload: productLineName }));
    }

    public dispatchCreateOrUpdateDigitalVoucher(digitalVoucher: IDigitalVoucherConfigurator)  {
        this.store.dispatch(fromActions.onCreateOrUpdateDigitalVoucher({ payload: digitalVoucher }));
    }

    public getError$(): Observable<HttpErrorResponse> {
      return this.store.pipe(select(fromFeature.selectFeatureDigitalVoucherErrors),
          filter((error: HttpErrorResponse) => error != null)
      );
  }
}
