import { ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { SelectionModel } from '@angular/cdk/collections';
import { first } from 'rxjs/operators';
import { AdminManageProductContentProvider,
    ManageProductsResponse, ProductContentUpdateRequest  } from '@modeso/dgoods-lib-admin-fe';
import { HttpErrorResponse } from '@angular/common/http';
import Debug from 'debug';
// tslint:disable-next-line:max-line-length
import { TranslationsByKeyResponseDetails, UpdateContentByProductLineDialog, UpdateProductContentDialogData } from './updateContentByProductLineDialog/updateContentByProductLineDialog';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {ProductLineForManagementDto} from '@modeso/types__dgoods-products';
const debug = Debug('modeso:dgoods-admin:ProductContentManagmentPage');
@Component({
    selector: 'app-page-productContentManagment',
    templateUrl: './productContentManagment.page.html',
    styleUrls: ['./productContentManagment.page.scss']
})
export class ProductContentManagmentPage extends BasePageComponent implements OnInit {
    products: ProductLineForManagementDto[] = [];
    dataSource: MatTableDataSource<any>;
    selection = new SelectionModel<any>();

    isLocalizationsError = false;
    isServerError = false;

    manageContentColumns: string[] = ['select', 'Product', 'de-ch', 'en-us', 'fr-ch', 'it-ch'];
    localizationUpdatedNotificationMessage = 'Product is updated successfully';
    serverErrorMessage = 'Server Error';
    errorMessage: string;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;

    constructor(injector: Injector, private adminProductContentProvider: AdminManageProductContentProvider, public dialog: MatDialog,
                private _snackBar: MatSnackBar, private changeDetector: ChangeDetectorRef,
                private localStorageService: LocalStorageService) {
        super(injector);
    }

    ngOnInit() {
        this.getAllProducts();
    }

    getTwintDescription(product: ProductLineForManagementDto, locale: string) {
        const localizedContent = product.content.find(content => content.locale === locale);
        if (localizedContent) {
            if( localizedContent.twintDescription) {

                return localizedContent.twintDescription;
            } else {
                return localizedContent.defaultDescription;
            }
        } else {
            return '';
        }
    }



    getAllProducts() {
        this.subscriptions.push(
            this.adminProductContentProvider.getAllProducts$().subscribe((response: ManageProductsResponse) => {
                debug(response);
                this.isLocalizationsError = false;
                this.isServerError = false;
                this.products = response.products;
                this.dataSource = new MatTableDataSource<any>(this.products);
                this.changeDetector.detectChanges();
                this.dataSource.paginator = this.paginator;
            })
        );

        this.subscriptions.push(
            this.adminProductContentProvider.getError$().subscribe((errorResponse: HttpErrorResponse) => {

                if (errorResponse.status === 404) {
                    this.isServerError = false;
                    this.isLocalizationsError = true;
                    this.errorMessage = errorResponse.error.message;
                } else {
                    this.isLocalizationsError = false;
                    this.isServerError = true;
                }

            })
        );
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, null, {
        duration: 2000,
        panelClass: ['blue-snackbar']
        });
    }

    selectedRow() {
        if (!this.selection.selected[0]) {
        return null;
        }
        return this.selection.selected[0];
    }


    localizationButtonStatus() {
        return !this.selectedRow();
    }

    onUpdateContent(language: string) {
        const selectedProduct: ProductLineForManagementDto = this.selectedRow();
        const updateData: UpdateProductContentDialogData = new UpdateProductContentDialogData(selectedProduct, language);

        const dialogRef = this.dialog.open(UpdateContentByProductLineDialog, {
          width: '50%',
          data: updateData,
        });

        dialogRef.afterClosed().subscribe((result: TranslationsByKeyResponseDetails) => {

            debug(result);
            if (!result) {
                return;
            }

            const requestData = new ProductContentUpdateRequest(result.key, result.locale, result.text);

            this.subscriptions.push(
            this.adminProductContentProvider.updateContentByProductLine$(requestData).pipe(first())
              .subscribe((response: ManageProductsResponse) => {

                debug(response);
                this.isLocalizationsError = false;
                this.isServerError = false;
                this.products = response.products;
                this.dataSource = new MatTableDataSource<any>(this.products);
                this.changeDetector.detectChanges();
                this.dataSource.paginator = this.paginator;
                this.openSnackBar(this.localizationUpdatedNotificationMessage);
              })
          );

            this.subscriptions.push(
            this.adminProductContentProvider.getError$().pipe(first()).subscribe((errorResponse: HttpErrorResponse) => {

              if (errorResponse.status === 404) {
                this.isServerError = false;
                this.isLocalizationsError = true;
                this.errorMessage = errorResponse.error.message;
              } else {
                this.isLocalizationsError = false;
                this.isServerError = true;
              }

            })
          );
        });


    }

    hasReadPermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
        UserRoles.DIGITAL_VAUCHERS_ADMIN,
        UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
