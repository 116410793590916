import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminWhiteListingProductsProvider } from '@modeso/dgoods-lib-admin-fe';
import { AdminContentPageProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-content-page.provider';
import { AdminManageSpotLightsProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-manageSpotLight.provider';
import { IContentPageResponse, ProductsWithAvailablePricesDto, SpotlightTypes , ISpotLight } from '@modeso/types__dgoods-products';
import { mergeMap, retryWhen, Subscription, take, throwError, timer } from 'rxjs';
import { UploadService } from '../../../shared/services/upload.service';
import { IUploadURL } from '../../../shared/services/uploadUrl.model';
import { BasePageComponent } from '../../base.page';
import { CUSTOM_DATE_FORMATS, CUSTOM_MOMENT_FORMATS } from '../../../shared/util/dateFormats.helper';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular-material-components/moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
import { KeyValue } from '@angular/common';
import { FileUploadUtil } from '../../../shared/util/fileUtil';
import { spotlightInitValues } from './spotlightInitValues';


@Component({
  selector: 'app-create-spot-light',
  templateUrl: './create-spot-light.component.html',
  styleUrls: ['./create-spot-light.component.scss'],
  providers:[
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],

})
export class CreateSpotLightComponent extends BasePageComponent implements OnInit {
  spotLightForm: FormGroup;

  @ViewChild(NgxFileDropComponent, { static: false }) imgFileDropElement;

  products = [];
  contentPages: IContentPageResponse[];
  spotLight: ISpotLight;
  spotlightId: string;
  inProgress = false;
  showSeconds = false;
  urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/.*)?$/i;
  isReadOnly: boolean = false;
  errorMessageForImagesUpload: string = '';
  uploadError: boolean = false;
  images = {
    "de-ch":"",
    "en-us":"",
    "it-ch":"",
    "fr-ch":""
  };
  languages = { English: 'en-us',
                German:  'de-ch',
                Italian: 'it-ch',
                French:  'fr-ch'}

  selectedIndex=0;
  fileUtil: FileUploadUtil = new FileUploadUtil();
  spotlightSubscription: Subscription;


  constructor(public uploadService: UploadService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spotLightProvider: AdminManageSpotLightsProvider,
    private adminWhiteListingProvider: AdminWhiteListingProductsProvider,
    private contentPageProvider: AdminContentPageProvider,
    injector: Injector) {
    super(injector);
    this.spotlightId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this.loadWhiteListedProducts();
    this.loadContentPages();

    if (this.spotlightId) {
      this.spotlightSubscription = this.spotLightProvider.getSpotLightById$(this.spotlightId).subscribe((spotlight)=>{

        // spotlight is found in store
        if (spotlight) {
          this.initSpotlightForm(spotlight);

        } else {
          //spotlight come from BE
          this.spotLightProvider.fetchSpotLights();
        }
      })

    } else {
      this.spotLightProvider.getCopiedSpotlight().pipe(take(1)).subscribe((spotlight)=>{

        // user copied a spotlight
        if(spotlight){
          this.initSpotlightForm(spotlight);
        }else{
          const currentDate = new Date();
          this.initSpotlightForm({...spotlightInitValues, startFrom: currentDate});
        }
      })
    }

  }
  initializeBoolean(bool){
    if(bool === undefined){
      return true;
    }
    return bool;
  }
  initSpotlightForm(spotlightInit: ISpotLight){

    const spotlight = {...spotlightInit};

    this.spotLightForm = this.formBuilder.group({
      name: [spotlight.name, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      navigationType: [spotlight.navigationType? spotlight.navigationType :SpotlightTypes.PRODUCT, [Validators.required]],
      startFrom: [spotlight.startFrom ,  [Validators.required]],
      endAt: [spotlight.endAt , [Validators.required]],
      productLineId: [spotlight.productLineId],
      contentPageId: [spotlight.contentPageId],
      navigationUrl: [spotlight.navigationUrl],
      publishingState: [spotlight.published],
      displayOnAndroid: [this.initializeBoolean(spotlight.displayOnAndroid)],
      displayOnIOS: [this.initializeBoolean(spotlight.displayOnIOS)],
    });

    if(spotlight.image){
      this.images = {...spotlight.image};
      this.areImagesEmpty();
    }

    this.changeNavigateTo();
  }

  handleDateClicked(){
    console.log("handleDateClicked");

    this.startFrom.markAsTouched();
    this.endAt.markAsTouched();
  }

  changeNavigateTo() {
    this.clearValidation();
    if(this.navigationType.value === SpotlightTypes.PRODUCT){
      this.productLineId.setValidators(Validators.required);
    }else if (this.navigationType.value === SpotlightTypes.CONTENT){
      this.contentPageId.setValidators(Validators.required);
    }else{
      this.navigationUrl.setValidators([Validators.required,Validators.pattern(this.urlPattern)]);
    }
    this.updateValueAndValidity();
  }

  updateValueAndValidity() {
    this.productLineId.updateValueAndValidity();
    this.contentPageId.updateValueAndValidity();
    this.navigationUrl.updateValueAndValidity();
  }

  clearValidation() {
    this.productLineId.clearValidators();
    this.contentPageId.clearValidators();
    this.navigationUrl.clearValidators();
  }

  loadWhiteListedProducts() {
    this.products = [];
    this.adminWhiteListingProvider.getAllProductsInAllLanguages$()
      .pipe(take(2)).subscribe((allProducts: ProductsWithAvailablePricesDto[]) => {
        if (allProducts) {
          this.products = [...allProducts];
        }
      })
  }

  loadContentPages() {
    this.subscriptions.push(
      this.contentPageProvider.getAllContentPages().subscribe(
        (response) => {
          this.contentPages = response;
        }
      )
    );
  }

  onFileSelected(droppedFile: NgxFileDropEntry) {

    const selectedIndex = this.selectedIndex;
    this.uploadError = false;
    this.errorMessageForImagesUpload = "";

    const fileEntry = droppedFile[0].fileEntry as FileSystemFileEntry;

    fileEntry.file(async (file: File) => {

      if (!this.fileUtil.isFileSizeAllowed(file.size) || ! await this.fileUtil.validateFile(file)) {
        this.uploadError = true;
        this.errorMessageForImagesUpload = `Uploaded Image for (${Object.keys(this.languages)[selectedIndex].toLowerCase()}) is either unsupported/exceeded maxiumum size`;
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      const maxRetries = 3;
      const initialDelay = 1000;
      this.inProgress = true;

      this.uploadService.getUploadUrl(file.name, formData)
        .pipe(
          retryWhen(errors => {
            let retries = 0;
            return errors.pipe(
              mergeMap(error => {
                if (retries < maxRetries) {
                  retries++;
                  const delay = Math.pow(2, retries) * initialDelay;
                  return timer(delay);
                }
                return throwError(()=>error);
              }),
              take(maxRetries + 1)
            );
          }),
          take(1)
        ).subscribe((urlObj: IUploadURL) => {

          this.uploadService.uploadFile(urlObj.uploadUrl, file, urlObj.contentType)
          .pipe(take(2)).subscribe(image => {
            this.images[Object.values(this.languages)[selectedIndex]]=urlObj.fetchUrl;
            this.inProgress = false;
            this.uploadError = false;

          },
          (err) => {
            if (err.error.status === 'Bad Request') {
              this.errorMessageForImagesUpload = `Upload error: ${err.error.error} for ${Object.keys(this.languages)[selectedIndex].toLowerCase()} language`;
            } else {
              this.errorMessageForImagesUpload = `Something went wrong while uploading spotlight for ${Object.keys(this.languages)[selectedIndex].toLowerCase()} language`;
            }
            this.inProgress = false;
            this.uploadError = true;
          }
        );

        },
        (error) => {
          this.errorMessageForImagesUpload = `Something went wrong while uploading spotlight for ${Object.keys(this.languages)[selectedIndex].toLowerCase()} language`;
          this.inProgress = false;
          this.uploadError = true;
        });
    });
  }

  save() {

    const spotlight: ISpotLight = {
      productLineId: this.navigationType.value === SpotlightTypes.PRODUCT ? this.productLineId.value : undefined,
      contentPageId: this.navigationType.value === SpotlightTypes.CONTENT ? this.contentPageId.value : undefined,
      navigationUrl: this.navigationType.value === SpotlightTypes.URL ? this.navigationUrl.value : undefined,
      navigationType: this.navigationType.value,
      name: this.name.value,
      startFrom: this.startFrom.value,
      endAt: this.endAt.value,
      published: this.publishingState.value,
      displayOnAndroid: this.displayOnAndroid.value,
      displayOnIOS: this.displayOnIOS.value,
      image: {...this.images},
    }

    if (this.spotlightId) {
      spotlight.id = this.spotlightId;
      this.spotLightProvider.updateSpotLight(spotlight)
    } else {
      this.spotLightProvider.createSpotLight(spotlight);
    }
  }

  // check if images is uploaded for 4 langs
  areImagesEmpty(): boolean {
    return Object.values(this.images).some(value => !value);
  }

  cancel(): void {
    this.router.navigate(['/de-ch/spot-lights']);
  }

  onChangeImageTab(event){
    this.selectedIndex=event.index;
    this.uploadError = false;
    this.errorMessageForImagesUpload = ""
  }

  // to keep the sorting order of keyvalue in html for loop
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  get name() {
    return this.spotLightForm.get('name');
  }
  get navigationType() {
    return this.spotLightForm.get('navigationType');
  }
  get startFrom() {
    return this.spotLightForm.get('startFrom');
  }
  get endAt() {
    return this.spotLightForm.get('endAt');
  }
  get productLineId() {
    return this.spotLightForm?.get('productLineId');
  }
  get contentPageId() {
    return this.spotLightForm?.get('contentPageId');
  }
  get navigationUrl() {
    return this.spotLightForm?.get('navigationUrl');
  }

  get publishingState() {
    return this.spotLightForm?.get('publishingState');
  }

  get displayOnAndroid() {
    return this.spotLightForm?.get('displayOnAndroid');
  }

  get displayOnIOS() {
    return this.spotLightForm?.get('displayOnIOS');
  }

  public get spotlightTypes(): typeof SpotlightTypes {
    return SpotlightTypes;
  }

   // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
   ngOnDestroy(){
    if(this.spotlightSubscription){
      this.spotlightSubscription.unsubscribe();
    }
    this.spotLightProvider.removeCopiedSpotlightFromStore();
  }

}
