<ng-container>
  <div [formGroup]="contentPageForm">
    <div class="mb-2">ContentPage Details</div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>
          Sort ID
        </mat-label>
        <input formControlName="sortId" matInput />
        <mat-error> Sort ID is required </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>
          Icon
        </mat-label>
        <mat-select formControlName="icon" name="icon" [(ngModel)]="contentPage.icon" required>
          <mat-option *ngFor="let icon of icons" [value]="icon">
            <img
              src="{{getIconSvg(icon)}}"
              alt="previous"
              width="19px"
              height="19px"
            />
            {{icon}}
          </mat-option>
        </mat-select>
        <mat-error>
          Icon is required
        </mat-error>
      </mat-form-field>
    </div>

    <div>
    <mat-tab-group animationDuration="0ms" (selectedIndexChange)="languageTabSelectionChanged($event)" >
      <mat-tab *ngFor="let item of languagesTabsData" label="{{item.label}}"></mat-tab>
    </mat-tab-group>

    </div>

    <div *ngIf="selectedLanguage" style="margin-top: 15px;">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>
            Title
          </mat-label>
          <input formControlName="title" matInput />
          <mat-error *ngIf="updatedContentPage.title[selectedLanguage].length == 0">Title is required</mat-error>
          <mat-error *ngIf="updatedContentPage.title[selectedLanguage].length >= 30">Title is too long</mat-error>
        </mat-form-field>
      </div>

      <div style="margin-top: 15px;">
        <mat-label>
          Content
          <span class="input_required">*</span>
        </mat-label>
        <app-markdown-image-editor
          [delegate]="this"
          id="text"
          [markdown]="description"
          [control]="contentPageForm.get('content')"
          [images]="contentPage.images"
          (imageUploaded)="onImageUploaded($event)"
          (imageDeleted)="onImageDeleted($event)"
          (valueChanged)="onContentValueChanged($event)"
        >
      </app-markdown-image-editor>
      <div class="save-error">{{errorMessage}}</div>

      </div>
    </div>
  </div>

  <div class="save-buttons">
    <button class="btn btn-success save-button" [disabled]="contentPageForm.invalid || !validForm || !isUnsavedChanges" (click)="save()">Save</button>
    <button class="btn btn-secondary cancel-button" (click)="cancel()">Cancel</button>
  </div>

</ng-container>
