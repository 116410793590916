import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
@Component({
    selector: 'app-page-userManagement',
    templateUrl: './userManagement.page.html',
    styleUrls: ['./userManagement.page.scss']
})
export class UserManagementPage extends BasePageComponent implements OnInit {
    background: string = '#0076bf';
    navLinks = [
        {
            label: 'Admin Users',
            link: 'admin-users',
            index: 0
        }, {
            label: 'Add Admin Users',
            link: 'add-admin-user',
            index: 1
        }
    ];
    activeLink = this.navLinks[0].link;
    constructor(injector: Injector, private router: Router) {
        super(injector);

    }

    ngOnInit() {
        super.ngOnInit();
    }


}
