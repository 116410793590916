export class Blocked {
    public blockedProductsAnalytics: string[];
    public blockedShopAnalytics: string[];
    public fraudDetectionLimits: FraudDetetionLimitsDetails[];
    public totalAmountOfTodayPurchasing: number;
    public todayPurchasingAmountOfproductLines: TodayPurchasingAmountOfproductLines[];
    public todayPurchasingAmountOfUsers: TodayPurchasingAmountOfUsersDetails[]
    public productsLimit: ProductsLimitDetails[];

}

export class FraudDetetionLimitsDetails {
    public name: string;
    public value: number;
    public timeframeName?: string;
    public timeframeValue?: number;
}

export class TodayPurchasingAmountOfproductLines {
    public _id: string;
    public productLineEntityId: string[];
    public amount?: number;
    public count?: number;
}

export class TodayPurchasingAmountOfUsersDetails {
    public _id: string;
    public amount?: number;
    public count?: number;
}

export class ProductsLimitDetails {
    product: string;
    status: string;
    limits: number;
}
