import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from '../admin.config';
import Debug from 'debug';
import { ISpotLight } from '@modeso/types__dgoods-products';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminManageProductCategoryService');
@Injectable({
    providedIn: 'root',
})
export class AdminManageSpotLightService {
    private server = '';
    private microserviceName = 'products';
    private readonly spotlights = '/spotlights';
    private readonly manageSpotlights = '/manage';
    private readonly spotlightState = '/state';



    /**
     *
     * @param http
     * @param config
     */
    constructor(private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;        
    }

    getAllSpotLights() {        
        debug('getAllSpotLights');
        const url = `${this.server}${this.microserviceName}${this.spotlights}${this.manageSpotlights}`;
        debug(url);
        return this.http.get<any>(url);
    }

    createSpotlight(content: { payload: ISpotLight }) {
        debug('create', content);
        const url = `${this.server}${this.microserviceName}${this.spotlights}`;
        return this.http.post(url, content.payload);
    }

    updateSpotlight(content: { payload: ISpotLight }) {
        debug('update', content);
        const url = `${this.server}${this.microserviceName}${this.spotlights}/${content.payload.id}`;
        return this.http.put(url, content.payload);
    }

    deleteSpotlight(content: { payload: string }) {
        debug('delete', content);
        const url = `${this.server}${this.microserviceName}${this.spotlights}/${content.payload}`;
        return this.http.delete(url);
    }


    getSpotLightSetting()  {        
        debug('getSpotLightSetting');        
        const url = `${this.server}${this.microserviceName}${this.spotlights}${this.spotlightState}`;
        debug(url);
        return this.http.get<any>(url);
    }

    updateSpotLightSetting(payload) {
        debug('updateSpotLightSetting');
        const url = `${this.server}${this.microserviceName}${this.spotlights}${this.spotlightState}`;
        return this.http.post(url,payload);
    }
}
