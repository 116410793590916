import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { DigitalVoucherConfigAttributes } from '@modeso/types__dgoods-products';
import Debug from 'debug';
const debug = Debug('modeso:dgoods-admin:DropDownListConfiguratorComponent');
@Component({
  selector: 'app-drop-down-list-configurator',
  templateUrl: './drop-down-list-configurator.component.html',
  styleUrls: ['./drop-down-list-configurator.component.scss']
})
export class DropDownListConfiguratorComponent{

  @Input() title: string;
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() iterators;

  constructor() { }

  change($event: MatSelectChange) {
    if(this.name === 'displayLink') return;

    const textForm = this.form.get('text');
    for (const iterator of Object.values(textForm['controls'])) {
      const control: FormControl = iterator['controls'][this.name];
      if(control){
        if($event.value !== DigitalVoucherConfigAttributes.NoDisplay) {
          control.setValidators(Validators.required);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      }     
    }
  }

}
