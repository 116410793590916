import { Injectable } from '@angular/core';
import * as fromActions from './../actions/admin.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import Debug from 'debug';
import { LoginUser } from '../models/administration/login.model';
import { UserResponse } from '../models/administration/userResponse.model';
import { Observable } from 'rxjs';
import { ChangePassword } from '../models/administration/changePassword.model';
import { SignupUser } from '../models/administration/signup.model';
import { AdminUsersResponse, IAdminUserModel } from '../models/administration/usersResponse.model';
import { UserRolesResponse } from '../models/administration/userRolesResponse.model';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminProvider');

@Injectable({
    providedIn: 'root'
})
export class AdminProvider {

    constructor(
        private store: Store<fromFeature.AppState>) { }

    public getNewAccsessToken$(): void {
        this.store.dispatch(fromActions.getNewAccessToken());
    }

    public login$(user: LoginUser): Observable<UserResponse> {

        this.store.dispatch(fromActions.login({ payload: user }));
        return this.store.pipe(select(fromFeature.selectFeatureLogin), map((loginRespone: UserResponse) => {
            if (loginRespone) {
                return loginRespone;
            } else {
                debug('Login response if undefined or null');
                return undefined;
            }
        }));
    }

    public changePassword$(passwords: ChangePassword): Observable<UserResponse> {
        this.store.dispatch(fromActions.changePassword({ payload: passwords }));
        return this.store.pipe(select(fromFeature.selectFeatureLogin), map((changePasswordResponse: UserResponse) => {
            if (changePasswordResponse) {
                return changePasswordResponse;
            } else {
                debug('ChangePassword response is undefined or null');
                return undefined;
            }
        }));
    }

    public signup$(newUser: SignupUser): Observable<UserResponse> {
        this.store.dispatch(fromActions.signup({ payload: newUser }));
        return this.store.pipe(select(fromFeature.selectFeatureSignup), map((signupResponse: UserResponse) => {
            if (signupResponse) {
                return signupResponse;
            } else {
                debug('Signup response is undefined or null');
                return undefined;
            }
        }));
    }

    public logout(): void {
        this.store.dispatch(fromActions.logout());
    }

    public getError$(): Observable<any> {
        return this.store.pipe(select(fromFeature.featureError),
            map((error: any) => {
                return error;
            })
        );
    }


    public getAdminUsers$(): Observable<AdminUsersResponse> {
        this.store.dispatch(fromActions.getAdminUsers());
        return this.store.pipe(select(fromFeature.selectFeatureAdminUsers));
    }

    public updateAdminUser$(user: IAdminUserModel): Observable<AdminUsersResponse> {
        debug(user);
        this.store.dispatch(fromActions.updateUserById({ payload: user }));
        return this.store.pipe(select(fromFeature.selectFeatureAdminUsers));
    }

    public deleteAdminUser$(user: IAdminUserModel): Observable<AdminUsersResponse> {
        debug(user);
        this.store.dispatch(fromActions.deleteUserById({ payload: user }));
        return this.store.pipe(select(fromFeature.selectFeatureAdminUsers));
    }

    public getLoggedInUser$(): Observable<UserResponse> {
        return this.store.pipe(select(fromFeature.selectFeatureLogin), map((loginRespone: UserResponse) => {
            if (loginRespone) {
                return loginRespone;
            } else {
                debug('Login response if undefined or null');
                return undefined;
            }
        }));
    }

    public assertLogin$(): Observable<UserRolesResponse> {
        this.store.dispatch(fromActions.getUserRoles());
        return this.store.pipe(select(fromFeature.selectFeatureUserRoles));
    }

    public getUserRoles$(): Observable<UserRolesResponse> {
        return this.store.pipe(select(fromFeature.selectFeatureUserRoles));
    }
}
