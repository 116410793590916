import { Injectable } from '@angular/core';
import * as fromActions from '../actions/admin-sortingOrder.actions';
import * as fromFeature from '../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISortingOrder  } from '@modeso/types__dgoods-products';


const debug = Debug('modeso:dgoods-lib-admin-fe:AdminSortingOrderProvider');
@Injectable({
    providedIn: 'root'
})
export class AdminSortingOrderProvider {

    constructor(private store: Store<fromFeature.AppState>) { }


    public dispatchCreateOrUpdateSortingOrder(sortingOrder: ISortingOrder) {
        this.store.dispatch(fromActions.onCreatOrUpdateSortingOrder({payload: sortingOrder}));
        return this.store.pipe(select(fromFeature.selectSortingOrderState))
    }


    public getError$(): Observable<any> {
      return this.store.pipe(select(fromFeature.featureError),
          map((error: any) => {
              return error;
          })
      );
  }
}
