<div class="generate-variation-dialogue" style="width: 75vw">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <div mat-dialog-content style="width: 100%">
    <app-markdown-editor
      [delegate]="this"
      id="text"
      [markdown]="description"
    ></app-markdown-editor>
  </div>
</div>
