export interface UpdateProductSettingsRequest {
  productLineName: string;
  enforceTC: boolean;
  couponsEnabled: boolean;
  displayOnIOS: boolean;
  displayOnAndroid: boolean;
}

export class ProductSettingsUpdateResponse {
  public productLineName: string;
  public enforceTC: boolean;
  public couponsEnabled: boolean;
  public displayOnIOS: boolean;
  public displayOnAndroid: boolean;
}
