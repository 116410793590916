import { CategoryDto } from '@modeso/types__dgoods-products';

export class ProductCategoriesUpdateRequest {
    constructor( public productLineName: string, public categories: string[]) {}
}

export class ProductCategoriesUpdateResponse {
    public productLineName: string;
    public enforceTC: boolean;
    public couponsEnabled: boolean;
    public categories: CategoryDto[];
}


export class ProductCategoriesUpdateRequestModel {
  constructor(public categories: string[]) {}
}
