import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import Debug from 'debug';
import * as fromActions from "../actions/admin-sortingOrder.actions"
import { Router } from '@angular/router';
import { ISortingOrder  } from '@modeso/types__dgoods-products';
import { AdminSortingOrderService } from '../services/admin-sortingOrder.service';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminSortingOrderEffect');

@Injectable()
export class AdminSortingOrderEffect {
    constructor(private actions$: Actions, private service: AdminSortingOrderService, private router: Router) { }


    creatOrUpdateSortingOrder$ = createEffect(
        () => this.actions$.pipe(
          ofType(fromActions.onCreatOrUpdateSortingOrder.type),
          mergeMap(
            (action: fromActions.IActionWithPayload<ISortingOrder>) => {
              return this.service.createOrUpdateSortingOrder(action.payload)
                .pipe(
                  map(
                    (response) => {
                      return fromActions.onCreatOrUpdateSortingOrderSuccessfully({ payload: response });
                    },
                  ),
                  catchError((error) => {
                    return of(fromActions.onCreatOrUpdateSortingOrderFailed({ payload: error }));
                  }),
                );
            },
          ),
        ),
      );
    
      errorOnCreatOrUpdateAffiliateSortingOrder$ = createEffect(
        () => this.actions$.pipe(
          ofType(fromActions.onCreatOrUpdateSortingOrderFailed.type),
          tap(
            (action: fromActions.IActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
          ),
        ), { dispatch: false },
      );
    

    handleOnLoadAllErrors(error) {
        debug(error);
        return error;
    }
}

