<h1 mat-dialog-title>Edit Category</h1>
<div mat-dialog-content>
  <form #form="ngForm" (ngSubmit)="submit()">
    <mat-form-field>
      <mat-label>Icon</mat-label>
      <mat-select name="icon" [(ngModel)]="category.icon" required>
        <mat-option *ngFor="let icon of icons" [value]="icon">
          <img
            src="{{getIconSvg(icon)}}"
            alt="previous"
            width="19px"
            height="19px"
          />
          {{icon}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Text (en-us)</mat-label>
      <input matInput name="text.en-us" [(ngModel)]="category.text['en-us']" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Text (de-ch)</mat-label>
      <input matInput name="text.de-ch" [(ngModel)]="category.text['de-ch']" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Text (fr-ch)</mat-label>
      <input matInput name="text.fr-ch" [(ngModel)]="category.text['fr-ch']" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Text (it-ch)</mat-label>
      <input matInput name="text.it-ch" [(ngModel)]="category.text['it-ch']" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Number for Sorting</mat-label>
      <input matInput name="orderNumber" [(ngModel)]="category.orderNumber" required>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [disabled]="form.invalid" (click)="submit()">Save</button>
</div>

