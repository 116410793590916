import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, retry, tap, throttleTime } from 'rxjs/operators';

import Debug from 'debug';
import * as fromActions from "../actions/admin-merchant.actions"
import { AdminMerchantService } from '../services/admin-merchant.service';
import { IMerchant } from '@modeso/types__dgoods-products';
import { Router } from '@angular/router';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminMerchantEffect');





@Injectable()
export class AdminMerchantEffect {
    constructor(private actions$: Actions, private service: AdminMerchantService, private router: Router) { }


    getAllMerchants$ = createEffect(
      () => this.actions$.pipe(
        ofType(fromActions.getAllMerchants.type),
        mergeMap(
          () => {
            return this.service.getAllMerchants()
              .pipe(
                retry(1),
                map(
                  response => (fromActions.onGetAllMerchantsSucceeded({ payload: response })),
                ),
                catchError((error) => of(fromActions.onGetAllMerchantsFailed({ payload: error })))
              );
          }
        )
      )
    );

    createOrUpdateMerchant$ = createEffect(
      () => this.actions$.pipe(
        ofType(fromActions.createOrUpdateMerchant.type),
        throttleTime(1000),
        mergeMap(
          (action: fromActions.IActionWithPayload<IMerchant>) => {
            return this.service.createOrUpdateMerchant(action.payload)
              .pipe(
                // retry(1),
                map(
                  response => (fromActions.onCreateOrUpdateMerchantSuccessfully({ payload: response })),
                ),
                catchError((error) => of(fromActions.onCreateOrUpdateMerchantFailed({ payload: error })))
              );
          }
        )
      )
    );

    errorOnCreatOrUpdateMerchant$ = createEffect(
      () => this.actions$.pipe(
        ofType(fromActions.onCreateOrUpdateMerchantFailed.type),
        tap(
          (action: fromActions.IActionWithPayload<any>) => {
            this.handleOnLoadAllErrors(action.payload)
          }),
      ), { dispatch: false },
    );

    onCreatOrUpdateMerchantSuccess$ = createEffect(
      () => this.actions$.pipe(
        ofType(fromActions.onCreateOrUpdateMerchantSuccessfully.type),
        tap(
          (action: fromActions.IActionWithPayload<any>) => {
            this.router.navigateByUrl('de-ch/merchant-list');
          }),
      ), { dispatch: false },
    );


    handleOnLoadAllErrors(error) {
        debug(error);
        return error;
    }
}

