export class DefaultFraudLimitsRequest {
    public fraudLimit: string;
    public fraudLimitValue: number;
}

export class DefaultFraudLimit {
    public fraudLimit: string;
    public fraudLimitDescription: string;
    public fraudLimitValue: number;
}
