import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownInputComponent } from './markdown/markdown-input.component';
import { MarkdownEditorComponent } from './markdown/markdown.component';
import { MatFormFieldModule , MatFormFieldControl} from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MarkdownEditorWithImageComponent } from './markdown/markdownWithImages/markdown.component';

import { NgxFileDropModule } from 'ngx-file-drop';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
      MarkdownEditorComponent,
      MarkdownInputComponent,
      MarkdownEditorWithImageComponent
    ],
    providers: [
      MarkdownEditorComponent,
      MarkdownEditorWithImageComponent,
    ],
    imports: [
      ReactiveFormsModule,
      FormsModule,
      CommonModule,
      MatFormFieldModule,
      MatInputModule,
      NgxFileDropModule,
      MatButtonModule,

    ],
    exports: [
      MarkdownEditorComponent,
      MarkdownInputComponent,
      MarkdownEditorWithImageComponent,

    ]
})
export class SharedModule { }
