<div class="container" *ngIf="!hasReadPermission()">
  Sorry you don't have read permission.
</div>
<div class="container" *ngIf="hasReadPermission()">
  <div class="row">
    <h3>
      Closed User Group
    </h3>
    <div class="table-container">
      <div>
        <mat-form-field style="width: 270px;">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="CustomerUuid">
        </mat-form-field>

      </div>
      <table mat-table [dataSource]="dataSource">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell fxFlex="100px" *matHeaderCellDef>
          </th>
          <td mat-cell fxFlex="100px" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Key Column -->
        <ng-container matColumnDef="CustomerUuid">
          <th mat-header-cell *matHeaderCellDef> CustomerUuid </th>
          <td mat-cell *matCellDef="let element"> {{element.customerUuid}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="manageColumns;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: manageColumns;" (click)="selection.toggle(row)">
        </tr>
      </table>
      <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>

  <div class="row">
    <div class="messages">
      <span class="failure" *ngIf="isError">{{errorMessage}}</span>
      <span class="failure" *ngIf="isServerError">{{serverErrorMessage}}</span>
    </div>

    <div class="action-button">
        <button *ngIf="hasWritePermission()" id="deleteUserButton" [disabled]="updateButtonStatus()"
        (click)="onRemoveWhitelistedUser()">Remove</button>
    </div>


  </div>
  <div *ngIf="hasWritePermission()">
    <h3>
      Search Users
    </h3>
    <app-page-user-search-form [selectedAction]="onAddToWhitelist" selectedActionCaption="Add to Closed User Group"></app-page-user-search-form>
    
  </div>
</div>
