import { Component, Injector, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AdminUsersResponse, IAdminUserModel, AdminProvider, AllTranslationsDetails, TranslationsByKeyResponseDetails,
} from '@modeso/dgoods-lib-admin-fe';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import Debug from 'debug';
import { UpdateUserByIdDialog } from './updateUserByIdDialog/updateUserByIdDialog';
import { PopUpComponent } from '../popUp/popUp';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

const debug = Debug('modeso:dgoods-admin:ManageUsersPage');

const filterPredicate = (data: IAdminUserModel, filter: string) =>  {
  if (data.name.toLowerCase().indexOf(filter) !== -1 ) {
    return true;
  }
  return false;
};

@Component({
  selector: 'app-page-manage-users',
  templateUrl: './manageUsers.page.html',
  styleUrls: ['./manageUsers.page.scss'],
})
export class ManageUsersPage extends BasePageComponent implements OnInit {
  users: AdminUsersResponse = new AdminUsersResponse();
  dataSource: MatTableDataSource<IAdminUserModel>;
  selection = new SelectionModel<any>();

  isError = false;
  isServerError = false;

  manageColumns: string[] = ['select', 'Username', 'Role'];
  updatedNotificationMessage = 'User is updated successfully';
  serverErrorMessage = 'Server Error';
  errorMessage: string;
  filterValue = '';
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;

  errorhandler = (errorResponse: HttpErrorResponse) => {
    if (errorResponse === undefined) {
      return;
    }
    if (errorResponse.status === 404) {
      this.isServerError = false;
      this.isError = true;
      this.errorMessage = errorResponse.error.message;
    } else {
      this.isError = false;
      this.isServerError = true;
    }
  }

  constructor(injector: Injector, private adminProvider: AdminProvider, public dialog: MatDialog,
              private _snackBar: MatSnackBar, private changeDetector: ChangeDetectorRef,
              private localStorageService: LocalStorageService) {
    super(injector);
  }

  ngOnInit() {
    // this.localizationselectionList.selectedOptions = new SelectionModel<MatListOption>(false);
    this.getAllUsers();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.filterValue = filterValue;
  }

  getAllUsers() {
    this.subscriptions.push(
      this.adminProvider.getAdminUsers$().pipe().subscribe((response: AdminUsersResponse) => {

        this.isError = false;
        this.isServerError = false;

        this.users = response;

        this.dataSource = new MatTableDataSource<IAdminUserModel>(this.users);
        this.dataSource.filterPredicate = filterPredicate;
        this.changeDetector.detectChanges();
        this.dataSource.paginator = this.paginator;


      })
    );

    this.subscriptions.push(
      this.adminProvider.getError$().subscribe(this.errorhandler)
    );
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
  }

  selectedRow() {
    if (!this.selection.selected[0]) {
      return null;
    }
    return this.selection.selected[0];
  }


  updateButtonStatus() {
    return !this.selectedRow();
  }


  onUpdateUser() {
    const selectedUser: IAdminUserModel = this.selectedRow();
    const dialogRef = this.dialog.open(UpdateUserByIdDialog, {
      width: '50%',
      data: selectedUser
    });

    dialogRef.afterClosed().subscribe((result: IAdminUserModel) => {


      if (!result) {
        return;
      }
      selectedUser.name = result.name;
      selectedUser.role = result.role;

      debug(selectedUser);
      this.subscriptions.push(
        this.adminProvider.updateAdminUser$(selectedUser).pipe(first())
          .subscribe((updatedUsers: AdminUsersResponse) => {

            this.isError = false;
            this.isServerError = false;

            this.users = updatedUsers;

            this.dataSource = new MatTableDataSource<IAdminUserModel>(this.users);
            this.dataSource.filterPredicate = filterPredicate;
            this.changeDetector.detectChanges();
            this.dataSource.paginator = this.paginator;
            this.openSnackBar(this.updatedNotificationMessage);
            this.dataSource.filter = this.filterValue;
          })
      );

      this.subscriptions.push(
        this.adminProvider.getError$().pipe(first()).subscribe(this.errorhandler)
      );
    });


  }

  onDeleteUser() {

    const selectedUser: IAdminUserModel = this.selectedRow();

    const dialogRef = this.dialog.open(PopUpComponent, {
      data: {
          title: 'Delete User' ,
          confirmationMessage: 'Are you sure you want to delete user ' + selectedUser.name + '?',
      },
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.subscriptions.push(
          this.adminProvider.deleteAdminUser$(selectedUser).pipe(first()).subscribe((response: AdminUsersResponse) => {
            this.isError = false;
            this.isServerError = false;

            this.users = response;

            this.dataSource = new MatTableDataSource<IAdminUserModel>(this.users);
            this.dataSource.filterPredicate = filterPredicate;
            this.changeDetector.detectChanges();
            this.dataSource.paginator = this.paginator;
            this.openSnackBar(this.updatedNotificationMessage);
            this.dataSource.filter = this.filterValue;
          })
        );

        this.subscriptions.push(
          this.adminProvider.getError$().pipe(first()).subscribe(this.errorhandler)
        );
      }
    }));
  }

  hasReadPermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
        UserRoles.DIGITAL_VAUCHERS_ADMIN,
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
  }

  hasWritePermission(): boolean {
      const role = this.localStorageService.getUserRole();
      const priviledgedRoles = [
          UserRoles.DIGITAL_VAUCHERS_ADMIN,
      ];
      return PermissionHelper.hasPermission(role, priviledgedRoles);
  }
}
