import { ISpotLight } from "@modeso/types__dgoods-products";

export const spotlightInitValues: ISpotLight = {
  name: "",
  productLineId: "",
  contentPageId:"",
  navigationUrl: "",
  navigationType: null,
  startFrom: null,
  endAt: null,
  published: false,
  displayOnAndroid: false,
  displayOnIOS: false,
  image: {"de-ch": "", "en-us": "", "fr-ch": "", "it-ch": ""},
};
