import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminProvider, UserResponse, SignupUser } from '@modeso/dgoods-lib-admin-fe';
import { take } from 'rxjs/operators';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';
@Component({
    selector: 'app-page-signup',
    templateUrl: './signup.page.html',
    styleUrls: ['./signup.page.scss']
})
export class SignupPage extends BasePageComponent implements OnInit {
    signupForm: FormGroup;
    isServerError = false;
    errorMessage: string;
    isPasswordWeak = false;
    isUserAddedSuccessfully = false;
    isUserAlreadyExist = false;
    responseMessage: string;
    isSignupFailed = false;
    // tslint:disable-next-line: max-line-length
    weakPasswordErrorMessage = 'Password must be more than 12 charachters and it must contains upper and small letters, symbols, numbers and spaces are not allowed';
    userAddedSuccessfullyMessage = 'New user is created successfully';
    passwordAndConfirmedNotMatchedErrorMsg = 'Password and Confirm Password are not the same';
    serverError = 'Server Error';
    roles = [UserRoles.DIGITAL_VAUCHERS_ADMIN,
        UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER];
    constructor(injector: Injector, private router: Router, private adminProvider: AdminProvider,
                private localStorageService: LocalStorageService) {
        super(injector);
    }

    ngOnInit() {
        this.signupForm = new FormGroup({
            userName: new FormControl(null, { validators: [Validators.required] }),
            role: new FormControl(null, { validators: [Validators.required] }),
            password: new FormControl(null, { validators: [Validators.required] }),
            confirmPassword: new FormControl(null, { validators: [Validators.required] })

        }, {
            validators: this.password.bind(this)
        });
    }

    password(formGroup: FormGroup) {
        const { value: password } = formGroup.get('password');
        const { value: confirmPassword } = formGroup.get('confirmPassword');
        return password === confirmPassword ? null : { passwordNotMatch: true };
    }

    onLoginUSer() {
        if (this.signupForm.invalid) {
            return;
        }

        const newUSer: SignupUser = {
            name: this.signupForm.value.userName,
            password: this.signupForm.value.password,
            role: this.signupForm.value.role,
        };

        this.subscriptions.push(
            this.adminProvider.signup$(newUSer).pipe(take(2)).subscribe((signupResponse: UserResponse) => {

                if (signupResponse && signupResponse.user) {


                    this.isPasswordWeak = false;
                    this.isServerError = false;
                    this.isSignupFailed = false;
                    this.isUserAlreadyExist = false;
                    this.isUserAddedSuccessfully = true;
                } else if (signupResponse && signupResponse.message) {
                    this.responseMessage = signupResponse.message;

                    this.isUserAddedSuccessfully = false;
                    this.isPasswordWeak = false;
                    this.isServerError = false;
                    this.isSignupFailed = false;
                    this.isUserAlreadyExist = true;
                } else {
                    this.adminProvider.getError$().subscribe((errorResponse: any) => {

                        if (errorResponse) {
                            if (errorResponse.status.toString() === '403') {

                                this.isUserAddedSuccessfully = false;
                                this.isServerError = false;

                                this.isSignupFailed = false;
                                this.isUserAlreadyExist = false;
                                this.isPasswordWeak = true;
                            } else if (errorResponse.status.toString() === '400') {
                                this.isUserAlreadyExist = false;
                                this.isServerError = false;
                                this.isPasswordWeak = false;
                                this.isUserAddedSuccessfully = false;
                                this.isSignupFailed = true;
                                this.errorMessage = errorResponse.error.error;

                            } else {

                                this.isPasswordWeak = false;
                                this.isUserAddedSuccessfully = false;
                                this.isUserAlreadyExist = false;
                                this.isSignupFailed = false;
                                this.isServerError = true;
                            }


                        }
                    });
                }
            })
        );

    }

    buttonStatus() {
        return this.signupForm.invalid;
    }

    getMatchedPasswords() {
        if (this.signupForm.errors) {
            if (this.signupForm.errors.passwordNotMatch) {
                this.isServerError = false;
                this.isPasswordWeak = false;
                this.isUserAddedSuccessfully = false;
                this.isSignupFailed = false;
            }
            return this.signupForm.errors.passwordNotMatch;
        }

    }
    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
      }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
