import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductLineForManagementDto } from '@modeso/types__dgoods-products';

@Component({
  selector: 'app-settings-edit-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['confirmation-dialog.component.scss'],
})
export class SettingsEditDialogComponent {
  editableSettings: string[] = ['enforceTC', 'couponsEnabled', 'displayOnIOS', 'displayOnAndroid'];
  product: ProductLineForManagementDto;
  updatedProduct: ProductLineForManagementDto;

  constructor(
    public dialogRef: MatDialogRef<SettingsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.product = data.product;
    this.updatedProduct = { ...data.product };
  }

  onYesClick(): void {
    this.dialogRef.close(this.updatedProduct);
  }
}
