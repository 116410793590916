
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/admin-digitalvoucher.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdminDigitalVoucherConfiguratorService } from '../services/admin-digitalvoucher-configurator.service.ts';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
@Injectable()
export class AdminDigitalVoucherConfiguratorEffects {
    constructor(private actions$: Actions, private service: AdminDigitalVoucherConfiguratorService, 
        private snackBar: MatSnackBar, private router: Router) { }

    getDigitalVoucherByProductLine$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetDigitalVoucherByProductLine.type),
            mergeMap(
                (payload: any) => {
                    return this.service.getDigitalVoucherByProductLineName(payload.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onGetDigitalVoucherByProductLineSuccessfull({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetDigitalVoucherByProductLineFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnGetDigitalVoucher$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetDigitalVoucherByProductLineFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleAllErrors(action.payload)
            )
        ), { dispatch: false }
    );
    createOrUpdateDigitalVoucher$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onCreateOrUpdateDigitalVoucher.type),
            mergeMap(
                (payload: any) => {
        
                    return this.service.createOrUpdateDigitalVoucher(payload.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onCreateOrUpdateDigitalVoucherSuccessful({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onCreateOrUpdateDigitalVoucherFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnCreateOrUpdateDigitalVoucher$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onCreateOrUpdateDigitalVoucherFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => {
                    this.openSnackBar('Error Occurred while saving data');
                    this.handleAllErrors(action.payload);
                }
            )
        ), { dispatch: false }
    );
    OnCreateOrUpdateDigitalVoucherSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onCreateOrUpdateDigitalVoucherSuccessful.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.router.navigateByUrl('/de-ch/product-managment/whiteListing-products')
            )
        ), { dispatch: false }
    );

    

    private openSnackBar(message: string) {
        this.snackBar.open(message, "", {
            duration: 4000,
        });
    }

    private handleAllErrors(error) {
        return error;
    }
}
