export const environment = {
  production: true,
  allowLoginWithUsernameAndPassword: true,
  modeso: {
    apiGateway: 'https://api.digitalvoucher-int.twint.ch/api/'
  },
  defaultLanguage: 'de-ch',
  availableLanguages: ['de-ch', 'fr-ch', 'it-ch'],
  enableDebugging: 'modeso:*, dgoods:*'
};
