import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props, Action } from '@ngrx/store';
import { AuditLogsRequest } from '../models/auditLogs/auditLogsRequest.model';
import { AuditLogsResponse } from '../models/auditLogs/auditLogsResponse.model';

export const getAuditLogs = createAction(
    '[Admin AuditLogs] getAuditLogs',
    props<{ payload: AuditLogsRequest }>()
);

export const onGetAuditLogsSuccessfully = createAction(
    '[Admin AuditLogs] getAuditLogs successfully',
    props<{ payload: AuditLogsResponse }>()
);

export const onGetAuditLogsFailed = createAction(
    '[Admin AuditLogs] getAuditLogs failed',
    props<{ payload: HttpErrorResponse }>()
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}