/* eslint-disable @angular-eslint/component-selector */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMarkdownEditorDelegate, MarkdownEditorComponent } from '../../../shared/markdown/markdown.component';


@Component({
    selector: 'description-editor-dialog',
    templateUrl: 'descriptionEditorDialog.html',
    styleUrls: ['./descriptionEditorDialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DescriptionEditorDialog implements IMarkdownEditorDelegate {

    description;
    constructor(
        public dialogRef: MatDialogRef<DescriptionEditorDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.description = data.description;
    }

    onSaveButtonClick(markdown: string, sender: MarkdownEditorComponent): void {
        this.dialogRef.close(markdown);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(this.description);

    }
}
