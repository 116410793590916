import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginUser, AdminProvider, UserResponse, ChangePassword } from '@modeso/dgoods-lib-admin-fe';
import { take } from 'rxjs/operators';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';

@Component({
    selector: 'app-page-changePassword',
    templateUrl: './changePassword.page.html',
    styleUrls: ['./changePassword.page.scss']
})
export class ChangePasswordPage extends BasePageComponent implements OnInit {
    changePasswordForm: FormGroup;
    validLogin = false;
    invalidUser = false;
    isCurrentPasswordWrong = false;
    isBadRequest = false;
    isServerError = false;
    errorMessage: string;
    isNewPasswordWeak = false;
    isPasswordChangedSuccessfully = false;
    serverError = 'Server Error';
    // tslint:disable-next-line: max-line-length
    passwordWeakErrorMessage = 'Password must be more than 12 charachters and it must contains upper and small letters, symbols, numbers and spaces are not allowed';
    passwordChangedSuccessfullyMessage = 'Password is changed successfully';
    passwordAndConfirmedNotMatchedErrorMsg = 'Password and Confirm Password are not the same';

    constructor(injector: Injector, private adminProvider: AdminProvider, private localStorageService: LocalStorageService) {
        super(injector);
    }

    ngOnInit() {
        this.changePasswordForm = new FormGroup({
            currentPassword: new FormControl(null, { validators: [Validators.required] }),
            newPassword: new FormControl(null, { validators: [Validators.required] }),
            confirmNewPassword: new FormControl(null, { validators: [Validators.required] }),
        }, {
            validators: this.password.bind(this)
        });
    }

    password(formGroup: FormGroup) {
        const { value: newPassword } = formGroup.get('newPassword');
        const { value: confirmNewPassword } = formGroup.get('confirmNewPassword');
        return newPassword === confirmNewPassword ? null : { passwordNotMatch: true };
    }

    getMatchedPasswords() {
        if (this.changePasswordForm.errors) {

            if (this.changePasswordForm.errors.passwordNotMatch) {
                this.isServerError = false;
                this.isCurrentPasswordWrong = false;
                this.isNewPasswordWeak = false;
                this.isPasswordChangedSuccessfully = false;
                this.isBadRequest = false;
            }
            return this.changePasswordForm.errors.passwordNotMatch;
        }

    }

    onSubmit() {
        if (this.changePasswordForm.invalid) {
            return;
        }

        const passwords: ChangePassword = {
            currentPassword: this.changePasswordForm.value.currentPassword,
            newPassword: this.changePasswordForm.value.newPassword
        };

        this.subscriptions.push(
            this.adminProvider.changePassword$(passwords).pipe(take(2)).subscribe((changePasswordResponse: UserResponse) => {

                if (changePasswordResponse && changePasswordResponse.user) {

                    this.isPasswordChangedSuccessfully = true;
                    this.isNewPasswordWeak = false;
                    this.isServerError = false;
                    this.isCurrentPasswordWrong = false;
                    this.isBadRequest = false;

                } else {
                    this.adminProvider.getError$().subscribe((errorResponse: any) => {

                        if (errorResponse) {

                            if (errorResponse.status.toString() === '404') {

                                this.isCurrentPasswordWrong = true;
                                this.isNewPasswordWeak = false;
                                this.isServerError = false;
                                this.isPasswordChangedSuccessfully = false;
                                this.errorMessage = errorResponse.error.message;

                            } else if (errorResponse.status.toString() === '403') {

                                this.isPasswordChangedSuccessfully = false;
                                this.isServerError = false;
                                this.isCurrentPasswordWrong = false;
                                this.isNewPasswordWeak = true;
                                // this.errorMessage = errorResponse.error.message;

                            } else if (errorResponse.status.toString() === '400') {

                                this.isCurrentPasswordWrong = false;
                                this.isServerError = false;
                                this.isNewPasswordWeak = false;
                                this.isPasswordChangedSuccessfully = false;
                                this.isBadRequest = true;
                                this.errorMessage = errorResponse.error.error;

                            } else {
                                this.isServerError = true;
                                this.isCurrentPasswordWrong = false;
                                this.isNewPasswordWeak = false;
                                this.isPasswordChangedSuccessfully = false;
                            }


                        }
                    });
                }
            })
        );

    }

    buttonStatus() {
        return this.changePasswordForm.invalid;
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
