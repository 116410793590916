import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-error-dialog',
    templateUrl: './errorDialog.html',
    styleUrls: ['./errorDialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CouponErrorDialog {

  error;

    constructor(
        public dialogRef: MatDialogRef<CouponErrorDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.error = data.error;
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
