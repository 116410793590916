import { HttpErrorResponse } from '@angular/common/http';
import { IDigitalVoucherConfigurator } from '@modeso/types__dgoods-products';
import { createAction, props, Action } from '@ngrx/store';
export const onGetDigitalVoucherByProductLine = createAction(
    '[Admin Digital Voucher] onGetDigitalVoucherByProductLine',
    props<{ payload: string }>()
);

export const onGetDigitalVoucherByProductLineSuccessfull = createAction(
    '[Admin Digital Voucher] get Digital Voucher successfully',
    props<{ payload: IDigitalVoucherConfigurator }>()
);

export const onGetDigitalVoucherByProductLineFailed = createAction(
    '[Admin Digital Voucher] get Digital Voucher failed',
    props<{ payload: HttpErrorResponse }>()
);
export const onCreateOrUpdateDigitalVoucher = createAction(
    '[Admin Digital Voucher] onCreateOrUpdateDigitalVoucher',
    props<{ payload: IDigitalVoucherConfigurator }>()
);

export const onCreateOrUpdateDigitalVoucherSuccessful = createAction(
    '[Admin Digital Voucher] create or update digital voucher successfully',
    props<{ payload: IDigitalVoucherConfigurator }>()
);

export const onCreateOrUpdateDigitalVoucherFailed = createAction(
    '[Admin Digital Voucher] create or update digital voucher failed',
    props<{ payload: HttpErrorResponse }>()
);
export interface ActionWithPayload<T> extends Action {
    payload: T;
}