<div class="center-content">
  <h2 *ngIf="activate" mat-dialog-title>Are you sure to enable the feature?</h2>
  <h2 *ngIf="!activate" mat-dialog-title>Are you sure to disable the feature?</h2>

  <mat-dialog-actions>
    <button mat-button (click)="confirm()">Ok</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</div>

