<div #rootelement class="dgoods-checkbox">
  <span
    *ngIf="!isProductPage"
    #checkbox
    type="checkbox"
    (click)="onToggle()"
  ></span>
  <label #label>
    <ng-content></ng-content>
  </label>
  <div *ngIf="isProductPage" class="toggle">
    <label class="switch">
      <input
        #checkbox
        type="checkbox"
        [checked]="checked"
        (click)="onToggle()"
      />
      <div class="slider round"></div>
    </label>
  </div>
</div>
