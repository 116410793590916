<div class="markdown-editor">
  <div class="form-wrapper">
    <mat-form-field class="full-width" appearance="fill">
      <textarea
        matInput
        placeholder="Enter Markdown Code here"
        [formControl]="control"
        rows="15"
      ></textarea>
      <mat-error *ngIf="control.invalid">{{errorMessage}}</mat-error>
    </mat-form-field>
    <div class="preview full-width" *ngIf="showPreview" [innerHtml]="getHtml()"></div>
  </div>
  <div>
    <button mat-button type="submit" (click)="submitForm()" [disabled]="control.invalid">Save</button>
    <button *ngIf="!showPreview" type="button" mat-button (click)="togglePreview()">Show Preview</button>
    <button *ngIf="showPreview" type="button" mat-button (click)="togglePreview()">Hide Preview</button>
    <button type="button" mat-button (click)="cancelForm()">Cancel</button>
  </div>
</div>
