<div class="container">
  <div class="row">
    <div class="table-container">
      <div>
        <mat-form-field style="width: 270px;">
          <input #emailSearch matInput placeholder="Email" [(ngModel)]="searchValue" (keyup.enter)="onSearch()">
        </mat-form-field>
      
          <button id="deleteUserButton" [disabled]="searchButtonIsDisabled()"
          (click)="onSearch()">Search</button>

      </div>
      <table mat-table [dataSource]="dataSource">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell fxFlex="100px" *matHeaderCellDef>
          </th>
          <td mat-cell fxFlex="100px" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Key Column -->
        <ng-container matColumnDef="CustomerUuid">
          <th mat-header-cell *matHeaderCellDef> CustomerUuid </th>
          <td mat-cell *matCellDef="let element"> {{element.userToken}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- Issuer Column -->
        <ng-container matColumnDef="Issuer">
          <th mat-header-cell *matHeaderCellDef> Issuer </th>
          <td mat-cell *matCellDef="let element"> {{element.userIssuer}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="manageColumns;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: manageColumns;" (click)="selection.toggle(row)">
        </tr>
      </table>
      <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>

  <div class="row">
    <div class="messages">
      <span class="failure" *ngIf="isError">{{errorMessage}}</span>
      <span class="failure" *ngIf="isServerError">{{serverErrorMessage}}</span>
    </div>

    <div class="action-button">
        <button [disabled]="updateButtonStatus()"
        (click)="selectedAction(this.selectedRow())">{{selectedActionCaption}}</button>
    </div>


  </div>

</div>