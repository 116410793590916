import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WhitelistedUsersResponseModel } from '../../public-api';
import * as fromActions from '../actions/admin.whiteListingUsers.actions';
import { AddOrRemoveWhitelistedUserResponseModel } from '../models/whiteListUsers/addOrRemoveWhitelistedUser.response.model';
import { UserSearchRequestModel, UserSearchResponseModel } from '../models/whiteListUsers/userSearch.model';
import * as fromFeature from './../reducers/admin.reducer';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminWhiteListingUsersProvider');
@Injectable({
    providedIn: 'root',
})
export class AdminWhiteListingUsersProvider {

    constructor(
        private store: Store<fromFeature.AppState>) { }

    public requestWhitelistedUsers$(): Observable<WhitelistedUsersResponseModel> {
        this.store.dispatch(fromActions.getWhitelistedUsers());
        return this.store.pipe(select(fromFeature.selectFeatureWhitelistedUsers), map((response: WhitelistedUsersResponseModel) => {
            if (response) {
                return response;
            } else {
                debug('get all products in all languages response is undefined');
                return undefined;
            }
        }));
    }

    public getWhitelisteUsers$(): Observable<WhitelistedUsersResponseModel> {
        return this.store.pipe(select(fromFeature.selectFeatureWhitelistedUsers), map((response: WhitelistedUsersResponseModel) => {
            if (response) {
                return response;
            } else {
                debug('get all products in all languages response is undefined');
                return undefined;
            }
        }));
    }

    public searchUsers$(search: UserSearchRequestModel): Observable<UserSearchResponseModel> {
        this.store.dispatch(fromActions.searchUser({payload: search}));
        return this.store.pipe(select(fromFeature.selectFeatureSearchUserResult), map((response: UserSearchResponseModel) => {
            if (response) {
                return response;
            } else {
                debug('search user response is undefined');
                return undefined;
            }
        }));
    }

    public getUserSearchResults$(): Observable<UserSearchResponseModel> {
        return this.store.pipe(select(fromFeature.selectFeatureSearchUserResult), map((response: UserSearchResponseModel) => {
            if (response) {
                return response;
            } else {
                debug('search user response is undefined');
                return undefined;
            }
        }));
    }


    public addUserToWhitelist$(customerUuid: string): Observable<AddOrRemoveWhitelistedUserResponseModel> {
        this.store.dispatch(fromActions.addUserToWhitelist({payload: customerUuid}));
        return this.store.pipe(select(fromFeature.selectFeatureAddOrRemoveUserFromWhitelistResponse), map((response: any) => {
            if (response) {
                return response;
            } else {
                debug('add user to whitlist response is undefined');
                return undefined;
            }
        }));
    }

    public removeUserFromWhitelist$(customerUuid: string): Observable<AddOrRemoveWhitelistedUserResponseModel> {
        this.store.dispatch(fromActions.removeUserFromWhitelist({payload: customerUuid}));
        return this.store.pipe(select(fromFeature.selectFeatureAddOrRemoveUserFromWhitelistResponse), map((response: any) => {
            if (response) {
                return response;
            } else {
                debug('remove user from whitlist response is undefined');
                return undefined;
            }
        }));
    }

    public getAddOrRemoveUserFromWhitelistResponse$(): Observable<AddOrRemoveWhitelistedUserResponseModel> {
        return this.store.pipe(select(fromFeature.selectFeatureAddOrRemoveUserFromWhitelistResponse),
        map((response: any) => {
            if (response) {
                return response;
            } else {
                debug('no response for add or remove user from whitelist');
                return undefined;
            }
        }));
    }

    public getError$(): Observable<any> {
        return this.store.pipe(select(fromFeature.featureError),
            map((error: any) => {
                return error;
            })
        );
    }

}
