import { Injectable } from '@angular/core';
import * as fromActions from './../actions/dgoods.user.actions';
import * as fromFeature from './../reducers/dgoods.user.reducer';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILookUpAccountResponse,ISendMobilePhoneOtpRequest,IUpdateSweepayAccountRequest,IUpdateSweepayAccountResponse,IUpdateUserRequest, IValidateSweepayOtpRequest, IValidateSweepayOtpResponse } from '../services/dgoods.user.service';


@Injectable({
  providedIn: 'root'
})
export class UserProvider {

  constructor(
    private store: Store<fromFeature.AppState>) { }



  public updateUser$(body: IUpdateUserRequest): Observable<IUpdateUserRequest> {
    this.store.dispatch(fromActions.onUpdateUser({ payload: body }));
    return this.store.pipe(select(fromFeature.featureUser),
      map((user) => {
        return user;
      })
    );
  }

  public getSweepayAccount$(): Observable<ILookUpAccountResponse> {
    this.store.dispatch(fromActions.onLookUpAccount());
    return this.store.pipe(select(fromFeature.featurelookupSweepayAccount));
  }
  public updateSweepayAccount$(body: IUpdateSweepayAccountRequest): Observable<IUpdateSweepayAccountResponse> {
    this.store.dispatch(fromActions.onUpdateSweepayAccountWithPhoneNo({ payload: body }));
    return this.store.pipe(select(fromFeature.featureUpdateSweepayAccount),
      map((updateSweepayAccount: IUpdateSweepayAccountResponse) => {
        return updateSweepayAccount;
      })
    );
  }

  public resendSweepayAccount$(body: ISendMobilePhoneOtpRequest): Observable<IUpdateSweepayAccountResponse> {
    this.store.dispatch(fromActions.onResendOtpSweepayAccount({ payload: body }));
    return this.store.pipe(select(fromFeature.featureResendOtpSweepayAccount),
      map((updateSweepayAccount: IUpdateSweepayAccountResponse) => {
        return updateSweepayAccount;
      })
    );
  }

  public validateSweepayOtp$(body: IValidateSweepayOtpRequest): Observable<IValidateSweepayOtpResponse> {
    this.store.dispatch(fromActions.onValidateSweepayOtp({ payload: body }));
    return this.store.pipe(select(fromFeature.featureValidateSweepayOtp),
      map((validateOtp: IValidateSweepayOtpResponse) => {
        return validateOtp;
      })
    );
  }

  public getEmail$(): Observable<IUpdateUserRequest> {
    return this.store.pipe(select(fromFeature.featureUser),
      map((body: IUpdateUserRequest) => {
        return body;
      })
    );
  }

  public getUser$(): Observable<string> {
    this.store.dispatch(fromActions.onGetSweepayPhoneNumber());
    return this.store.pipe(select(fromFeature.featureSweepayPhoneNumber));
  }

  public dispatchGetUsersEmails$(usersTokens: string[]): void {
    this.store.dispatch(fromActions.onGettingUsersEmails({payload: {userTokens: usersTokens}}));
  }

  public getUsersEmails$(): Observable<Record<string,string>> {
    return this.store.pipe(select(fromFeature.featureSelectUsersEmails));
  }

  public getError$(): Observable<any> {
    return this.store.pipe(select(fromFeature.featureError));
  }
}
