import { CouponStateEnum } from '@modeso/types__twint-lib-coupons';

export const couponInitValue = {
  couponType: '',
  state: CouponStateEnum.Inactive,
  name: '',
  autoGeneratedCodes: [],
  codes: [],
  products: [],
  minPurchaseAmount: 50,
  maxApplicationPerUser: 0,
  maxApplicationOverAllUsers: 1,
  startFrom: new Date(),
  endAt: '',
  effectType: '',
  effectValue: '',
  noOfcodes: '',
};
