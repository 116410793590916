import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/admin-manageProductContent.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminManageProductContentService } from '../services/admin-manageProductContent.service';
import { ManageProductsResponse } from '../models/manageProductContent/managedProductsResponse.model';
import {
    ProductContentUpdateResponse,
} from '../models/manageProductContent/updateProductContent.model';
import { ProductSettingsUpdateResponse } from '../models/manageProductContent/updateProductSettings.model';
import { ProductCategoriesUpdateResponse } from '../models/manageProductContent/updateProductCategories.model';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminManageProductContentEffects');

@Injectable()
export class AdminManageProductContentEffects {
    constructor(private actions$: Actions, private service: AdminManageProductContentService) {}

    getAllProductContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAllProductContent.type),
            mergeMap(() => {
                return this.service.getAllProducts().pipe(
                    map((response: ManageProductsResponse) => {
                        return fromActions.onGetAllProductContentSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onGetAllProductContentFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnGetAllProductContent$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onGetAllProductContentFailed.type),
                tap((action: fromActions.IActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllCartErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    updateContentByProductLine$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateContentByProductLine.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.updateContentByProductLine(payload).pipe(
                    map((response: ProductContentUpdateResponse) => {
                        return fromActions.onUpdateContentByProductLineSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onUpdateContentByProductLineFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnUpdateContentByProductLine$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onUpdateContentByProductLineFailed.type),
                tap((action: fromActions.IActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllCartErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    updateSettingsByProductLine$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.onUpdateSettingsByProductLine.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.updateSettingsByProductLine(payload).pipe(
                    map((response: ProductSettingsUpdateResponse) => {
                        return fromActions.onUpdateSettingsByProductLineSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onUpdateSettingsByProductLineFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnUpdateEnforceTcByProductLine$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onUpdateSettingsByProductLineFailed.type),
                tap((action: fromActions.IActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllCartErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    updateProdCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateProductCategories.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.updateProductCategories(payload).pipe(
                    map((response: ProductCategoriesUpdateResponse) => {
                        return fromActions.onUpdateProductCategoriesSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onUpdateProductCategoriesFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnUpdateProdCategory$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onUpdateProductCategoriesFailed.type),
                tap((action: fromActions.IActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllCartErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    handleOnLoadAllCartErrors(error) {
        debug(error);
        return error;
    }
}
