import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CouponDTO, CouponFilterDTO } from '@modeso/types__twint-lib-coupons';
import { TwintCouponConfigService } from '../twint.coupons.config';
import { IFinancingParty } from '../models/interfaces/finance';
import { ExportCostFileType } from '../models/enums/export-cost-file-type.enum';
import { ExportCostFileRequest } from '../models/interfaces/export-cost-file-request.interface';

@Injectable({
  providedIn: 'root',
})
export class TwintCouponsService {
  private server = '';

  private microserviceName = 'cart';
  private readonly addCoupon = '/coupons';
  private readonly editCoupon = '/coupons/';
  private readonly couponFeature = '/coupons/state';
  private readonly activateCoupon = '/activate';
  private readonly deactivateCoupon = '/deactivate';
  private readonly invalidateCode = '/codes/';
  private readonly invalidateCodes = '/codes';
  private readonly archiveCoupon = '/archive';
  private readonly fetchCouponDetailsCoupon = '/details';
  private readonly controlCouponState = '/control';
  private readonly exportCostFile = '/cost-full-report';
  private readonly exportCostFileSummary = '/cost-summary-report';
  private readonly financingParties = '/financing-parties';


  constructor(private http: HttpClient, @Inject(TwintCouponConfigService) private config) {
    this.server = config.apiGateway;
  }

  public createCoupon(coupon: CouponDTO): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.addCoupon}`;
    return this.http.post<CouponDTO>(url, coupon);
  }

  public updateCoupon(coupon: CouponDTO): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${coupon.id}`;
    return this.http.put<CouponDTO>(url, coupon);
  }

  public getCoupons(data?: CouponFilterDTO): Observable<CouponDTO[]> {
    const queryParams = this.buildQueryParams(data);
    const url = `${this.server}${this.microserviceName}${this.addCoupon}?${queryParams}`;
    return this.http.get<CouponDTO[]>(url);
  }

  public fetchCouponDetails(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.fetchCouponDetailsCoupon}`;
    return this.http.get<CouponDTO>(url, {});
  }

  public toggleCouponsFeature(couponFeature: boolean) {
    const url = `${this.server}${this.microserviceName}${this.couponFeature}`;
    return this.http.post<boolean>(url, { state: couponFeature ? 'active' : 'inactive' });
  }
  public checkCouponsFeature() {
    const url = `${this.server}${this.microserviceName}${this.couponFeature}`;
    const res = this.http.get<boolean>(url);
    return res;
  }

  public activateCouponState(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.activateCoupon}`;
    return this.http.post<CouponDTO>(url, {});
  }

  public deactivateCouponState(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.deactivateCoupon}`;
    return this.http.post<CouponDTO>(url, {});
  }

  public deleteCoupon(couponId: string): Observable<any> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}`;
    return this.http.delete<any>(url);
  }

  public invalidateCodeState(invalidateCodeObj): Observable<any> {
    const { couponId, codeId } = invalidateCodeObj;
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.invalidateCode}${codeId}`;
    return this.http.put<any>(url, {});
  }

  public invalidateCodesState(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.invalidateCodes}`;
    return this.http.put<CouponDTO>(url, {});
  }

  public archiveCouponState(archiveCouponObj): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${archiveCouponObj.couponId}${this.archiveCoupon}`;
    return this.http.post<CouponDTO>(url, { archiveState: archiveCouponObj.archiveState });
  }

  public controlCoupon(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.controlCouponState}`;
    return this.http.patch<CouponDTO>(url, null);
  }

  public getFinancingParties(): Observable<IFinancingParty[]> {
    const url = `${this.server}${this.microserviceName}${this.addCoupon}${this.financingParties}`;
    return this.http.get<IFinancingParty[]>(url);
  }
  public addFinanceParty(name: string): Observable<IFinancingParty> {
    const url = `${this.server}${this.microserviceName}${this.addCoupon}${this.financingParties}`;
    return this.http.post<IFinancingParty>(url, { name });
  }

  public exportCost(data: ExportCostFileRequest): Observable<Blob> {
    
    let url;
    const { type, ...requestBody } = data;
  
    if (data.type === ExportCostFileType.FULL) {
      url = `${this.server}${this.microserviceName}${this.addCoupon}${this.exportCostFile}`;
    } else {
      url = `${this.server}${this.microserviceName}${this.addCoupon}${this.exportCostFileSummary}`;
    }
  
    return this.http.post(url, requestBody, { responseType: 'blob' });
  }
  
  private buildQueryParams(params?: CouponFilterDTO): string {
    const queryParams = new URLSearchParams();

    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] != null && params[key] !== '') {
        queryParams.append(key, params[key]);
      }
    }

    return queryParams.toString();

  }

}
