import { createAction, props, Action } from '@ngrx/store';
import { ISortingOrder  } from '@modeso/types__dgoods-products';

export const onCreatOrUpdateSortingOrder = createAction(
    '[TDV SortingOrder] CreatOrUpdateSortingOrder',
    props<{payload: ISortingOrder}>(),
  );
  export const onCreatOrUpdateSortingOrderSuccessfully = createAction(
    '[TDV SortingOrder] onCreatOrUpdateSortingOrderSuccessfully',
    props<{ payload: ISortingOrder }>(),
  );
  export const onCreatOrUpdateSortingOrderFailed = createAction(
    '[TDV SortingOrder]  onCreatOrUpdateSortingOrderFailed',
    props<{ payload: any }>(),
  );

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
