import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/twint.coupons.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { TwintCouponsService } from '../services/twint.coupons.service';
import { Router } from '@angular/router';
import { CouponDTO, CouponFilterDTO } from '@modeso/types__twint-lib-coupons';
import { ExportCostFileRequest } from '../models/interfaces/export-cost-file-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { FileService } from '@modeso/twint-lib-core-fe';

const debug = Debug('modeso:twint-coupons:TwintCouponsEffects');

@Injectable()
export class TwintCouponsEffects {
  constructor(private readonly actions$: Actions, 
              private readonly service: TwintCouponsService, 
              private readonly router: Router,
              private readonly fileService: FileService) {}

  onAddCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onAddCoupon.type),
      mergeMap((action: fromActions.ActionWithPayload<CouponDTO>) => {
        return this.service.createCoupon(action.payload).pipe(
          map((response) => fromActions.onAddCouponSuccessfully({ payload: response })),
          catchError((error) => of(fromActions.onAddCouponFailed({ payload: error })))
        );
      })
    )
  );

  errorOnAddCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onAddCouponFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  successOnAddCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onAddCouponSuccessfully.type),
        tap((action: fromActions.ActionWithPayload<CouponDTO>) => {
          this.router.navigateByUrl(`/de-ch/coupons-management`);
        })
      ),
    { dispatch: false }
  );

  onEditCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onUpdateCoupon.type),
      mergeMap((action: fromActions.ActionWithPayload<CouponDTO>) => {
        return this.service.updateCoupon(action.payload).pipe(
          map((response) => fromActions.onUpdateCouponSuccessfully({ payload: response })),
          catchError((error) => of(fromActions.onUpdateCouponFailed({ payload: error })))
        );
      })
    )
  );

  errorOnEditCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onUpdateCouponFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  successOnEditCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onUpdateCouponSuccessfully.type),
        tap((action: fromActions.ActionWithPayload<CouponDTO>) => {
          this.router.navigateByUrl(`/de-ch/coupons-management`);
        })
      ),
    { dispatch: false }
  );

  getAllCoupons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetCoupons.type),
      mergeMap((action: fromActions.ActionWithPayload<CouponFilterDTO>) => {
        return this.service.getCoupons(action.payload).pipe(
          map((response) => {
            return fromActions.onGetCouponsSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onGetCouponsFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnGetAllCoupons$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onGetCouponsFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  fetchCouponDetailsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchCouponDetailsById.type),
      mergeMap((action: fromActions.ActionWithPayload<string>) => {
        return this.service.fetchCouponDetails(action.payload).pipe(
          map((response) => {
            return fromActions.onFetchCouponDetailsCouponSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onFetchCouponDetailsFailed({ payload: error }));
          })
        );
      })
    )
  );


  errorOnFetchCouponDetailsById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onFetchCouponDetailsFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  checkCouponsFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onCheckCouponsFeature.type),
      mergeMap(() => {
        return this.service.checkCouponsFeature().pipe(
          map((response) => {
            return fromActions.onCheckCouponsFeatureSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onCheckCouponsFeatureFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorCheckCouponsFeature$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onCheckCouponsFeatureFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  toggleCouponsFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onToggleCouponsFeature.type),
      mergeMap((action: fromActions.ActionWithPayload<boolean>) => {
        return this.service.toggleCouponsFeature(action.payload).pipe(
          map((response) => {
            return fromActions.onToggleCouponsFeatureSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onToggleCouponsFeatureFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorToggleCouponsFeature$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onToggleCouponsFeatureFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  activateCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onActivateCoupon.type),
      mergeMap((action: fromActions.ActionWithPayload<string>) => {
        return this.service.activateCouponState(action.payload).pipe(
          map((response) => {
            return fromActions.onActivateCouponSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onActivateCouponFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnActivateCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onActivateCouponFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  deactivateCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onDeactivateCoupon.type),
      mergeMap((action: fromActions.ActionWithPayload<string>) => {
        return this.service.deactivateCouponState(action.payload).pipe(
          map((response) => {
            return fromActions.onDeactivateCouponSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onDeactivateCouponFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnDeactivateCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onDeactivateCouponFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  deleteCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onDeleteCoupon.type),
      mergeMap((action: fromActions.ActionWithPayload<string>) => {
        return this.service.deleteCoupon(action.payload).pipe(
          map((response) => {
            return fromActions.onDeleteCouponSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onDeleteCouponFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnDeleteCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onDeleteCouponFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  invalidateCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onInvalidateCode.type),
      mergeMap((action: fromActions.ActionWithPayload<any>) => {
        return this.service.invalidateCodeState(action).pipe(
          map((response) => {
            return fromActions.onInvalidateCodeSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onInvalidateCodeFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnInvalidateCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onInvalidateCodeFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  invalidateCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onInvalidateCodes.type),
      mergeMap((action: fromActions.ActionWithPayload<any>) => {
        return this.service.invalidateCodesState(action.payload).pipe(
          map((response) => {
            return fromActions.onInvalidateCodesSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onInvalidateCodesFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnInvalidateCodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onInvalidateCodesFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  archiveCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onArchiveCoupon.type),
      mergeMap((action: fromActions.ActionWithPayload<any>) => {
        return this.service.archiveCouponState(action.payload).pipe(
          map((response) => {
            return fromActions.onArchiveCouponSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onArchiveCouponFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnArchiveCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onArchiveCouponFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  getFinancingParties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getFinancingParties.type),
      mergeMap(() => {
        return this.service.getFinancingParties().pipe(
          map((response) => {
            return fromActions.getFinancingPartiesSuccess({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.getFinancingPartiesFailure({ payload: error }));
          })
        );
      })
    )
  );
  errorGetFinancingParties$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getFinancingPartiesFailure.type),
        tap(() => this.handleOnLoadAllErrors('Error fetching financing parties'))
      ),
    { dispatch: false }
  );

  //addFinanceParty
  addFinanceParty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addFinanceParty.type),
      mergeMap((action: fromActions.ActionWithPayload<string>) => {
        return this.service.addFinanceParty(action.payload).pipe(
          map((response) => {
            return fromActions.addFinancePartySuccess({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.addFinancePartyFailure({ payload: error }));
          })
        );
      })
    )
  );
  errorAddFinanceParty$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addFinancePartyFailure.type),
        tap(() => this.handleOnLoadAllErrors('Error adding financing party'))
      ),
    { dispatch: false }
  );



  controlCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onControlCoupon.type),
      mergeMap((action: fromActions.ActionWithPayload<string>) => {
        return this.service.controlCoupon(action.payload).pipe(
          map((response) => {
            return fromActions.onControlCouponSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onControlCouponFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnControlCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onActivateCouponFailed.type),
        tap((action: fromActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  exportCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onExportCostFile.type),
      mergeMap((action: fromActions.ActionWithPayload<ExportCostFileRequest>) => {
        return this.service.exportCost(action.payload).pipe(
          tap((response: Blob)=>{
            const fileName = `${action.payload.type}_${Date.now()}.csv`;
            return this.fileService.downloadBlob(response,fileName);
          }),
          map(() => {
            return fromActions.onExportCostFileSuccessfully();
          }),
      catchError(async (error) => {
          if (error.error instanceof Blob && error.error.type === 'application/json') {
            try {
              const text = await error.error.text();
              const errorObj = JSON.parse(text);
              const extendedError = {
                ...error,
                parsedMessage: errorObj.message, 
              };

              return fromActions.onExportCostFileFailed({ payload: extendedError });
            } catch (e) {
              return fromActions.onExportCostFileFailed({ payload: error });
            }
          } else {
            // Return an observable with the original error
            return fromActions.onExportCostFileFailed({ payload: error });
          }
        })
        );
      })
    )
  );

  errorOnExportCost$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onExportCostFileFailed.type),
        tap((action: fromActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllErrors(action.payload))
      ),
    { dispatch: false }
  );

  public handleOnLoadAllErrors(error: any) {
    debug(error);
    return error;
  }
}
