
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createAction, props } from '@ngrx/store';
import { IBlockedProductModel } from '../models/fraudDetectionProducts/blockedProducts.model';
import { IUnblockedProduct } from '../models/fraudDetectionProducts/IBlockedProduct';
import { UnBlockProductResponse } from '../services/admin.fraudDetectionProducts.service';

export const unBlockProduct = createAction(
    '[Admin Fraud Detection Products] unblock product',
    props<{ payload: string }>(),
);
export const unBlockProductSuccessfully = createAction(
    '[Admin Fraud Detection Products] unblock product successfully',
    props<{ payload: IUnblockedProduct }>(),
);
export const unBlockProductFailed = createAction(
    '[Admin Fraud Detection Products] unblock product failed',
    props<{ payload: UnBlockProductResponse }>(),
);
export const getBlockedProducts = createAction(
    '[Admin Fraud Detection Products] get blocked products',
);
export const getBlockedProductsSuccessfully = createAction(
    '[Admin Fraud Detection Products] get blocked products successfully',
    props<{ payload: IBlockedProductModel }>(),
);
export const getBlockedProductsFailed = createAction(
    '[Admin Fraud Detection Products] get blocked products failed',
    props<{ payload: any }>(),
);

export const unblockShop = createAction(
    '[Admin Fraud Detection Products] unblock shop'
);

export const onUnblockShopSuccessfully = createAction(
    '[Admin Fraud Detection Products] unblock shop successfully',
    props<{ payload: UnBlockProductResponse }>()
);

export const onUnblockShopFailed = createAction(
    '[Admin Fraud Detection Products] unblock shop failed',
    props<{ payload: HttpErrorResponse }>()
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}
