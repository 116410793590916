import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromActions from './../actions/admin-manageProductCategory.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { CreateUpdateCategoryRequestModel } from '../models/manageProductCategories/categories.models';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminManageProductCategoryProvider');
@Injectable({
    providedIn: 'root'
})
export class AdminManageProductCategoryProvider {

    constructor(private store: Store<fromFeature.AppState>) { }

    public getAllCagetories$(): Observable<any> {
        debug("Get All categories called");
        this.store.dispatch(fromActions.getAllProductCategory());
        return this.store.pipe(select(fromFeature.selectFeatureProductCategory));
    }

    public createCategory(category: CreateUpdateCategoryRequestModel) {

        this.store.dispatch(fromActions.createCategory({payload: category}));
    }

    public updateCategory(category: CreateUpdateCategoryRequestModel) {

        this.store.dispatch(fromActions.updateCategory({payload: category}));
    }

    public deleteCategory(id: string) {

        this.store.dispatch(fromActions.deleteCategory({payload: id}));
    }


    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(select(fromFeature.featureError),
            filter((error: HttpErrorResponse) => error != null)
        );
    }


}
