import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './skeleton/header/header.component';
import { SidebarComponent } from './skeleton/sidebar/sidebar.component';
import { ContentAreaComponent } from './skeleton/content-area/content-area.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomePage } from './pages/home/home.page';
import { FraudLimitsPage } from './pages/fraudLimits/fraudLimits.page';
import { LoginPage } from './pages/login/login.page';
import { ChangePasswordPage } from './pages/changePassword/changePassword.page';
import { SignupPage } from './pages/signup/signup.page';
import { ReportsPage } from './pages/reports/reports.page';
import { BasePageComponent } from './pages/base.page';
import { BlockedProductsPage } from './pages/blockedProducts/blockedProducts.page';
import { SharedModule } from './shared/shared.module';
import * as fromAppState from './state/app.state.reducer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { UpdateDefaultDialog } from './pages/fraudLimits/updateDialog/updateDefaultDialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminConfig, AdminModule, AUTH_INTERCEPTOR, AuthHandler } from '@modeso/dgoods-lib-admin-fe';
import { SessionOrchestrator } from './orchestrator/session.orchestrator';
import { PopUpComponent } from './pages/popUp/popUp';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { WhiteListingPage } from './pages/whiteListing/whiteListing.page';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AddOrUpdateIssuerDialog } from './pages/fraudLimits/addOrUpdateIssuerDialog/addOrUpdateIssuerDialog';
import { ManageContentPage } from './pages/manageContent/manageContent.page';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UpdateLocalizationByKeyDialog } from './pages/manageContent/updatelocalizationByKeyDialog/updateLocalizationByKeyDialog';
import { ProductManagmentPage } from './pages/productManagment/productManagment.page';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ContentManagementPage } from './pages/contentManagement/contentManagement.page';
import { ProductContentManagmentPage } from './pages/productContentManagement/productContentManagment.page';
import { UpdateContentByProductLineDialog } from './pages/productContentManagement/updateContentByProductLineDialog/updateContentByProductLineDialog';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { UserManagementPage } from './pages/userManagement/userManagement.page';
import { ManageUsersPage } from './pages/manageUsers/manageUsers.page';
import { UpdateUserByIdDialog } from './pages/manageUsers/updateUserByIdDialog/updateUserByIdDialog';
import { ClosedUserGroupPage } from './pages/closedUserGroup/closedUserGroup.page';
import { UserSearchFormComponent } from './pages/closedUserGroup/userSearchForm/userSearchForm.page';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ProductSettingsPage } from './pages/productSettings/productSettings.page';
import { AuditLogsPage } from './pages/auditLogs/auditLogs.page';
import { SamlAssertPage } from './pages/samlAssert/samlAssert.page';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { CreateMerchantPage } from './pages/merchantManagement/createMerchant/create-merchant.page';
import { MerchantListPage } from './pages/merchantManagement/merchant-list/merchant-list.page';
import { DigitalVoucherConfiguratorComponent } from './pages/digital-voucher-configurator/digital-voucher-configurator.component';
import { TextFormComponent } from './pages/digital-voucher-configurator/text-form/text-form.component';
import { AuthGuard } from './shared/auth.guard';
import { DropDownListConfiguratorComponent } from './pages/digital-voucher-configurator/drop-down-list-configurator/drop-down-list-configurator.component';
import { AddCouponPage } from './pages/couponManagement/addCoupon/add-coupon.page';
import { MatRadioModule} from '@angular/material/radio';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TwintCouponModule , TwintCouponConfig  } from '@modeso/twint-lib-couponsmng-fe';
import { CommonModule } from '@angular/common';
import { CouponsPage } from './pages/couponManagement/coupons.page';
import { CreateNewstickerPage } from './pages/newstickerManagment/create-newsticker/create-newsticker.page';
import { NewstickerListPage } from './pages/newstickerManagment/newsticker-list/newsticker-list.page';
import { DescriptionEditorDialog } from './pages/Dialogs/descriptionEditorDialog/descriptionEditorDialog';
import { DgoodsSharedModule, GetEnumKeyFromValuePipe, MarkdownPipe, ModesoCoreModule, PascalCasePipe, SafeHtmlPipe } from '@modeso/twint-lib-core-fe';
import { MatIconModule } from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import { ProductCategoriesPage } from './pages/productCategories/productCategories.page';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditCategoryDialogComponent } from './pages/productCategories/edit-category-dialog.component';
import { ConfirmationDialogComponent } from './pages/Dialogs/confirmation-dialog.component';
import { ContentPageListComponent } from './pages/contentPagesManagment/content-pages-list/content-pages-list.page';
import { CreateContentPageComponent } from './pages/contentPagesManagment/create-content-page/create-content-page.page';
import { SafeHtmlPipeImages } from './shared/markdown/markdownWithImages/safeHtml.pipe';
import { SettingsEditDialogComponent } from './pages/productSettings/confirmation-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule} from '@angular/material/button';
import { CreateSpotLightComponent } from './pages/spotlightManagement/create-spotlight/create-spot-light.component';
import { ListSpotLightsComponent } from './pages/spotlightManagement/list-spotlights/list-spotlights.component';
import { MatMenuModule } from '@angular/material/menu';
import { NgxFileDropModule } from 'ngx-file-drop';
 import { ContentBoxListComponent } from './pages/contentBoxesManagment/content-boxes-list/content-boxes-list.page';
import { CreateContentBoxComponent } from './pages/contentBoxesManagment/create-content-box/create-content-box.page';
import { SafeHtmlAllowUrlPipe } from './shared/pipes/safeHtmlAllowUrl.pipe';
import { DgoodsUserConfig, DgoodsUserModule } from '../../../dgoods-lib-user-fe/src/lib';

const adminConfig: AdminConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'admin'
};

const couponConfig: TwintCouponConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'cart'
};

const userConfig: DgoodsUserConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'users'
};

@NgModule({
  declarations: [
    AppComponent,
    BasePageComponent,
    HeaderComponent,
    SidebarComponent,
    ContentAreaComponent,
    HomePage,
    FraudLimitsPage,
    UpdateDefaultDialog,
    LoginPage,
    ChangePasswordPage,
    SignupPage,
    BlockedProductsPage,
    PopUpComponent,
    ReportsPage,
    AddOrUpdateIssuerDialog,
    ManageContentPage,
    UpdateLocalizationByKeyDialog,
    WhiteListingPage,
    ProductSettingsPage,
    AddOrUpdateIssuerDialog,
    ProductManagmentPage,
    UpdateContentByProductLineDialog,
    ContentManagementPage,
    ProductContentManagmentPage,
    ProductCategoriesPage,
    UserManagementPage, ManageUsersPage, UpdateUserByIdDialog,
    ClosedUserGroupPage, UserSearchFormComponent,
    AuditLogsPage,
    SamlAssertPage,
    CreateMerchantPage,
    MerchantListPage,
    DigitalVoucherConfiguratorComponent,
    TextFormComponent,
    DropDownListConfiguratorComponent,
    AddCouponPage,
    CouponsPage,
    CreateNewstickerPage,
    NewstickerListPage,
    DescriptionEditorDialog,
    EditCategoryDialogComponent,
    ConfirmationDialogComponent,
    ContentPageListComponent,
    CreateContentPageComponent,
    SafeHtmlPipeImages,
    SafeHtmlAllowUrlPipe,
    SettingsEditDialogComponent,
    ListSpotLightsComponent,
    CreateSpotLightComponent,
    CreateContentBoxComponent,
    ContentBoxListComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    StoreModule.forRoot([]),
    StoreModule.forFeature(fromAppState.appStateFeatureKey, fromAppState.reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    DgoodsSharedModule,
    ModesoCoreModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule.forRoot(adminConfig),
    TwintCouponModule.forRoot(couponConfig),
    DgoodsUserModule.forRoot(userConfig),
    BrowserAnimationsModule,
    DragDropModule,
    MatDialogModule,
    MatSelectModule,
    MatNativeDateModule, MatFormFieldModule, MatInputModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatPaginatorModule,
    MatListModule,
    MatSnackBarModule, MatCheckboxModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    AngularEditorModule,
    MatTableExporterModule,
    MatRadioModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    MatMomentDateModule,
    NgxMatMomentModule,
    MatIconModule,
    MatSortModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatMenuModule,
    NgxFileDropModule
  ],
  entryComponents: [
    PopUpComponent, UpdateDefaultDialog,
    AddOrUpdateIssuerDialog, UpdateLocalizationByKeyDialog, UpdateContentByProductLineDialog,
    UpdateUserByIdDialog
  ],
  providers: [
    AuthGuard,
    GetEnumKeyFromValuePipe,
    PascalCasePipe,
    SafeHtmlPipe,
    MarkdownPipe,
    SafeHtmlPipeImages,
    SafeHtmlAllowUrlPipe,
    { provide: AUTH_INTERCEPTOR, useClass: SessionOrchestrator },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHandler, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }
