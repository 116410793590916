import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dgoods-dots-loading-indicator',
  templateUrl: './dgoods-dots-loading-indicator.component.html',
  styleUrls: ['./dgoods-dots-loading-indicator.component.scss']
})
export class DgoodsDotsLoadingIndicatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
