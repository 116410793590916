import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DefaultFraudLimitsRequest } from '@modeso/dgoods-lib-admin-fe';

@Component({
    selector: 'app-update-default-dialog',
    templateUrl: 'updateDefaultDialog.html',
    styleUrls: ['./updateDefaultDialog.scss'],
})
export class UpdateDefaultDialog {
    updateDefaultFraudForm: FormGroup;
    constructor(
        public dialogRef: MatDialogRef<UpdateDefaultDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        const valueValidators = new Array<any>();
        valueValidators.push(Validators.required);
        const extraValidator = Validators.max(99999999);
        valueValidators.push(extraValidator);


        this.updateDefaultFraudForm = new FormGroup({
            name: new FormControl({
                value: data.updatedDefaultValue.fraudLimit,
                disabled: true
            }, { validators: [Validators.required] }),
            value: new FormControl(data.updatedDefaultValue.fraudLimitValue, { validators: valueValidators }),
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
    save() {
        if (this.updateDefaultFraudForm.invalid) {
            return;
        } else {
            const updatedDefaultFraud: DefaultFraudLimitsRequest = {
                fraudLimit: this.data.updatedDefaultValue.fraudLimit,
                fraudLimitValue: this.updateDefaultFraudForm.value.value,
            };
            this.dialogRef.close(updatedDefaultFraud);

        }
    }

    buttonStatus() {
        return this.updateDefaultFraudForm.invalid;
    }

}
