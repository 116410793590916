import { Injectable } from '@angular/core';
import * as fromFeature from './../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromActions from './../actions/admin-auditLogs.actions';
import { AuditLogsResponse } from '../models/auditLogs/auditLogsResponse.model';
import { AuditLogsRequest } from '../models/auditLogs/auditLogsRequest.model';

@Injectable({
    providedIn: 'root'
})
export class AdminAuditLogsProvider {

    constructor(private store: Store<fromFeature.AppState>) { }

    public getAuditLogs$(auditLogsRequest: AuditLogsRequest): Observable<AuditLogsResponse> {
        this.store.dispatch(fromActions.getAuditLogs({ payload: auditLogsRequest }));
        return this.store.pipe(select(fromFeature.featureAuditLogsResponse));
    }

    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(select(fromFeature.featureError),
            filter((error: HttpErrorResponse) => error != null)
        );
    }
}
