import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminManageContentService } from '../services/admin-manageContent.service';
import * as fromActions from '../actions/admin-manageContent.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { AllTranslationsResponse } from '../models/manageContent/allTranslationsResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationsByKeyResponseDetails } from '../models/manageContent/translationByKeyResponse.model';


const debug = Debug('modeso:modeso-lib-admin-fe:AdminManageContentEffects');


@Injectable()
export class AdminManageContentEffects {
    constructor(private actions$: Actions, private service: AdminManageContentService) { }

    getAllLocalizations$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getAllLocalizations.type),
            mergeMap(
                () => {
                    return this.service.getAllLocalizations()
                        .pipe(
                            map(
                                (response: AllTranslationsResponse) => {
                                    return fromActions.onGetAllLocalizationsSuccessfully({ payload: response.translations });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onGetAllLocalizationFailed({ payload: error }))
                            })
                        );
                }
            )
        )
    );

    errorOnGetAllLocalization$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetAllLocalizationFailed.type),
            tap(
                (action: fromActions.IActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    updateLocalizationByKey$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateLocalizationByKey.type),
            mergeMap(
                (payload: any) => {
                    return this.service.updateLocalizationByKey(payload)
                        .pipe(
                            map(
                                (response: TranslationsByKeyResponseDetails) => {
                                    return fromActions.onUpdateLocalizationByKeySuccessfully({ payload: response })
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onUpdateLocalizationByKeyFailed({ payload: error }))
                            })
                        );
                }
            )
        )
    );

    errorOnUpdateLocalizationByKey$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateLocalizationByKeyFailed.type),
            tap(
                (action: fromActions.IActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );


    handleOnLoadAllCartErrors(error) {
        debug(error);
        return error;
    }
}

