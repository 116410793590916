<div class="dgoods-price-option-group">
  <ng-container *ngFor="let price of prices">
    <dgoods-price-option *ngIf="!price.isDenomination" class="dgoods-price-option-group-items" [delegate]="this"
    [price]="price" [selected]="price.selected" [value]="price.value" [currency]="price.currency" [reference]="price.reference" [prices]="prices">

      <ng-container *ngTemplateOutlet="templateRef; context: {$implicit: price}"></ng-container>
    </dgoods-price-option>

    <dgoods-price-option-denomination *ngIf="price.isDenomination" class="dgoods-price-option-denomination"
    [price]="price" [delegate]="this" [selected]="price.selected" [value]="price.value" [currency]="price.currency" [reference]="price.reference">
      <ng-container *ngTemplateOutlet="templateRef; context: {$implicit: price}"></ng-container>
    </dgoods-price-option-denomination>

  </ng-container>
  <div *ngIf="!hasEvenPrices()" class="dgoods-price-option-group-items" >

  </div>
</div>
