import { Action, createReducer, on, createSelector } from '@ngrx/store';

export const appStateFeatureKey = 'modesoAppState';

export interface FeatureState {
    test: string;
}

export interface AppState {
    modesoAppState: FeatureState;
}

export const selectFeature = (state: AppState) => state.modesoAppState;
export const selectedTest = createSelector(
    selectFeature,
    (state: FeatureState) => state.test
);

export const initialState: FeatureState = {
    test: "Hello World",
};

const appStateReducer = createReducer(
    initialState,
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return appStateReducer(state, action);
}
