import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUploadURL } from './uploadUrl.model';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private server = 'http://localhost:3001/api/';
  private microserviceName = 'files';

  constructor(private http: HttpClient) {
    this.server = environment.modeso.apiGateway;
  }

  public getUploadUrl(filename, formData: FormData): Observable<IUploadURL> {

    const response = this.http.post<IUploadURL>(`${this.server}${this.microserviceName}/url-for/${filename}`, formData);
    return response;
  }

  public uploadFile(url: string, file: File, contentType: string): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': contentType,
    });
    return this.http.put(url, file, {
      headers,
    });
  }

  public deleteFile(fileId: string) {
    const url = `${this.server}${this.microserviceName}/${fileId}`;
    return this.http.delete<void>(url);
  }


}
