export class TranslationsByKeyResponse {
    public translations: TranslationsByKeyResponseDetails;
}

export class TranslationsByKeyResponseDetails {
    public key: string;
    public text: TextDatails;
}

export class TextDatails {
    public 'de-ch': string;
    public 'fr-ch': string;
    public 'it-ch': string;
    public 'en-us': string;
}
