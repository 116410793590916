<div mat-dialog-content>
    <form class="update-default-form" [formGroup]="updateDefaultFraudForm">
        <div class="form-group">
            <label>Name</label>
            <div class="input-wrapper">
                <input id="name" class="form-control" type="text" formControlName="name">

            </div>
        </div>
        <div class="form-group">
            <label>Value</label>
            <div class="input-wrapper">
                <input id="value" class="form-control" type="text" formControlName="value" placeholder="Value"
                    oninput="this.value = this.value.replace(/[^1-9.]/g, '') && (parseInt(this.value));" min="1">

            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="dialog-buttons">
    <div class="action-button">
        <button mat-button (click)="onNoClick()" class="button">Cancel</button>
        <button mat-button [mat-dialog-close]="data" [disabled]="buttonStatus()" cdkFocusInitial (click)="save()"
            class="button">Update</button>
    </div>

</div>