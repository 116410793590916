import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-confirm-feature-dialog',
    templateUrl: './confirmFeatureDialog.html',
    styleUrls: ['./confirmFeatureDialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmFeatureDialog {

  activate;

    constructor(
        public dialogRef: MatDialogRef<ConfirmFeatureDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.activate = data.activate;
    }

    confirm(): void {
        this.dialogRef.close(true);
    }
    cancel(): void {
        this.dialogRef.close(false);
    }

}
