import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generate-codes',
  templateUrl: './confirmationDialog.html',
  styleUrls: ['./confirmationDialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmationDialog {
  type: number;
  text = "";

  constructor(public dialogRef: MatDialogRef<ConfirmationDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.type = data.type;
    this.text = data.text
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
