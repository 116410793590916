
  <ng-container >
    <div [formGroup]="newstickerForm">
      <div class="mb-2">Newsticker Details</div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>
            Name
            <span class="input_required">*</span>
          </mat-label>
          <input formControlName="name" matInput />
          <mat-error> Name is Required </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill">
          <mat-label>
            Type
            <span class="input_required">*</span>
          </mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of typeEnum" [value]="type">
              {{type | pascalcase}}
            </mat-option>
          </mat-select>
          <mat-error>
            Type is Required
          </mat-error>
        </mat-form-field>
      </div>


      <div class="my-2 align-center" >
        <div>
          Text <span class="input_required">*</span>
        </div>

        <!-- de-ch -->

        <div class="mx-3">
          <div class="desc-div">

            <mat-label>
              German
              <span class="input_required">*</span>
            </mat-label>
            <div (click)="addText('de-ch')">
              <div class="textarea-field text-ellipsis" [innerHtml]="newstickerForm?.get('text')?.get('de-ch')?.value | markdownToSafeHtml">
              </div>
            </div>
          </div>
        </div>

        <!-- en-us -->
        <div class="mx-3">
          <div class="desc-div">
            <mat-label>
              English
              <span class="input_required">*</span>
            </mat-label>
            <div (click)="addText('en-us')">
              <div  class="textarea-field text-ellipsis" [innerHtml]="newstickerForm?.get('text')?.get('en-us')?.value | markdownToSafeHtml"></div>
            </div>
          </div>
        </div>

        <!-- fr-ch -->
        <div class="mx-3">
          <div class="desc-div">
            <mat-label>
              French
              <span class="input_required">*</span>
            </mat-label>
            <div (click)="addText('fr-ch')">
              <div class="textarea-field text-ellipsis"  [innerHtml]="newstickerForm?.get('text')?.get('fr-ch')?.value | markdownToSafeHtml"></div>
            </div>
          </div>
        </div>

        <!-- it-ch -->
        <div class="mx-3">
          <div class="desc-div">
            <mat-label >
              Italian
              <span class="input_required">*</span>
            </mat-label>
            <div  (click)="addText('it-ch')">
              <div class="textarea-field text-ellipsis"  [innerHtml]="newstickerForm?.get('text')?.get('it-ch')?.value | markdownToSafeHtml"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div>
      <button class="btn save-btn m-3" [disabled]="newstickerForm.invalid " (click)="save()">  Save  </button>
      <button  class="btn cancel-btn" (click)="cancel()">  Cancel</button>
    </div>

    <div *ngIf="isError">
      <mat-error>{{errorMessage}}</mat-error>
    </div>
  </ng-container>
