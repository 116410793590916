export interface IconMapping {
  [key: string]: string;
}

export const iconMapping: IconMapping = {
  'account': 'account.svg',
  'apps': 'apps.svg',
  'contact_details': 'contact_details.svg',
  'credits_and_vouchers': 'credits_and_vouchers.svg',
  'email': 'email.svg',
  'faqs': 'faqs.svg',
  'gaming': 'gaming.svg',
  'loyalty': 'loyalty.svg',
  'orders': 'orders.svg',
  'payment': 'payment.svg',
  'promotions': 'promotions.svg',
  'shops': 'shops.svg',
  'software': 'software.svg',
  'streaming': 'streaming.svg',
  't&cs': 't&cs.svg',
  // Add more mappings as needed
  'fashion': 'fashion.svg',
  'supervoucher': 'supervoucher.svg',
  'telco': 'telco.svg',
  'tickets': 'tickets.svg',
  'travel': 'travel.svg',
};


export const getSvgPath = (iconName: string): string => {
  return `assets/svgs/light/${iconMapping[iconName]}`;
}
