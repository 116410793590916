import { Component, Input } from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsOverlayComponent');
export interface IDGoodsDialogBoxDelegate {
  onDialogBoxButtonClicked(): void;
}

@Component({
  selector: 'dgoods-dialogbox',
  templateUrl: './dgoods-dialogbox.component.html',
  styleUrls: ['./dgoods-dialogbox.component.scss']
})
export class DGoodsDialogBoxComponent {
  @Input() hidden?: boolean;
  @Input() delegate?: IDGoodsDialogBoxDelegate;
  @Input() dialogBoxData;
  @Input() descriptionFromLocalization?: boolean;

  onClick() {
    if (this.delegate) {
      this.delegate.onDialogBoxButtonClicked();
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }
}
