<div class="overlay" [hidden]="hidden">
  <div class="dialogbox-style">
    <div class="dialogbox-content">
      <div class="title">
        {{dialogBoxData.title | injectedText | async }}
      </div>
      <div class="description" *ngIf="descriptionFromLocalization">
        {{dialogBoxData.description | injectedText | async}}
      </div>
      <div class="description" *ngIf="!descriptionFromLocalization">
        {{dialogBoxData.description}}
      </div>
    </div>
    <div class="dialogbox-button-div">
      <button class="dialogbox-button" type="button" (click)="onClick()">
        {{ "dgoods_shared_overlaycompontent_ok" | injectedText | async }}</button>
    </div>
  </div>
</div>
