import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import Debug from 'debug';
import * as fromFeature from '../reducers/twint.coupons.reducer';
import * as fromActions from '../actions/twint.coupons.actions';
import { CouponDTO, CouponFilterDTO } from '@modeso/types__twint-lib-coupons';
import { IFinancingParty } from '../models/interfaces/finance';
import { ExportCostFileRequest } from '../models/interfaces/export-cost-file-request.interface';
import { HttpErrorResponse } from '@angular/common/http';

const debug = Debug('twint:coupons:TwintCouponsProvider');

@Injectable({
  providedIn: 'root',
})
export class TwintCouponsProvider {
  constructor(public store: Store<fromFeature.AppState>) { }

  public createCoupon(body: CouponDTO) {
    this.store.dispatch(fromActions.onAddCoupon({ payload: body }));
  }

  public editCoupon(body: CouponDTO) {
    this.store.dispatch(fromActions.onUpdateCoupon({ payload: body }));
  }

  public getSelectedCoupon$(): Observable<CouponDTO> {
    return this.store.pipe(select(fromFeature.selectCouponById()));
  }

  public dispatchGetCouponById$(couponId): void {
    this.store.dispatch(fromActions.fetchCouponDetailsById({ payload: couponId }));
  }

  public getCoupons$(): Observable<CouponDTO[]> {
    return this.store.pipe(select(fromFeature.selectFeatureCoupons));
  }

  public fetchCoupons(data?: CouponFilterDTO): void {
    this.store.dispatch(fromActions.onGetCoupons({ payload: data }));
  }

  public getCouponError$(): Observable<any> {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsError));
  }

  public flushError(): any {
    this.store.dispatch(fromActions.flushCouponError());
  }

  public deleteCoupon(couponId: string): any {
    this.store.dispatch(fromActions.onDeleteCoupon({ payload: couponId }));
  }

  public deactivateCoupon(couponId: string): any {
    this.store.dispatch(fromActions.onDeactivateCoupon({ payload: couponId }));
  }

  public activateCoupon(couponId: string): any {
    this.store.dispatch(fromActions.onActivateCoupon({ payload: couponId }));
  }

  public toggleCouponsFeature(couponFeature: boolean) {
    this.store.dispatch(fromActions.onToggleCouponsFeature({ payload: couponFeature }));
  }

  public checkCouponsFeature() {
    this.store.dispatch(fromActions.onCheckCouponsFeature());
  }

  public getCouponsFeature$() {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsFeature));
  }
  
  public getCouponsFeatureInitializationState$() {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsInitialized));
  }

  public invalidateCode(invalidateCodeObj): any {
    this.store.dispatch(fromActions.onInvalidateCode(invalidateCodeObj));
  }

  public invalidateCodes(couponId: string) {
    this.store.dispatch(fromActions.onInvalidateCodes({ payload: couponId }));
  }

  public archiveCoupon(archiveCouponObj): any {
    this.store.dispatch(fromActions.onArchiveCoupon({ payload: archiveCouponObj }));
  }

  public controlCoupon(couponId: string): void {
    this.store.dispatch(fromActions.onControlCoupon({ payload: couponId }));
  }

  // dispatch get financing parties and also create a selector for it
  public dispatchGetFinancingParties(): void {
    this.store.dispatch(fromActions.getFinancingParties());
  }

  public selectFinancingParties(): Observable<IFinancingParty[]> {
    return this.store.pipe(select(fromFeature.selectFinancingParties));
  }

  public addFinanceParty(name: string): void {
    this.store.dispatch(fromActions.addFinanceParty({ payload: name }));
  }

  public exportCostFile(data: ExportCostFileRequest): void {
    this.store.dispatch(fromActions.onExportCostFile({ payload: data }));
  }

  public getSuccessState$(): Observable<boolean> {
    return this.store.pipe(select(fromFeature.selectCouponSuccessState));
  }

  public getExportErrorState$(): Observable<HttpErrorResponse> | undefined {
    return this.store.pipe(select(fromFeature.selectExportCostErrorState));
  }
  public getExportSuccessState$(): Observable<boolean> {
    return this.store.pipe(select(fromFeature.selectExportCostSuccessState));
  }

  public resetExportCostState(): void {
    this.store.dispatch(fromActions.resetExportCostState());
  }

}
