import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from '../actions/twint.coupons.actions';
export const featureKey = 'twintCouponModule';
import Debug from 'debug';
import { HttpErrorResponse } from '@angular/common/http';
import { CouponDTO } from '@modeso/types__twint-lib-coupons';
import { IFinancingParty } from '../models/interfaces/finance';
const debug = Debug('twint:coupons:Reducer');
// State Declarations - START

export interface FeatureState {
  coupon: CouponDTO;
  coupons: CouponDTO[];
  couponsError: string | HttpErrorResponse;
  couponsFeature: boolean;
  initialized: boolean;
  financingParties: IFinancingParty[];
  loading: boolean;
  exportErrorState: HttpErrorResponse | null;
  exportSuccessState: boolean;
}

export interface AppState {
  twintCouponModule: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.twintCouponModule;

export const selectFeatureCoupons = createSelector(selectFeature, (state: FeatureState) => state.coupons);

export const selectCouponById = () => createSelector(selectFeature, (state: FeatureState) => state.coupon);

export const selectFeatureCouponsError = createSelector(selectFeature, (state: FeatureState) => state.couponsError);
export const selectFeatureCouponsFeature = createSelector(selectFeature, (state: FeatureState) => state.couponsFeature);
export const selectFeatureCouponsInitialized = createSelector(selectFeature, (state: FeatureState) => state.initialized);
export const selectCouponSuccessState = createSelector(selectFeature, (state: FeatureState) => state.loading);
export const selectExportCostErrorState = createSelector(selectFeature, (state: FeatureState) => state.exportErrorState);
export const selectExportCostSuccessState = createSelector(selectFeature, (state: FeatureState) => state.exportSuccessState);
export const selectFinancingParties = createSelector(selectFeature, (state: FeatureState) => state.financingParties);

// Selectors Declarations - END

// Reducer Declarations - START
export const initialState: FeatureState = {
  coupons: [],
  coupon: undefined,
  couponsError: undefined,
  couponsFeature: false,
  initialized: false,
  financingParties: [],
  loading: false,
  exportErrorState: undefined,
  exportSuccessState: false
};

const couponsReducer = createReducer(
  initialState,

  // add coupon
  on(fromActions.onAddCoupon, (state) => ({ ...state, couponsError: undefined })),
  on(fromActions.onAddCouponSuccessfully, (state, action) => ({ ...state, couponsError: undefined })),
  on(fromActions.onAddCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // edit coupon
  on(fromActions.onUpdateCoupon, (state) => ({ ...state })),
  on(fromActions.onUpdateCouponSuccessfully, (state, action) => ({ ...state, couponsError: undefined })),
  on(fromActions.onUpdateCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // get all coupons
  on(fromActions.onGetCoupons, (state) => ({
    ...state,
    coupons: [],
  })),
  on(fromActions.onGetCouponsSuccessfully, (state, action) => {
    return { ...state, coupons: action.payload, couponsError: undefined };
  }),
  on(fromActions.onGetCouponsFailed, (state, action) => {
    return { ...state, couponsError: action.payload };
  }),

  // ToggleCouponsFeature
  on(fromActions.onToggleCouponsFeature, (state) => ({ ...state, couponsError: undefined })),
  on(fromActions.onToggleCouponsFeatureSuccessfully, (state, action) => {
    return { ...state, couponsFeature: action.payload, couponsError: undefined };
  }),
  on(fromActions.onToggleCouponsFeatureFailed, (state, action) => {
    return { ...state, couponsError: action.payload };
  }),
  // CheckCouponsFeature
  on(fromActions.onCheckCouponsFeature, (state) => ({ ...state, couponsError: undefined })),
  on(fromActions.onCheckCouponsFeatureSuccessfully, (state, action) => {
    return { ...state, couponsFeature: action.payload, couponsError: undefined, initialized: true };
  }),
  on(fromActions.onCheckCouponsFeatureFailed, (state, action) => {
    return { ...state, couponsError: action.payload, initialized: true };
  }),

  // activate coupon
  on(fromActions.onActivateCoupon, (state) => ({ ...state })),
  on(fromActions.onActivateCouponSuccessfully, (state, action) => {
    const payload = action.payload;
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === payload.id);

    if(updatedIndex !== -1){
      updatedCoupons[updatedIndex] = {...updatedCoupons[updatedIndex], state: action.payload.state, controlled: action.payload.controlled}
    }else{
      updatedCoupons.push({ ...payload })
    }

    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onActivateCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // deactivate coupon
  on(fromActions.onDeactivateCoupon, (state) => ({ ...state })),
  on(fromActions.onDeactivateCouponSuccessfully, (state, action) => {
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === action.payload.id);

    if(updatedIndex !== -1){
      updatedCoupons[updatedIndex] = {...updatedCoupons[updatedIndex], state: action.payload.state, controlled: action.payload.controlled}
    }else{
      updatedCoupons.push({ ...action.payload })
    }

    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onDeactivateCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // delete coupon
  on(fromActions.onDeleteCoupon, (state) => ({ ...state })),
  on(fromActions.onDeleteCouponSuccessfully, (state, action) => {
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === action.payload);
    updatedCoupons.splice(updatedIndex, 1);
    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onDeleteCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // invalidate code
  on(fromActions.onInvalidateCode, (state) => ({ ...state })),
  on(fromActions.onInvalidateCodeSuccessfully, (state, action) => {

    return { ...state,  coupon: action.payload, couponsError: undefined };
  }),
  on(fromActions.onInvalidateCodeFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // invalidate codes
  on(fromActions.onInvalidateCodes, (state) => ({ ...state })),
  on(fromActions.onInvalidateCodesSuccessfully, (state, action) => {

    return { ...state, coupon: action.payload, couponsError: undefined };
  }),
  on(fromActions.onInvalidateCodesFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // archive coupon
  on(fromActions.onArchiveCoupon, (state) => ({ ...state })),
  on(fromActions.onArchiveCouponSuccessfully, (state, action) => {
    const payload = action.payload;
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === payload.id);

    if(updatedIndex !== -1){
      updatedCoupons[updatedIndex] = {
        ...updatedCoupons[updatedIndex],
        archived: action.payload.archived,
        controlled: action.payload.controlled,
       // as state gets updated if it was active then archived
        state: action.payload.state
      }
    }else{
      updatedCoupons.push({ ...action.payload })
    }

    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onArchiveCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // flush coupon errors
  on(fromActions.flushCouponError, (state, action) => {
    return {...state, couponsError:undefined};
  }),

  on(fromActions.fetchCouponDetailsById, (state, action) => {
    return { ...state, coupon: null, couponsError: undefined };
  }),

   // fetch coupon details by id
  on(fromActions.onFetchCouponDetailsCouponSuccessfully, (state, action) => {
    return { ...state, coupon: action.payload, couponsError: undefined };
  }),

  on(fromActions.onControlCoupon, (state) => ({ ...state })),
  on(fromActions.onControlCouponSuccessfully, (state, action) => {
    const coupon = {... state.coupon,
      controlled: action.payload.controlled,
    }
    return { ...state, coupon, couponsError: undefined };
  }),
  on(fromActions.onControlCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  on(fromActions.getFinancingPartiesSuccess, (state, action) => {
    return { ...state, financingParties: action.payload };
  }),
  on(fromActions.getFinancingPartiesFailure, (state, action) => {
    return { ...state, couponsError: action.payload };
  }),
  on(fromActions.addFinancePartySuccess, (state, action) => {
    const party = state.financingParties.find((party) => party.name.toLowerCase() === action.payload.name.toLowerCase());
    if (!party) {
      return { ...state, financingParties: [...state.financingParties, action.payload] };
    }
    return state;
  }),
  on(fromActions.addFinancePartyFailure, (state, action) => {
    return { ...state, couponsError: action.payload };
  }),

  
  on(fromActions.onExportCostFile, (state) => ({ ...state, loading: true })),
  on(fromActions.onExportCostFileSuccessfully, (state, action) => {
    return { ...state, loading: false , exportErrorState: null , exportSuccessState: true };
  }),
  on(fromActions.onExportCostFileFailed, (state, action) => { 
    return {...state, exportErrorState: action.payload, loading: false, exportSuccessState: false }}),

  on(fromActions.resetExportCostState, (state, action) => {
      return {...state, exportErrorState: null, loading: false , exportSuccessState: false};
  }),

);

export function reducer(state: FeatureState | undefined, action: Action) {
  return couponsReducer(state, action);
}
// Reducer Declarations - END
