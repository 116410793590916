import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { INewsticker,  NewstickerTypes , PublishStatus, INewstickerResponse} from '@modeso/types__dgoods-products';
import { MatDialog } from '@angular/material/dialog';
import { AdminNewstickerProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-newsticker.provider';
import { DescriptionEditorDialog } from '../../Dialogs/descriptionEditorDialog/descriptionEditorDialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-newsticker',
  templateUrl: './create-newsticker.page.html',
  styleUrls: ['./create-newsticker.page.scss']
})
export class CreateNewstickerPage implements OnInit , OnDestroy {
  newstickerForm: FormGroup;
  subscription = new Subscription();
  newsTickerObject: INewstickerResponse;
  typeEnum = Object.keys(NewstickerTypes);
  isError = false;
  errorMessage : string;


  errorhandler = (errorResponse: HttpErrorResponse) => {
    if (errorResponse === undefined) {
      return;
    }
    this.isError = true;
    if (errorResponse.error.duplicateKey === true) {
      this.errorMessage ="This name already Exists";
    } else {
      this.errorMessage ="Something went wrong";
    }
  }
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private adminProductsProvider: AdminNewstickerProvider,
    private activatedRoute: ActivatedRoute,
    private router: Router,

  ) {  }
  ngOnInit() {
    this.newstickerForm = this.formBuilder.group({
      name: this.formBuilder.control('', Validators.required),
      text: this.formBuilder.group({
        'de-ch': this.formBuilder.control('', Validators.required),
        'fr-ch': this.formBuilder.control('', Validators.required),
        'en-us': this.formBuilder.control('', Validators.required),
        'it-ch': this.formBuilder.control('', Validators.required),
      }),
      type: this.formBuilder.control('', Validators.required),
    });


    const tickerId = this.activatedRoute.snapshot.params.id;

    if (tickerId) {
      this.subscription.add(
      this.adminProductsProvider.getNewstickerList().pipe(take(2)).subscribe( (stickers: INewstickerResponse[]) => {
        if (stickers && stickers.length) {
          this.newsTickerObject = stickers.find( (item) => item._id === tickerId);
          if (this.newsTickerObject) {
            this.populateFields(this.newsTickerObject);
          }
        }
      }));
    }
  }

  populateFields(newsticker: any) {
    const { name, type, text } = newsticker;
    const { _id: textId, ...textValues } = text;

    this.newstickerForm.setValue({
      name,
      type : type || '',
      text: textValues,
    });
  }

  addText(language) {
    const description = this.newstickerForm.get('text').get(language).value;
    const dialogRef = this.dialog.open(DescriptionEditorDialog, {
        data: {
            title: 'Add text',
            description,
        },
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        this.newstickerForm.get('text').get(language).setValue(result);
        }
    });
  }

  save() {
    const newsticker: INewsticker = {
      ...this.newstickerForm.value,
      name: this.newstickerForm.value.name.trim(),
      text: {
        'en-us': this.newstickerForm.value.text['en-us'].trim(),
        'de-ch': this.newstickerForm.value.text['de-ch'].trim(),
        'fr-ch': this.newstickerForm.value.text['fr-ch'].trim(),
        'it-ch': this.newstickerForm.value.text['it-ch'].trim(),
      },

      status: PublishStatus.UNPUBLISHED,
    };

    if (this.newsTickerObject) {
      const newstickerWithId: INewstickerResponse = {
        ...newsticker,
        _id: this.newsTickerObject._id,
      };
      this.adminProductsProvider.updateNewsticker(newstickerWithId);

    } else {
      this.adminProductsProvider.addNewsticker(newsticker);
    }
    this.adminProductsProvider.getError$().subscribe(this.errorhandler);
  }

  cancel() {
    this.router.navigate([`/de-ch/newstickers/`]);
  }

   get name(): AbstractControl {
    return this.newstickerForm.get('name');
  }
  get type(): AbstractControl {
    return this.newstickerForm.get('type');

  }
  get text(): AbstractControl {
    return this.newstickerForm.get('text');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
