export class Goods {
    public summary: GoodsSummaryDetails;
    public brands: GoodsBrandDetails[];

}

export class GoodsSummaryDetails {
    public totalOrders: number;
    public canceldByShop: number;
    public orderSuccess: number;
    public useAsGfit: number;
    public usePersonal: number;
    public isOwnAmount: number;
    public isPredefinedValue: number;
    public soldTotal: number;
    public soldAvarage: number;
    public discounted: number;
    public totalDiscounts: number
}

export class GoodsBrandDetails {
    public _id: string;
    public totalOrders: number;
    public canceldByShop: number;
    public orderSuccess: number;
    public useAsGfit: number;
    public usePersonal: number;
    public isOwnAmount: number;
    public isPredefinedValue: number;
    public soldTotal: number;
    public soldAvarage: number;
    public discounted: number;
    public totalDiscounts: number
}
