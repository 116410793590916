import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateUpdateCategoryRequestModel } from '@modeso/dgoods-lib-admin-fe/models/manageProductCategories/categories.models';
import { CategoryDto } from '@modeso/types__dgoods-products';
import Debug from 'debug';
import { IconMapping, iconMapping, getSvgPath } from '../../shared/models/icons';
const debug = Debug('modeso:dgoods-admin:EditCategoryDialogComponent');

@Component({
  selector: 'app-edit-category-dialog',
  templateUrl: './edit-category-dialog.component.html',
  styleUrls: ['./edit-category-dialog.component.scss']
})
export class EditCategoryDialogComponent {
  category: CategoryDto | CreateUpdateCategoryRequestModel;
  icons: string[];
  iconMapping: IconMapping = iconMapping

  constructor(
    public dialogRef: MatDialogRef<EditCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CategoryDto | CreateUpdateCategoryRequestModel
  ) {
    this.category = data;
    this.icons = Object.keys(this.iconMapping);
    debug(data)
  }

  getIconSvg(iconName: string): string {
    return getSvgPath(iconName)
  }

  submit() {
    this.dialogRef.close(this.category);
  }

  cancel() {
    this.dialogRef.close();
  }
}
