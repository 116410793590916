import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BasePageComponent } from '../../base.page';
import { AdminContentBoxProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-content-box.provider';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../Dialogs/confirmation-dialog.component';
import { take } from 'rxjs';
import { AdminWhiteListingProductsProvider } from '@modeso/dgoods-lib-admin-fe';
import { SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';
import { SafeHtmlAllowUrlPipe } from '../../../shared/pipes/safeHtmlAllowUrl.pipe';

@Component({
  selector: 'app-create-content-box',
  templateUrl: './create-content-box.page.html',
  styleUrls: ['./create-content-box.page.scss'],
})
export class CreateContentBoxComponent extends BasePageComponent implements OnInit {
  contentBoxForm: FormGroup;
  selectedLanguage: string;
  isError: boolean;
  errorMessage: string;
  contentBox: IContentBoxResponse;
  updatedContentBox: IContentBoxResponse;
  isUnsavedChanges = false;
  selectedTabIndex: number = 0;
  languagesTabsData: any[] = [];
  allProductsLineNames: string[] = [];
  showPreview: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private contentBoxProvider: AdminContentBoxProvider,
    private productProvider: AdminWhiteListingProductsProvider,
    private pipe: SafeHtmlAllowUrlPipe,
    public dialog: MatDialog,

    injector: Injector
  ) {
    super(injector);

    this.languagesTabsData = [
      {
        label: 'English (en_us)',
        value: 'en_us',
      },
      {
        label: 'German (de_ch)',
        value: 'de_ch',
      },
      {
        label: 'French (fr_ch)',
        value: 'fr_ch',
      },
      {
        label: 'Italian (it_ch)',
        value: 'it_ch',
      },
    ];

    this.contentBoxForm = this.formBuilder.group({
      productlineId: ['', Validators.required],
      title: ['', [Validators.required]],
      content: ['', Validators.required],
    });

    this.selectedLanguage = 'en_us';
    this.isError = false;
    this.errorMessage = '';
    this.contentBox = {
      productlineId: '',
      title: {
        en_us: '',
        de_ch: '',
        fr_ch: '',
        it_ch: '',
      },
      content: {
        en_us: '',
        de_ch: '',
        fr_ch: '',
        it_ch: '',
      },
      published: false,
    };
    this.updatedContentBox = {
      _id: '',
      productlineId: '',
      title: {
        en_us: '',
        de_ch: '',
        fr_ch: '',
        it_ch: '',
      },
      content: {
        en_us: '',
        de_ch: '',
        fr_ch: '',
        it_ch: '',
      },
      published: false,
    };
  }

  ngOnInit(): void {
    this.contentBoxForm.reset(); // Clear all form values and reset state

    this.loadContentBox();
  }

  loadContentBox() {

     if (this.activatedRoute.snapshot.params.id) {
      let found = false;
      this.contentBoxProvider.selectAllContentBoxes().pipe(take(1)).subscribe((contentBoxes) => {
        if (contentBoxes) {
          for (const contentBox of contentBoxes) {
            if (contentBox._id === this.activatedRoute.snapshot.params.id) {
              this.contentBox = JSON.parse(JSON.stringify(contentBox));
              this.updatedContentBox = JSON.parse(JSON.stringify(contentBox));
              found = true;
            }
          }
        }
        if (!found) {
          this.fetchContentBoxById();
        }

        this.selectedLanguage = 'en_us';
        this.contentBoxForm.patchValue({
          productlineId: this.updatedContentBox.productlineId,
          title: this.updatedContentBox.title[this.selectedLanguage],
          content: this.updatedContentBox.content[this.selectedLanguage],
        });
        this.isUnsavedChanges = false;

      });
    }

    this.subscriptions.push(
      this.productProvider.getAllProductLineNames$().subscribe((response) => {
        if (response) {
          this.allProductsLineNames = response;
        }
      })
    );
  }

  fetchContentBoxById() {
    this.subscriptions.push(
      this.contentBoxProvider.getContentBoxById(this.activatedRoute.snapshot.params.id).subscribe((contentBox) => {
        if (contentBox) {
          this.contentBox = { ...contentBox };
          this.updatedContentBox = JSON.parse(JSON.stringify(contentBox));
        }
      })
    );
  }

  onProductLineNameSelectionChange(event) {
    this.updatedContentBox.productlineId = event.value;
    this.isUnsavedChanges = true;
  }

  languageTabSelectionChanged(event) {
    this.selectedLanguage = this.languagesTabsData[event].value;
    this.populateFieldsFromContentBox();

    // focus on the content input (touched)
    this.contentBoxForm.controls.title.markAsTouched();
    this.contentBoxForm.controls.content.markAsTouched();
  }
  onTitleChange(event) {
    this.updatedContentBox.title[this.selectedLanguage] = event.target.value;
    this.isUnsavedChanges = true;

    // Test for empty or whitespaces
    for (const language of Object.keys(this.updatedContentBox.title)) {
      if (this.updatedContentBox.title[language].length === 0 || /^\s*$/.test(this.updatedContentBox.title[language])) {
        this.errorMessage = `Please fill in all required fields. (Title cannot be empty for ${language} language.)`;
      }
    }
  }
  onContentChange(event) {
    this.errorMessage = '';
    this.updatedContentBox.content[this.selectedLanguage] = event.target.value;
    this.isUnsavedChanges = true;

    // Test for empty or whitespaces
    for (const language of Object.keys(this.updatedContentBox.content)) {
      if (
        this.updatedContentBox.content[language].length === 0 ||
        /^\s*$/.test(this.updatedContentBox.content[language])
      ) {
        this.errorMessage = `Please fill in all required fields. (Content cannot be empty for ${language} language.)`;
      }
    }
  }

  populateFieldsFromContentBox(): void {
    if (this.updatedContentBox) {
      this.contentBoxForm.patchValue({
        title: this.updatedContentBox.title[this.selectedLanguage],
        content: this.updatedContentBox.content[this.selectedLanguage],
      });
    } else {
      this.contentBoxForm.patchValue({
        title: '',
        content: '',
      });
    }
  }

  isContentBoxInValid():boolean{
      return Object.values(this.updatedContentBox.title).some(value => !value) ||  Object.values(this.updatedContentBox.content).some(value => !value);
  }

  save(): void {
    if (this.contentBoxForm.valid && !this.isContentBoxInValid()) {
      // Perform save operation here
      if (this.activatedRoute.snapshot.params.id) {
        this.contentBoxProvider.updateContentBox(this.updatedContentBox);
      } else {
         this.contentBoxProvider.addContentBox(this.updatedContentBox);
      }
    } else {
      this.isError = true;
    }
  }

  cancel(): void {
    if (this.isUnsavedChanges) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: 'Unsaved Changes',
          message: 'Are you sure you want to proceed? Any unsaved content changes will be lost.',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.router.navigate(['/admin/contentboxes']); // Redirect to content boxes list
          }
        });
    } else {
      this.router.navigate(['/admin/contentboxes']); // Redirect to content boxes list
    }
  }

  togglePreview() {
    this.showPreview = !this.showPreview;
  }

  getHtml(): SafeHtml {
    return this.pipe.transform(marked.parse(this.updatedContentBox.content[this.selectedLanguage]), 'string');
  }

  getHtmlString(): string {
    return marked.parse(this.updatedContentBox.content[this.selectedLanguage]);
  }
}
