import { Component, Input, ElementRef, ViewChild, Renderer2, OnInit } from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsTextAreaComponent');

export interface IDGoodsTextAreaDelegate {
  onChangeTextArea(value: string, id: string, element: DGoodsTextAreaComponent): void;
}

@Component({
  selector: 'dgoods-textarea',
  templateUrl: './dgoods-textarea.component.html',
  styleUrls: ['./dgoods-textarea.component.scss']
})
export class DGoodsTextAreaComponent implements OnInit {

  @Input() delegate?: IDGoodsTextAreaDelegate;
  @Input() id?: string;
  @Input() caption?: string;
  @Input() captionHidden?: boolean;
  @Input() value? = '';
  @ViewChild('input', { static: true }) textArea: ElementRef;

  constructor(private render: Renderer2) { }

  ngOnInit() {
    this.captionHidden = true;
  }
  onFocus() {
    if (this.value) {
      this.captionHidden = false;
    } else {
      this.captionHidden = true;
    }
    debug('focus');
  }

  onKeyUp(value) {
    if (value) {
      this.captionHidden = false;
    } else {
      this.captionHidden = true;
    }
    debug('keyup');
    debug(value);
    this.autoGrow();
  }

  onBlur(value: string) {
    debug('blur ' + value);
    this.value = value;
    if (this.value) {
      this.captionHidden = false;
    } else {
      this.captionHidden = true;
    }
    if (this.delegate) {
      this.delegate.onChangeTextArea(this.value, this.id, this);
    } else {
      debug('onChange event not fired. There is no delegate attached to the component.');
    }
  }


  private autoGrow() {
    const textArea = this.textArea.nativeElement;
    let height = textArea.scrollHeight;
    debug(height);
    if (height < 89 ) {
      height = 89;
    }
    this.render.setStyle(this.textArea.nativeElement, 'overflow', 'hidden');
    this.render.setStyle(this.textArea.nativeElement, 'height', '89px');
    this.render.setStyle(this.textArea.nativeElement, 'height', height + 'px');
   }
}
