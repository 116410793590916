import { Injectable } from '@angular/core';
import * as fromActions from './../actions/admin-merchant.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IMerchant, IMerchantResponse } from '@modeso/types__dgoods-products';


const debug = Debug('modeso:dgoods-lib-admin-fe:AdminMerchantProvider');
@Injectable({
    providedIn: 'root'
})
export class AdminMerchantProvider {

    constructor(private store: Store<fromFeature.AppState>) { }

    // Merchants


    public getMerchantList(): Observable<IMerchantResponse[]> {
      this.store.dispatch(fromActions.getAllMerchants());
      return this.store.pipe(select(fromFeature.selectFeatureMerchantsList));
    }


    public createOrUpdateMerchant$(merchant: IMerchant) {
      this.store.dispatch(fromActions.createOrUpdateMerchant({ payload: merchant }));
    }

    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(select(fromFeature.selectFeatureMerchantCreationFailed),
            filter((error: HttpErrorResponse) => error != null)
        );
    }


}
