<div class="container" *ngIf="!hasReadPermission()">
  Sorry you don't have read permission.
</div>
<div class="container" *ngIf="hasReadPermission()">
    <div class="row">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource">
  
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell fxFlex="100px" *matHeaderCellDef>
            </th>
            <td mat-cell fxFlex="100px" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>
  
          <!-- Product -->
          <ng-container matColumnDef="Product">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element" class="mainIdentifier"> {{element.productLineName}} </td>
          </ng-container>
          <!-- de-ch -->
          <ng-container matColumnDef="de-ch">
            <th mat-header-cell *matHeaderCellDef> DE-CH </th>
            <td mat-cell *matCellDef="let element" [innerHtml]="getTwintDescription(element, 'de-ch')"> </td>
          </ng-container>
          <!-- en-us -->
          <ng-container matColumnDef="en-us">
            <th mat-header-cell *matHeaderCellDef> EN-US </th>
            <td mat-cell *matCellDef="let element" [innerHtml]="getTwintDescription(element, 'en-us')"></td>
          </ng-container>
          <!-- fr-ch -->
          <ng-container matColumnDef="fr-ch">
            <th mat-header-cell *matHeaderCellDef> FR-CH </th>
            <td mat-cell *matCellDef="let element" [innerHtml]="getTwintDescription(element, 'fr-ch')"></td>
          </ng-container>
          <!-- it-ch -->
          <ng-container matColumnDef="it-ch">
            <th mat-header-cell *matHeaderCellDef> IT-CH </th>
            <td mat-cell *matCellDef="let element" [innerHtml]="getTwintDescription(element, 'it-ch')"></td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="manageContentColumns;  sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: manageContentColumns;" (click)="selection.toggle(row)">
          </tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  
    <div class="row">
      <div class="messages">
        <span class="failure" *ngIf="isLocalizationsError">{{errorMessage}}</span>
        <span class="failure" *ngIf="isServerError">{{serverErrorMessage}}</span>
      </div>
  
      <div class="action-button" *ngIf="hasWritePermission()">
        <button id="updateLocalizationButtonDe" [disabled]="localizationButtonStatus()"
          (click)="onUpdateContent('de-ch')">Update German</button>
          <button id="updateLocalizationButtonEN" [disabled]="localizationButtonStatus()"
          (click)="onUpdateContent('en-us')">Update English</button>

      </div>
      <div class="action-button" *ngIf="hasWritePermission()">
        <button id="updateLocalizationButtonFr" [disabled]="localizationButtonStatus()"
        (click)="onUpdateContent('fr-ch')">Update French</button>
        <button id="updateLocalizationButtonIT" [disabled]="localizationButtonStatus()"
          (click)="onUpdateContent('it-ch')">Update Italian</button>


      </div>
  
    </div>
  
  </div>