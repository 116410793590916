<div class="continer">
    <h4>{{title}}</h4>
    <form [formGroup]="form.get(name)">
        <div class="column">
         <div>
            <div class="input-row">
                <mat-label>CODE</mat-label>
                <mat-form-field appearance="fill">
                    <input matInput formControlName="code">
                </mat-form-field>
            </div>
            <mat-error *ngIf="form.get(name).get('code').touched &&
            form.get(name).get('code').errors?.required ">code is Required</mat-error>
        </div>
            <div>
                <div class="input-row">
                    <mat-label>CARD</mat-label>
                    <mat-form-field appearance="fill" >
                        <input matInput formControlName="card">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('card').touched &&
                form.get(name).get('card').errors?.required ">card is Required</mat-error>
            </div>
            <div>
                <div class="input-row">
                    <mat-label>PIN</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="pin">
                    </mat-form-field>

                 </div>
                 <mat-error style="display: block;" *ngIf="form.get(name).get('pin').touched &&
                 form.get(name).get('pin').errors?.required ">pin is Required</mat-error>
            </div>
            <div>
                <div class="input-row">
                    <mat-label>EAN</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="ean">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('ean').touched &&
                form.get(name).get('ean').errors?.required ">ean is Required</mat-error>
            </div>

            <div>
                <div class="input-row">
                    <mat-label>PDF</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="pdf">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('pdf').touched &&
                form.get(name).get('pdf').errors?.required ">Pdf is Required</mat-error>
            </div>

            <div>
                <div class="input-row">
                    <mat-label>LINK</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="link">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('link').touched &&
                form.get(name).get('link').errors?.required ">link is Required</mat-error>
            </div>
            <div>
                <div class="input-row">
                    <mat-label>Open Link and Copy</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="copy">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('copy').touched &&
                form.get(name).get('copy').errors?.required ">Copy is Required</mat-error>
            </div>
            <div>
                <div class="input-row">
                    <mat-label>Copy Only</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="copyonly">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('copy').touched &&
                form.get(name).get('copy').errors?.required ">Copy is Required</mat-error>
            </div>
            <div>
                <div class="input-row">
                    <mat-label>Serial</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput  formControlName="serial">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('serial').touched &&
                form.get(name).get('serial').errors?.required ">serial is Required</mat-error>
            </div>
            <div>
                <div class="input-row">
                    <mat-label>REDEMPTION_INFORMATION</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput  formControlName="redemption_information"/>
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('redemption_information').touched &&
                form.get(name).get('redemption_information').errors?.required ">Redemption information is Required</mat-error>
            </div>
            <div>
                <div class="input-row">
                    <mat-label>AGB</mat-label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="agb"/>
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.get(name).get('agb').touched &&
                form.get(name).get('agb').errors?.required ">agb is Required</mat-error>
            </div>

            <div>
                <div class="input-row">
                    <mat-label>Extra Text</mat-label>
                    <mat-form-field appearance="fill">
                        <textarea matInput formControlName="extraText"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>

