import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslationsByKeyResponseDetails, IAdminUserModel } from '@modeso/dgoods-lib-admin-fe';
import Debug from 'debug';
import { UserRoles } from '../../../shared/enum/userrole.enum';
const debug = Debug('modeso:dgoods-admin:UpdateUserByIdDialog');


@Component({
    selector: 'app-update-user-by-id-dialog',
    templateUrl: 'updateUserByIdDialog.html',
    styleUrls: ['./updateUserByIdDialog.scss'],
})
export class UpdateUserByIdDialog {
    updateByKeyForm: FormGroup;
    originalUser: IAdminUserModel;

    roles = [UserRoles.DIGITAL_VAUCHERS_ADMIN,
        UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER];
    constructor(
        public dialogRef: MatDialogRef<UpdateUserByIdDialog>,
        @Inject(MAT_DIALOG_DATA) public data: IAdminUserModel) {
        this.originalUser = data;
        this.updateByKeyForm = new FormGroup({

            name: new FormControl({
                value: data.name,
                disabled: true
            }, { validators: [Validators.required] }),
            role: new FormControl(data.role, { validators: [Validators.required] }),
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        if (this.updateByKeyForm.invalid) {
            return;
        } else {
            const user: IAdminUserModel = {
                name: this.data.name,
                role: this.updateByKeyForm.value['role'],
                id: this.originalUser.id,
            };
            debug(user);
            this.dialogRef.close(user);

        }
    }

    buttonStatus() {
        return this.updateByKeyForm.invalid;
    }

}
