<h2>Digital Voucher Configuration</h2>
<h3>{{productLineName}}</h3>
<div *ngIf="isNotFound" id="info">
    This is a new configuration, default values loaded. Not saved!
  </div>
<form (ngSubmit)="submit()" *ngIf="digitalVoucherFrom" [formGroup]="digitalVoucherFrom">
    <div class="grid-container">

        <div id="value-area">
            <h4>Values</h4>
            <div class="column" [formGroup]="config">
                <app-drop-down-list-configurator [form]="digitalVoucherFrom"
                title="CODE" [iterators]="iterators" name="code"></app-drop-down-list-configurator>
                <app-drop-down-list-configurator [form]="digitalVoucherFrom"
                title="CARD" [iterators]="iterators" name="card"></app-drop-down-list-configurator>
                <app-drop-down-list-configurator [form]="digitalVoucherFrom"
                title="PIN" [iterators]="iterators" name="pin"></app-drop-down-list-configurator>
                <app-drop-down-list-configurator [form]="digitalVoucherFrom"
                title="SERIAL" [iterators]="iterators" name="serial"></app-drop-down-list-configurator>
                <app-drop-down-list-configurator [form]="digitalVoucherFrom"
                title="EAN" [iterators]="iterators" name="ean"></app-drop-down-list-configurator>
                <app-drop-down-list-configurator *ngIf="this.merchantType.toUpperCase() === 'OFFERZ' " [form]="digitalVoucherFrom"
                title="PDF" [iterators]="pdfIterators" name="pdf"></app-drop-down-list-configurator>
                <app-drop-down-list-configurator [form]="digitalVoucherFrom"
                title="REDEMPTION_INFORMATION" [iterators]="iterators" name="redemption_information"></app-drop-down-list-configurator>
                <app-drop-down-list-configurator [form]="digitalVoucherFrom"
                title="AGB" [iterators]="iterators" name="agb"></app-drop-down-list-configurator>
            </div>
        </div>
        <div id="de-ch-area">
            <app-text-form [form]="text" title="DE-CH" name="de-ch"></app-text-form>
        </div>
        <div id="it-ch-area">
                <app-text-form [form]="text" title="IT-CH" name="it-ch"></app-text-form>
        </div>
        <div id="features-area" [formGroup]="digitalVoucherFrom.get('config')">
            <h4>Features</h4>

            <app-drop-down-list-configurator [form]="digitalVoucherFrom"
            title="DISPLAYLINK" [iterators]="DisplayLinkValues" name="displayLink"></app-drop-down-list-configurator>

            <app-drop-down-list-configurator [form]="digitalVoucherFrom"
            title="COPY" [iterators]="iterators" name="copy"></app-drop-down-list-configurator>

            <div class="input-row">
                <mat-label>LINK</mat-label>
                <mat-form-field >
                    <input matInput formControlName="link">
                </mat-form-field>
            </div>

            <app-drop-down-list-configurator [form]="digitalVoucherFrom"
            title="VARIABLE 1" [iterators]="iterators" name="variable"></app-drop-down-list-configurator>

            <app-drop-down-list-configurator [form]="digitalVoucherFrom"
            title="VARIABLE 2" [iterators]="iterators" name="variable2"></app-drop-down-list-configurator>

            <app-drop-down-list-configurator [form]="digitalVoucherFrom"
            title="VARIABLE 3" [iterators]="iterators" name="variable3"></app-drop-down-list-configurator>

            <app-drop-down-list-configurator [form]="digitalVoucherFrom"
            title="EAN TYPE" [iterators]="codeType" name="eanType"></app-drop-down-list-configurator>

        </div>
        <div id="en-us-area">
            <app-text-form [form]="text" title="EN-US" name="en-us"></app-text-form>
        </div>
        <div id="fr-ch-area">
            <app-text-form [form]="text" title="FR-CH" name="fr-ch"></app-text-form>
        </div>
    </div>
    <button type="submit" class="btn save-btn m-3" [disabled]="digitalVoucherFrom.invalid">Submit</button>
</form>
