import { Component, ViewChild, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import Debug from 'debug';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TwintCouponsProvider } from '../../providers/twint.coupons.provider';
import { Observable, take , Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CouponDTO, CouponFilterDTO, CouponStateEnum } from '@modeso/types__twint-lib-coupons';
import { ConfirmDeletionDialog } from '../../dialog/confirmDeletionDialog/confirmDeletion';
import { ConfirmationDialog } from '../../dialog/confirmationDialog/confirmationDialog';
import { CouponErrorDialog } from '@modeso/twint-lib-couponsmng-fe';
import { Sort } from '@angular/material/sort';
import { ControlState } from '../../models/enums/control-state.enum';

const debug = Debug('modeso:twint-admin:ListCouponsComponent');
export interface CouponDTOExtended extends CouponDTO {
  availableCodes?: number;
}


@Component({
  selector: 'app-list-coupons',
  templateUrl: './list-coupons.component.html',
  styleUrls: ['./list-coupons.component.scss'],
})
export class ListCouponsComponent implements OnInit {
  dataSource = new MatTableDataSource<CouponDTO>();
  displayedColumns: string[] = ['name', 'validFrom', 'validTo', 'numberOfCreatedCodes', 'maxUsePerUser' , 'activationState','controlState', 'buttons'];
  couponManagement$: Observable<any>;
  getErrors: Subscription;
  couponsDataSource: CouponDTO[] = [];
  @Input() coupons: CouponDTO[];
  @Input() hasReadPermission;
  @Input() hasWritePermission;
  @Input() archivedState;
  @Output() archivedStateChanged: EventEmitter<any> = new EventEmitter();
  @Output() private sortField: EventEmitter<CouponFilterDTO> = new EventEmitter()
  @ViewChild(MatPaginator) private paginator: MatPaginator | undefined;


  constructor(private router: Router,
              private couponProvider: TwintCouponsProvider,
              public dialog: MatDialog) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    this.getErrors = this.couponProvider.getCouponError$().subscribe((httpError) => {
      debug("errors...")
      debug(httpError)
      if (httpError) {
        const errorMessage = httpError?.error?.message || "Internal Server error";
        this.dialog.open(CouponErrorDialog, {
          data: {
            error: errorMessage
          },
        });
      }
    });
  }

  ngAfterViewInit(){
    this.initializeTheCouponsToDataSource();
  }

  deleteCoupon(coupon: CouponDTO) {
    const dialogRef = this.dialog.open(ConfirmDeletionDialog, {
      data: {
          message: `Are you sure you want to delete ${coupon.name}?`
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.couponProvider.deleteCoupon(coupon.id);
      }
    });
  }


  editCoupon(couponId:string){
    this.router.navigate([`/de-ch/add-coupon`, { couponId } ]);
  }

  archiveCoupon(couponId,archiveState) : void{
      archiveState = !archiveState;
      this.archivedStateChanged.emit(archiveState);
      this.couponProvider.archiveCoupon({couponId , archiveState });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeTheCouponsToDataSource();
  }

  openConfirmationDialog(type: number, text: string , callback: Function) {
    // TYPE 1 ==> (Yes - Cancel) dialog
    // TYPE 2 ==> (Ok) dialog

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: { type, text },
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
      if (result) {
        callback();
      }
    });
  }

  changeCouponState(coupon: CouponDTO) : void{
    switch (coupon.state) {
      case CouponStateEnum.Active:
        this.couponProvider.deactivateCoupon(coupon.id);
        break;

      case CouponStateEnum.Inactive:
        const dialogText = 'The coupon is about to be published. Do you want to publish the coupon?';
        this.openConfirmationDialog(1, dialogText, ()=>{
          this.couponProvider.activateCoupon(coupon.id);
        });
        break;
    }
  }

  private initializeTheCouponsToDataSource(): void {

    this.couponsDataSource = this.coupons.filter((coupon) =>  this.archivedState ? coupon.archived : !coupon.archived );

    // to re-assign pageIndex with every tab change
    if (this.paginator != null) {
      this.paginator.pageIndex = 0;
    }
    this.dataSource = new MatTableDataSource<CouponDTO>(this.couponsDataSource);
    this.dataSource.paginator = this.paginator;  
    
  }


  ngOnDestroy(){
    if(this.getErrors){
      this.getErrors.unsubscribe();
      this.couponProvider.flushError();
    }
  }

  onSort(sortState: Sort): void {
    const searchDto: CouponFilterDTO = {
      // to handle the default state where sortState.direction is empty
      sortField: sortState.direction ? sortState.active:  undefined,
      sortDirection: sortState.direction
    }
    this.sortField.emit(searchDto);

  }
  
  public get couponActivationState(): typeof CouponStateEnum {
    return CouponStateEnum;
  }

  public get controlState(): typeof ControlState {
    return ControlState;
  }
}
