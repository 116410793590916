import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home/home.page';
import { FraudLimitsPage } from './pages/fraudLimits/fraudLimits.page';
import { LoginPage } from './pages/login/login.page';
import { AuthGuard } from './shared/auth.guard';
import { ChangePasswordPage } from './pages/changePassword/changePassword.page';
import { SignupPage } from './pages/signup/signup.page';
import { BlockedProductsPage } from './pages/blockedProducts/blockedProducts.page';
import { ReportsPage } from './pages/reports/reports.page';
import { ManageContentPage } from './pages/manageContent/manageContent.page';
import { WhiteListingPage } from './pages/whiteListing/whiteListing.page';
import { ProductManagmentPage } from './pages/productManagment/productManagment.page';
import { ContentManagementPage } from './pages/contentManagement/contentManagement.page';
import { ProductContentManagmentPage } from './pages/productContentManagement/productContentManagment.page';
import { UserManagementPage } from './pages/userManagement/userManagement.page';
import { ManageUsersPage } from './pages/manageUsers/manageUsers.page';
import { ClosedUserGroupPage } from './pages/closedUserGroup/closedUserGroup.page';
import { NoAuthGuard } from './shared/noauth.guard';
import { ProductSettingsPage } from './pages/productSettings/productSettings.page';
import { AuditLogsPage } from './pages/auditLogs/auditLogs.page';
import { SamlAssertPage } from './pages/samlAssert/samlAssert.page';
import { CreateMerchantPage } from './pages/merchantManagement/createMerchant/create-merchant.page';
import { MerchantListPage } from './pages/merchantManagement/merchant-list/merchant-list.page';
import { DigitalVoucherConfiguratorComponent } from './pages/digital-voucher-configurator/digital-voucher-configurator.component';
import { CouponsPage } from './pages/couponManagement/coupons.page';
import { AddCouponPage } from './pages/couponManagement/addCoupon/add-coupon.page';
import { NewstickerListPage } from './pages/newstickerManagment/newsticker-list/newsticker-list.page';
import { CreateNewstickerPage } from './pages/newstickerManagment/create-newsticker/create-newsticker.page';
import { ProductCategoriesPage } from './pages/productCategories/productCategories.page';
import { ContentPageListComponent } from './pages/contentPagesManagment/content-pages-list/content-pages-list.page';
import { CreateContentPageComponent } from './pages/contentPagesManagment/create-content-page/create-content-page.page';
import { CreateSpotLightComponent } from './pages/spotlightManagement/create-spotlight/create-spot-light.component';
import { ListSpotLightsComponent } from './pages/spotlightManagement/list-spotlights/list-spotlights.component';
import { ContentBoxListComponent } from './pages/contentBoxesManagment/content-boxes-list/content-boxes-list.page';
import { CreateContentBoxComponent } from './pages/contentBoxesManagment/create-content-box/create-content-box.page';

const routes: Routes = [
  {
    path: ':language',
    component: HomePage,
    canActivate: [AuthGuard],
  },

  {
    path: '',
    redirectTo: '/de-ch/login',
    pathMatch: 'full',
  },
  {
    path: ':language/fraudLimits',
    component: FraudLimitsPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/manageContent',
    component: ManageContentPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/login',
    component: LoginPage,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'saml/assert',
    component: SamlAssertPage,
    canActivate: [NoAuthGuard],
  },
  {
    path: ':language/cms',
    component: ContentManagementPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'text-and-labels',
        component: ManageContentPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'product-content',
        component: ProductContentManagmentPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'faq',
        component: ManageContentPage,
        data: { isFAQ: true },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: ':language/changePassword',
    component: ChangePasswordPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/signup',
    component: SignupPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/product-managment',
    component: ProductManagmentPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'blocked-products',
        component: BlockedProductsPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'whiteListing-products',
        component: WhiteListingPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'product-tc-enforcement',
        component: ProductSettingsPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'product-categories',
        component: ProductCategoriesPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'closed-user-group',
        component: ClosedUserGroupPage,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: ':language/merchant-list',
    component: MerchantListPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/create-merchant',
    component: CreateMerchantPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/create-merchant/:id',
    component: CreateMerchantPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/user-management',
    component: UserManagementPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin-users',
        component: ManageUsersPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'add-admin-user',
        component: SignupPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'twint-users',
        component: WhiteListingPage,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: ':language/create-newstickers',
    component: CreateNewstickerPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/create-newstickers/:id',
    component: CreateNewstickerPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/newstickers',
    component: NewstickerListPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/contentpages',
    component: ContentPageListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/create-contentpages',
    component: CreateContentPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/create-contentpages/:id',
    component: CreateContentPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/contentboxes',
    component: ContentBoxListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/create-contentboxes',
    component: CreateContentBoxComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/create-contentboxes/:id',
    component: CreateContentBoxComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/reports',
    component: ReportsPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/auditLogs',
    component: AuditLogsPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/product-managment/digitalvoucher/:productLineName/:merchant',
    component: DigitalVoucherConfiguratorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/coupons-management',
    component: CouponsPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/add-coupon',
    component: AddCouponPage,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/spot-lights',
    component: ListSpotLightsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/spot-light/:id',
    component: CreateSpotLightComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':language/spot-light',
    component: CreateSpotLightComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: HomePage,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
