import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminConfigService } from '../admin.config';
import { AddOrRemoveWhitelistedUserResponseModel } from '../models/whiteListUsers/addOrRemoveWhitelistedUser.response.model';
import { UserSearchRequestModel, UserSearchResponseModel } from '../models/whiteListUsers/userSearch.model';
import { WhitelistedUsersResponseModel } from '../models/whiteListUsers/whitelistedUserResponse.model';

@Injectable({
    providedIn: 'root',
})
export class AdminWhiteListingUsersService {

    private server = '';
    private readonly analyticsMicroServiceName = 'analytics';
    private readonly productMicroServiceName = 'products';
    private readonly usersRoute = '/users';
    private readonly whiteListRoute = '/whitelist';
    private readonly searchRoute = '/search';
    private readonly addOrDelete = '/customer';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;
    }

    public getWhitelisteUsers() {
        const url = `${this.server}${this.analyticsMicroServiceName}${this.usersRoute}${this.whiteListRoute}`;
        return this.http.get<WhitelistedUsersResponseModel>(url);
    }

    public searchUser(searchData: UserSearchRequestModel) {
        const url = `${this.server}${this.analyticsMicroServiceName}${this.usersRoute}${this.searchRoute}`;
        return this.http.post<UserSearchResponseModel>(url, searchData);
    }

    public addUserToWhitelist(customerUuid: string) {
        const url = `${this.server}${this.productMicroServiceName}${this.whiteListRoute}${this.addOrDelete}/${customerUuid}`;
        return this.http.post<AddOrRemoveWhitelistedUserResponseModel>(url, {});
    }

    public removeUserFromWhitelist(customerUuid: string) {
        const url = `${this.server}${this.productMicroServiceName}${this.whiteListRoute}${this.addOrDelete}/${customerUuid}`;
        return this.http.delete<AddOrRemoveWhitelistedUserResponseModel>(url);
    }

}
