import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dgoods-title-component',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class DGoodsTitleComponent {

    @Input() title: string;

}
