<div *ngIf="initializationState === false" class="spinner-container">
  <mat-spinner value=25 diameter="40"></mat-spinner>
</div>
<div class="coupon-container" *ngIf="initializationState === true">
  <div class="roboto-text page-title">

    <div class="module-activation-indicator">
      <div class="fields-container">
        <div class="coupon-feature-container">
          <span class="text">Coupons Feature</span>
          <label class="label" >
            <span *ngIf="couponsFeature else enableSpan" class="coupon-feature-span-on">ON</span>
            <ng-template #enableSpan>
              <span class="coupon-feature-span-off">OFF</span>
            </ng-template>
          </label>
        </div>
       
  
        <div class="search-container" [formGroup]="searchForm">
          <div class="fields-alignment">
            <div class="mr">
              <span class="field-name">Coupon Name</span>
              <mat-form-field appearance="outline"  >
                <input formControlName="couponName"  matInput />
              </mat-form-field>
            </div>
  
            <div class="mr">
              <span class="field-name">Coupon Code</span>
              <mat-form-field appearance="outline">
                <input formControlName="couponCode"  matInput />
              </mat-form-field>
            </div>
            
            <div>
              <button (click)="searchCoupons()" class="search-btn" >Search</button>
            </div>

            <div>
              <button (click)="clearSearchResults()" class="search-btn" >Clear search results</button>
            </div>
            
          </div>
          
          <div>
            <mat-error *ngIf="searchErrors" class="error-msg">
              Please search by either coupon code or coupon name, not both.
            </mat-error>
          </div>
         
          
        </div>
      
      </div>
     
      <div class="buttons">
        <ng-container *ngIf="hasWritePermission">
          <button *ngIf="couponsFeature else enable" (click)="toggleCouponsFeature()" class="disable-button" mat-button>
            <span class="roboto-text coupon-text-white">DISABLE COUPON FEATURE</span>
          </button>

          <ng-template #enable>
            <button (click)="toggleCouponsFeature()" class="enable-button" mat-button>
              <span class="roboto-text coupon-text">ENABLE COUPON FEATURE</span>
            </button>
          </ng-template>

          <button id="addCoupon" class="green-button" (click)="addCoupon()">
            <span class="roboto-text coupon-text">CREATE NEW COUPON</span>
          </button>

          <div>
            <button class="cost-file-button" (click)="exportCostFile(exportCostType.FULL)">
              <span class="roboto-text coupon-text-white">EXPORT COST FILE</span>
            </button>

            <button class="summary-cost-file" (click)="exportCostFile(exportCostType.SUMMARY)">
              <span class="roboto-text coupon-text-white">EXPORT SUMMARY COST FILE</span>
            </button>
          </div>
        


          

        </ng-container>
      </div>
    </div>

  

  </div>

 


  <div *ngIf="hasReadPermission">
    <nav mat-tab-nav-bar>
      <ng-container *ngFor="let link of navLinks" >
        <a mat-tab-link (click)="changeNavigation(link.archiveState)" [routerLink]="[link.link]" [ngClass]="{'tab-selected': activeLink===link.link}">
          <span>{{link.label}}</span>
        </a>
      </ng-container>
    </nav>

   
    <app-list-coupons 
    (archivedStateChanged)="changeNavigation($event)"
    (sortField)="changeSortColumnAndDirection($event)"
    [archivedState]="archiveState"
    [coupons]="coupons$ | async"
    [hasReadPermission]="hasReadPermission"
    [hasWritePermission]="hasWritePermission"
    ></app-list-coupons>
  </div>
</div>
