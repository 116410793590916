import { Action, createAction, props } from '@ngrx/store';
import { AddOrRemoveWhitelistedUserResponseModel } from '../models/whiteListUsers/addOrRemoveWhitelistedUser.response.model';
import { UserSearchRequestModel, UserSearchResponseModel } from '../models/whiteListUsers/userSearch.model';
import { WhitelistedUsersResponseModel } from '../models/whiteListUsers/whitelistedUserResponse.model';

export const getWhitelistedUsers = createAction(
    '[Admin] getWhitelistedUsers',
);
export const onGetWhitelistedUsersSuccessfully = createAction(
    '[Admin] onGetWhitelistedUsersSuccessfully',
    props<{ payload: WhitelistedUsersResponseModel }>(),
);
export const onGetWhitelistedUsersFailed = createAction(
    '[Admin] onGetWhitelistedUsersFailed',
    props<{ payload: any }>(),
);

export const searchUser = createAction(
    '[Admin] searchUser',
    props<{ payload: UserSearchRequestModel }>(),
);
export const onSearchUserSuccessfully = createAction(
    '[Admin] onSearchUserSuccessfully',
    props<{ payload: UserSearchResponseModel }>(),
);
export const onSearchUserFailed = createAction(
    '[Admin] onSearchUserFailed',
    props<{ payload: any }>(),
);

export const addUserToWhitelist = createAction(
    '[Admin] addUserToWhitelist',
    props<{ payload: string }>(),
);
export const onAddUserToWhitelistSuccessfully = createAction(
    '[Admin] onAddUserToWhitelistSuccessfully',
    props<{ payload: AddOrRemoveWhitelistedUserResponseModel }>(),
);
export const onAddUserToWhitelistFailed = createAction(
    '[Admin] onAddUserToWhitelistFailed',
    props<{ payload: any }>(),
);

export const removeUserFromWhitelist = createAction(
    '[Admin] removeUserFromWhitelist',
    props<{ payload: string }>(),
);
export const onRemoveUserFromWhitelistSuccessfully = createAction(
    '[Admin] onRemoveUserFromWhitelistSuccessfully',
    props<{ payload: AddOrRemoveWhitelistedUserResponseModel }>(),
);
export const onRemoveUserFromWhitelistFailed = createAction(
    '[Admin] onRemoveUserFromWhitelistFailed',
    props<{ payload: any }>(),
);

export const cleanAddOrDeleteToUserWhitelistResponse = createAction(
    '[Admin] cleanAddOrDeleteToUserWhitelistResponse',
);
export interface ActionWithPayload<T> extends Action {
    payload: T;
}
