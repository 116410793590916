export class Views {
    public summary: SummaryDetails;
    public pages: PageDetails[];
}

export class SummaryDetails {
    public totalVisits: number;
    public nrOfUniqueUsers: number;
    public avgPerUser: number;
}

export class PageDetails {
    public _id: string;
    public totalVisits: number;
    public uniqueUsers: number;
    public avgPerUser: number;
}
