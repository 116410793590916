import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { IssuerLimitsOfAddOrUpdateRequest } from '@modeso/dgoods-lib-admin-fe';

@Component({
    selector: 'app-add-or-update-issuer-dialog',
    templateUrl: 'addOrUpdateIssuerDialog.html',
    styleUrls: ['./addOrUpdateIssuerDialog.scss'],
})
export class AddOrUpdateIssuerDialog {
    addOrUpdateIssuerForm: FormGroup;
    timeFrames = ['DAY', 'WEEK', 'MONTH', 'YEAR'];

    selectedTimeFrame: string;
    constructor(
        public dialogRef: MatDialogRef<AddOrUpdateIssuerDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        const limitValidator = new Array<any>();
        limitValidator.push(Validators.required);
        limitValidator.push(Validators.max(99999999));

        const timeFrameValueValidators = new Array<any>();
        timeFrameValueValidators.push(Validators.required);
        timeFrameValueValidators.push(Validators.max(1000));


        const issuerNameValidators = new Array<any>();
        issuerNameValidators.push(Validators.required);
        issuerNameValidators.push(Validators.minLength(5));
        issuerNameValidators.push(Validators.maxLength(40));

        this.addOrUpdateIssuerForm = new FormGroup({

            name: new FormControl({
                value: data.issuer.name ? data.issuer.name : null,
                disabled: data.updateIssuer
            }, { validators: issuerNameValidators }),
            value: new FormControl(data.issuer.value ? data.issuer.value : null, { validators: limitValidator }),
            timeframeValue: new FormControl(data.issuer.timeframeValue ? data.issuer.timeframeValue : null,
                { validators: timeFrameValueValidators }),
            timeframeName: new FormControl(
                this.timeFrames.includes(this.data.issuer.timeframeName) ? this.data.issuer.timeframeName : '',
                { validators: [Validators.required] })
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        if (this.addOrUpdateIssuerForm.invalid) {
            return;
        } else {

            const issuer: IssuerLimitsOfAddOrUpdateRequest = {
                name: this.addOrUpdateIssuerForm.get('name').value,
                value: +this.addOrUpdateIssuerForm.value.value,
                timeframeName: this.addOrUpdateIssuerForm.get('timeframeName').value,
                timeframeValue: +this.addOrUpdateIssuerForm.value.timeframeValue
            };

            this.dialogRef.close(issuer);

        }
    }

    buttonStatus() {
        return this.addOrUpdateIssuerForm.invalid /*|| this.timeframeName.invalid*/;
    }

    getSelectedTimeFilter(event: MatSelectChange) {
        this.selectedTimeFrame = event.value;
    }

}
