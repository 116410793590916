import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AdminContentPageService } from '../services/admin-content-page.service';
import * as fromActions from '../actions/admin-content-page.action';

@Injectable()
export class AdminContentPageEffect {
  constructor(
    private actions$: Actions,
    private service: AdminContentPageService,
    private router: Router
  ) {}

  getAllContentPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllContentPages),
      mergeMap(() =>
        this.service.getAllContentPages().pipe(
          map((response) =>
            fromActions.getAllContentPagesSuccess({ payload: response })
          ),
          catchError((error) =>
            of(fromActions.getAllContentPagesFailure({ payload: error }))
          )
        )
      )
    )
  );

  addContentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addContentPage),
      mergeMap((action) =>
        this.service.addContentPage(action.payload).pipe(
          map((response) =>
            fromActions.addContentPageSuccess({ payload: response })
          ),
          catchError((error) =>
            of(fromActions.addContentPageFailure({ payload: error }))
          )
        )
      )
    )
  );

  addContentPageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addContentPageSuccess),
        tap(() => {
          this.router.navigate(['/content-pages']);
        })
      ),
    { dispatch: false }
  );

  updateContentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateContentPage),
      mergeMap((action) =>
        this.service.updateContentPage(action.payload).pipe(
          map((response) =>
            fromActions.updateContentPageSuccess({ payload: response })
          ),
          catchError((error) =>
            of(fromActions.updateContentPageFailure({ payload: error }))
          )
        )
      )
    )
  );

  updateContentPageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.updateContentPageSuccess),
        // tap(() => {
        //   this.router.navigate(['/content-pages']);
        // })
      ),
    { dispatch: false }
  );

  deleteContentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteContentPage),
      mergeMap((action) =>
        this.service.deleteContentPage(action.payload).pipe(
          map(() => fromActions.deleteContentPageSuccess({ payload: action.payload })),
          catchError((error) =>
            of(fromActions.deleteContentPageFailure({ payload: error }))
          )
        )
      )
    )
  );

  deleteContentPageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deleteContentPageSuccess),
        tap(() => {
          this.router.navigate(['/content-pages']);
        })
      ),
    { dispatch: false }
  );
}
