import { Injectable } from '@angular/core';
import * as fromActions from '../actions/admin-content-page.action';
import * as fromFeature from '../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { IContentPage, IContentPageResponse } from '../models/content.pages';

@Injectable({
  providedIn: 'root',
})
export class AdminContentPageProvider {
  constructor(private store: Store<fromFeature.AppState>) {}

  getAllContentPages(): Observable<IContentPageResponse[]> {
    this.store.dispatch(fromActions.getAllContentPages());
    return this.store.pipe(select(fromFeature.selectFeatureContentPagesList));
  }

  addContentPage(contentPage: IContentPage): void {
    this.store.dispatch(fromActions.addContentPage({ payload: contentPage }));
  }

  updateContentPage(contentPage: IContentPageResponse): void {
    this.store.dispatch(fromActions.updateContentPage({ payload: contentPage }));
  }

  deleteContentPage(contentPageId: string): void {
    this.store.dispatch(fromActions.deleteContentPage({ payload: contentPageId }));
  }

  getError$(): Observable<any> {
    return this.store.pipe(
      select(fromFeature.featureError),
      map((error: any) => {
        return error;
      })
    );
  }
}
