import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { marked } from 'marked';
import Debug from 'debug';
import { MARKED_CONFIG, MODESO_DEFAULT_MARKED_CONFIG, SafeHtmlPipe } from '@modeso/twint-lib-core-fe';
import { SafeHtml } from '@angular/platform-browser';

export interface IMarkdownEditorDelegate {
  onSaveButtonClick(markdown: string, sender: MarkdownEditorComponent): void;
  save(): void;
}
const debug = Debug('modeso:dgoods-admin:MarkdownEditorComponent');

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
})
export class MarkdownEditorComponent implements OnInit {
  showPreview: boolean = false;
  _markdown: string = '';
  get markdown(): string {
    return this._markdown;
  }
  @Input() set markdown(value: string) {
    this._markdown = value;
    this._formControl.setValue(value);
  }
  @Input() delegate?: IMarkdownEditorDelegate | undefined;

  @Input() validators?: [];
  @Input() errorMessage?: '';

  _formControl: FormControl = new FormControl('');
  get control(): FormControl {
    return this._formControl;
  }
  @Input() set control(value: FormControl) {
    this._formControl = value;
  }

  protected config: marked.MarkedOptions;

  constructor(private pipe: SafeHtmlPipe, @Optional() @Inject(MARKED_CONFIG) config: marked.MarkedOptions) {
    if (config) {
      this.config = config;
    } else {
      this.config = MODESO_DEFAULT_MARKED_CONFIG;
    }
    marked.setOptions(this.config);
  }

  ngOnInit(): void {
    this._markdown = this._formControl.value;
    this._formControl.setValidators(this.validators);
    this._formControl.valueChanges.subscribe((value) => {
      this._markdown = value;
    });
  }

  getHtml(): SafeHtml {
    return this.pipe.transform(marked.parse(this._markdown), 'string');
  }

  getHtmlString(): string {
    return marked.parse(this._markdown);
  }

  submitForm() {
    this.markdown = this._formControl.value;
    if (this.delegate) {
      this.delegate.onSaveButtonClick(this._markdown, this);
    }
  }
  cancelForm() {
    // revert to original value
    this.delegate.save();
  }

  togglePreview() {
    this.showPreview = !this.showPreview;
  }
}
