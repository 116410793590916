import { marked } from 'marked';
import { InjectionToken } from '@angular/core';

export const MARKED_CONFIG = new InjectionToken<marked.MarkedOptions>('marked.config');

export const MODESO_DEFAULT_MARKED_CONFIG: marked.MarkedOptions = {
  renderer: new marked.Renderer(),
  pedantic: false,
  gfm: true,
  breaks: true,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: false,
};
