import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from '../admin.config';
import { ISortingOrder  } from '@modeso/types__dgoods-products';

import Debug from 'debug';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminSortingOrderService');

@Injectable({
    providedIn: 'root',
})

export class AdminSortingOrderService {
    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly productsRoute = '/';
    private readonly sortingOrder = 'sortingOrder';


    constructor(
        private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;
    }

    public createOrUpdateSortingOrder(sortingOrder: ISortingOrder) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.sortingOrder}`;
        return this.http.post<ISortingOrder>(url, sortingOrder);
      }
 
}




