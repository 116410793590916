import { ProductsWithAvailablePricesDto } from '@modeso/types__dgoods-products';
import { Action, createAction, props } from '@ngrx/store';
import { WhiteListingResponseModel } from '../models/whiteListingProducts/whiteListingResponse.model';

export const getAllProductsInAllLanguages = createAction(
    '[Admin WhiteListing Products] get all products in all languages',
);
export const getAllProductsInAllLanguagesSuccessfully = createAction(
    '[Admin WhiteListing Products] get all products in all languages successfully',
    props<{ payload: ProductsWithAvailablePricesDto[] }>(),
);
export const getAllProductsInAllLanguagesFailed = createAction(
    '[Admin WhiteListing Products] get all products in all languages failed',
    props<{ payload: any }>(),
);
export const whiteListProduct = createAction(
    '[Admin WhiteListing Products] whiteList product ',
    props<{ payload: string }>(),
);
export const whiteListProductSuccessfully = createAction(
    '[Admin WhiteListing Products] whiteList product successfully',
    props<{ payload: WhiteListingResponseModel }>(),
);
export const whiteListProductFailed = createAction(
    '[Admin WhiteListing Products] whiteList product failed',
    props<{ payload: any }>(),
);
export const unWhiteListProduct = createAction(
    '[Admin WhiteListing Products] unwhiteList product ',
    props<{ payload: string }>(),
);
export const unwhiteListProductSuccessfully = createAction(
    '[Admin WhiteListing Products] unwhiteList product successfully',
    props<{ payload: WhiteListingResponseModel }>(),
);
export const unwhiteListProductFailed = createAction(
    '[Admin WhiteListing Products] unwhiteList product failed',
    props<{ payload: any }>(),
);
export const synchronizeProducts = createAction(
    '[Admin WhiteListing Products] synchronize products',
);
export const synchronizeProductsSuccessfully = createAction(
    '[Admin WhiteListing Products] synchronize products successfully',
    props<{ payload: string }>(),
);
export const synchronizeProductsFailed = createAction(
    '[Admin WhiteListing Products] synchronize products failed',
    props<{ payload: any }>(),
);

export const getAllProductLineNames = createAction(
    '[Admin WhiteListing Products] get all product line names',
);

export const getAllProductLineNamesSuccessfully = createAction(
    '[Admin WhiteListing Products] get all product line names successfully',
    props<{ payload: string[] }>(),
);

export const getAllProductLineNamesFailed = createAction(
    '[Admin WhiteListing Products] get all product line names failed',
    props<{ payload: any }>(),
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}
