<h2 mat-dialog-title> {{ product.productLineName }} </h2>
<mat-dialog-content>

  <div class="settings-container">
    <mat-slide-toggle color="warn" *ngFor="let setting of editableSettings" [(ngModel)]="updatedProduct[setting]">
      {{ setting }}
    </mat-slide-toggle>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-container">
  <button mat-raised-button	 mat-dialog-close color="secondary">Cancel</button>
  <button mat-raised-button	 color="primary" (click)="onYesClick()">Save</button>
</mat-dialog-actions>
