<div class="dgoods-tutorial-overlay-container" *ngIf="!hidden">
  <div *ngFor="let element of tutorialData">
    <dgoods-tutorial-element [tutorial]="element">
    </dgoods-tutorial-element>
  </div>
  <div class="button-container">
    <div class="sticky-dark">
    <button
      (click)="onOverlayButtonclick()">{{ "dgoods_shared_tutorialoverlaycompontent_roger" | injectedText | async }}</button>
    </div>
    </div>
</div>
