import { createAction, props, Action } from '@ngrx/store';
import { FraudLimitsResponse } from '../models/fraudLimits/fraudLimitsResponse.model';
import { DefaultFraudLimitsRequest } from '../models/fraudLimits/defaultFraudLimits.model';
import {
    IssuerLimitsOfAddOrUpdateRequest, IssuerLimitsResponse,
    IssuerLimitsOfDeleteRequest
} from '../models/fraudLimits/issuerLimits.model';

export const getFraudLimits = createAction('[Admin FraudLimits] getFraudLimits');

export const onGetFraudLimitsSuccessfully = createAction(
    '[Admin FraudLimits] getFraudLimits successfully',
    props<{ payload: FraudLimitsResponse[] }>()
);

export const onGetFraudLimitsFailed = createAction(
    '[Admin FraudLimits] getFraudLimits failed',
    props<{ payload: any }>()
);

export const updateDefaultFraudLimit = createAction(
    '[Admin FraudLimits] updateDefaultFraudLimit',
    props<{ payload: DefaultFraudLimitsRequest }>()
);

export const onUpdateDefaultFraudLimitSuccessfully = createAction(
    '[Admin FraudLimits] updateDefaultFraudLimit successfully',
    props<{ payload: FraudLimitsResponse }>()
);

export const onUpdateDefaultFraudLimitFailed = createAction(
    '[Admin FraudLimits] updateDefaultFraudLimit failed',
    props<{ payload: any }>()
);

export const addIssuer = createAction(
    '[Admin FraudLimits] addIssuer',
    props<{ payload: IssuerLimitsOfAddOrUpdateRequest }>()
);

export const onAddIssuerSuccessfully = createAction(
    '[Admin FraudLimits] addIssuer successfully',
    props<{ payload: IssuerLimitsOfAddOrUpdateRequest }>()
);

export const onAddIssuerFailed = createAction(
    '[Admin FraudLimits] addIssuer failed',
    props<{ payload: any }>()
);

export const updateIssuer = createAction(
    '[Admin FraudLimits] updateIssuer',
    props<{ payload: IssuerLimitsOfAddOrUpdateRequest }>()
);

export const onUpdateIssuerSuccessfully = createAction(
    '[Admin FraudLimits] updateIssuer successfully',
    props<{ payload: IssuerLimitsOfAddOrUpdateRequest }>()
);

export const onUpdateIssuerFailed = createAction(
    '[Admin FraudLimits] updateIssuer failed',
    props<{ payload: any }>()
);

export const deleteIssuer = createAction(
    '[Admin FraudLimits] deleteIssuer',
    props<{ payload: IssuerLimitsOfDeleteRequest }>()
);

export const onDeleteIssuersuccessfully = createAction(
    '[Admin FraudLimits] deleteIssuer successfully',
    props<{ payload: IssuerLimitsOfDeleteRequest }>()
);

export const onDeleteIssuerFailed = createAction(
    '[Admin FraudLimits] deleteIssuer failed',
    props<{ payload: any }>()
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}
