import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductsWithAvailablePricesDto } from '@modeso/types__dgoods-products';
import { AdminConfigService } from '../admin.config';
import { WhiteListingResponseModel } from '../models/whiteListingProducts/whiteListingResponse.model';

@Injectable({
    providedIn: 'root',
})
export class AdminWhiteListingProductsService {

    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly productsRoute = '/';
    private readonly getAllProductsAllLanguagesRoute = "allProductsAllLanguages";
    private readonly getAllProductsLineNamesRoute = "allProductsLineNames";
    private readonly whiteListProductRoute="whitelist/product/";
    private readonly synchronizeProductsRoute="synchronize"
    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;
    }
    public getAllProductsAllLanguages() {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.getAllProductsAllLanguagesRoute}`;
        return this.http.get<ProductsWithAvailablePricesDto[]>(url);
    }
    public whiteListProduct(product:{payload:string}) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.whiteListProductRoute}${product.payload}`;
        return this.http.post<WhiteListingResponseModel>(url,null);
    }
    public unWhiteListProduct(product:{payload:string}) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.whiteListProductRoute}${product.payload}`;
        return this.http.delete<WhiteListingResponseModel>(url);
    }
    public synchronizeProducts() {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.synchronizeProductsRoute}`;
        return this.http.post<string>(url,null);
    }

    public getAllProductLineNames() {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.getAllProductsLineNamesRoute}`;
        return this.http.get<string[]>(url);
    }

}
