<div class="terms-of-service-container">
  <div class="title-content-container">
    <dgoods-title-with-content title="dgoods_shared_termsofservicecomponent_title">
      <ng-content></ng-content>
    </dgoods-title-with-content>
  </div>
  <div class="button-container">
    <div class="sticky">
      <dgoods-button [delegate]="this">
        {{ "dgoods_shared_termsofservicecompontent_conclude" | injectedText | async }}
      </dgoods-button>
    </div>
  </div>
</div>
