import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe, Inject, Optional } from '@angular/core';
import * as DOMPurify from 'dompurify';
import Debug from 'debug';
const debug = Debug('modeso:core:SafeHtmlPipe');
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipeImages implements PipeTransform {

  constructor(
    protected sanitizer: DomSanitizer
  ) {

  }

  public transform(value: any, type: string): SafeHtml {
    const sanitizedContent = DOMPurify.sanitize(value, {
      FORBID_TAGS: ['form', 'script'],
      RETURN_DOM_FRAGMENT: false,
      RETURN_DOM: false,
    });
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
