import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminService } from '../services/admin.service';
import * as fromActions from '../actions/admin.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { AdminUsersResponse, IAdminUserModel } from '../models/administration/usersResponse.model';
import { HttpErrorResponse } from '@angular/common/http';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminEffects');


@Injectable()
export class AdminEffects {
    constructor(private actions$: Actions, private service: AdminService) { }
    login$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.login.type),
            mergeMap(
                (payload) => {
                    return this.service.login(payload)
                        .pipe(
                            map(
                                response => {
                                    return fromActions.onLoginSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onLoginFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnLogin$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onLoginFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    changePassowrd$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.changePassword.type),
            mergeMap(
                (payload) => {
                    return this.service.changePassword(payload)
                        .pipe(
                            map(
                                response => {
                                    return fromActions.onChangePasswordSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onChangePasswordFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnChangePassword = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onChangePasswordFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    signup$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.signup.type),
            mergeMap(
                (payload) => {
                    return this.service.signup(payload)
                        .pipe(
                            map(
                                response => {
                                    return fromActions.onSignupSauccessfully({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onSignupFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnSignup = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onSignupFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    logout$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.logout),
            mergeMap(
                () => {
                    return this.service.logout()
                        .pipe(
                            map(
                                response => {
                                    return fromActions.onLogoutSuccessfully();
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onLogoutFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnLogout$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onLogoutFailed),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    getNewAccessToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getNewAccessToken.type),
            mergeMap(
                () => {
                    return this.service.getNewAccesToken()
                        .pipe(
                            map(
                                response => {
                                    return fromActions.onGetNewAccessTokenSuccessfully();
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetNewAccessTokenFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnGetNewAccessToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetNewAccessTokenFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    handleOnLoadAllErrors(error) {
        debug(error);
        return error;
    }

    // tslint:disable-next-line:member-ordering
    getAdminUsers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getAdminUsers.type),
            mergeMap(
                () => {
                    return this.service.getAdminUsers()
                        .pipe(
                            map(
                                (response: AdminUsersResponse) => {
                                    return fromActions.onGetGetAdminUsersSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onGetAdminUsersFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    // tslint:disable-next-line:member-ordering
    errorOnGetAdminUsers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetAdminUsersFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    // tslint:disable-next-line:member-ordering
    updateUserById$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateUserById.type),
            mergeMap(
                (payload: { payload: IAdminUserModel}) => {
                    debug(payload);
                    return this.service.updateAdminUser(payload.payload)
                        .pipe(
                            map(
                                (response: IAdminUserModel) => {
                                    return fromActions.onUpdateUserByIdSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onUpdateUserByIdFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    // tslint:disable-next-line:member-ordering
    errorOnUpdateUserById$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateUserByIdFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    // tslint:disable-next-line:member-ordering
    deleteUserById$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.deleteUserById.type),
            mergeMap(
                (payload: { payload: IAdminUserModel}) => {
                    debug(payload);
                    return this.service.deleteAdminUser(payload.payload.id)
                        .pipe(
                            map(
                                (response: IAdminUserModel) => {
                                    return fromActions.onDeleteUserByIdSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onDeleteUserByIdFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    // tslint:disable-next-line:member-ordering
    errorOnDeleteUserById$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateUserByIdFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    getUserRoles$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getUserRoles.type),
            mergeMap(
                () => {
                    return this.service.getUserRoles()
                        .pipe(
                            map(
                                (response: string[]) => {
                                    return fromActions.onGetUserRolesSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onGetUserRolesFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnGetUserRoles$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetUserRolesFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );
}

