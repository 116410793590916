export class IssuerLimitsOfAddOrUpdateRequest {
    public name: string;
    public value: number;
    public timeframeName: string;
    public timeframeValue: number;
}

export class IssuerLimitsResponse {
    public message: string;
}

export class IssuerLimitsOfDeleteRequest {
    public issuerName: string;
}
