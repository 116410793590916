<div class="container" *ngIf="!hasReadPermission()">
  Sorry you don't have read permission.
</div>
<div class="container" *ngIf="hasReadPermission()">
  <div class="row">
    <label>Default</label>
    <div class="table-container">
      <table mat-table [dataSource]="defaultDataSource">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? defaultSelection.toggle(row) : null"
              [checked]="defaultSelection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.fraudLimit}} </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.fraudLimitDescription}} </td>
        </ng-container>

        <!-- Value Column -->
        <ng-container matColumnDef="Value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element"> {{element.fraudLimitValue}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="defaultFraudLimitsColmuns;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: defaultFraudLimitsColmuns;" (click)="defaultSelection.toggle(row)">
        </tr>
      </table>
      <mat-paginator #defaultFraudPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
    </div>

    <div class="messages">
      <span class="failure" *ngIf="isServerErrorDefaultFraud">{{errorMessage}}</span>
    </div>

    <div class="action-button">
      <button  *ngIf="hasWritePermission()" id="updateDefaultFraudButton" [disabled]="defaultUpdateButtonStatus()"
        (click)="onUpdateDefaultFraud()">Update</button>
    </div>

  </div>


  <div class="row">
    <label>Issuers</label>
    <div class="table-container">
      <table mat-table [dataSource]="issuerDataSource">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? issuerSelection.toggle(row) : null"
              [checked]="issuerSelection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Value Column -->
        <ng-container matColumnDef="Value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>

        <!-- Timeframe Name Column -->
        <ng-container matColumnDef="Timeframe Name">
          <th mat-header-cell *matHeaderCellDef> Timeframe Name </th>
          <td mat-cell *matCellDef="let element"> {{element.timeframeName}} </td>
        </ng-container>

        <!-- Timeframe Value Column -->
        <ng-container matColumnDef="Timeframe Value">
          <th mat-header-cell *matHeaderCellDef> Timeframe Value </th>
          <td mat-cell *matCellDef="let element"> {{element.timeframeValue}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="issuerFraudLimitsColmuns;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: issuerFraudLimitsColmuns;" (click)="issuerSelection.toggle(row)">
        </tr>
      </table>
      <mat-paginator #issuerFraudPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

      <div class="messages">
        <span class="failure" *ngIf="isServerErrorIssuer">{{errorMessage}}</span>
      </div>

      <div class="action-button"  *ngIf="hasWritePermission()">
        <button id="saveAddressButton" (click)="onAddIssuer()">Add</button>
        <button id="saveAddressButton" [disabled]="issuerButtonStatus()" (click)="onUpdateIssuer()">Update</button>
        <button id="saveAddressButton" [disabled]="issuerButtonStatus()" (click)="onDeleteIssuer()">Delete</button>
      </div>

    </div>

  </div>

</div>