/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';
import Debug from 'debug';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { couponInitValue } from './coupon';
import { TwintCouponsProvider } from '../../providers/twint.coupons.provider';
import { CouponDTO, CouponEffectTypeEnum, CouponTypeEnum, CouponCodeDTO, CouponJournalEnum, RedeemStateEnum } from '@modeso/types__twint-lib-coupons';
import { Observable, Subject, Subscription, combineLatest, map, of, take, takeUntil } from 'rxjs';
import { filter, find, tap } from 'rxjs';
import { CUSTOM_DATE_FORMATS, CUSTOM_MOMENT_FORMATS } from '../..//util/dateFormats.helper';
import { CouponErrorDialog } from '../../dialog/errorDialog/errorDialog';
import { ConfirmDeletionDialog } from '../../dialog/confirmDeletionDialog/confirmDeletion';
import { ConfirmationDialog } from '../../dialog/confirmationDialog/confirmationDialog';
import { lastValueFrom } from 'rxjs';
import { CouponDTOExtended } from '../list-coupons/list-coupons.component';
import { ModificationData } from '../../models/interfaces/modification-data.interface';
import equal from 'fast-deep-equal';
import { AdminUsersResponse } from '../../../../../dgoods-lib-admin-fe/src/lib';
import { IFinancingParty } from '../../models/interfaces/finance';
import { AddFinancingPartyDialog } from '../../dialog/addFinancingParty/addFinancingParty';
import { UserProvider } from '../../../../../dgoods-lib-user-fe/src/lib';
import { CouponCodeRedemptionDetails } from '../../models/interfaces/coupon-code-redepmtion-details.interface';

const debug = Debug('modeso:dgoods-admin:AddCouponPage');
const MAX100_Validator = Validators.max(100.00)
const MAX500_Validator = Validators.max(500.00)
@Component({
  selector: 'app-add-coupon-component',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class AddCouponComponent {

  @Input() couponId;
  @Input() adminUsers$: Observable<AdminUsersResponse>;
  @Input() productsObservable$: Observable<any>;
  @ViewChild('allProductsSelected') private readonly allProductsSelected: MatOption;
  @Output() redirectToList: EventEmitter<any> = new EventEmitter();
  displayedColumnsForAutoGeneratedCodes: string[] = ['code', 'email', 'userId', 'time', 'state', 'options'];
  displayedColumnsForManualCodes: string[] = ['email', 'userId', 'time'];
  couponForm: FormGroup;
  showSeconds = false;
  defaultTime = [0, 0];
  isEditMode = false;
  isViewMode = false;
  isCouponRedeemed = false;
  isFormSubmitted = false;
  isCouponControlled: boolean = false;
  error = '';
  couponCodes: CouponCodeRedemptionDetails[] = [];
  showInvalidateCodesBtn = false;
  whiteListedProducts = [];
  getCouponDetails: Subscription;
  getErrors: Subscription;
  productsSubscription: Subscription;
  private usersEmailsSubscription: Subscription | undefined;
  redeemState: RedeemStateEnum = RedeemStateEnum.OPEN;
  availableCodes: number = -1;
  adminUserDetails: ModificationData;
  private initialCoupon: Partial<CouponDTO> | undefined;
  private userTokens: string[] = [];
  private readonly destroy$ = new Subject<void>();
  financingParties$: Observable<string[]> = of([]);
  financingParties: IFinancingParty[] = [];
  couponFinancingPartyTotal: number[] = [0, 0, 0];
  isCouponArchived: boolean = false;
  private readonly FINANCING_PARTIES_COUNT = 3;


  constructor(private readonly formBuilder: FormBuilder,
    private readonly couponProvider: TwintCouponsProvider,
    private readonly usersProvider: UserProvider,
    private readonly dialog: MatDialog) { }

  ngOnInit() {

    this.getErrors = this.couponProvider.getCouponError$().subscribe((httpError) => {
      debug("errors...")
      debug(httpError)
      if (httpError) {
        let errorMessage = "Internal Server error";

        if (httpError?.error?.message) {
          errorMessage = httpError?.error?.message;
          this.isFormSubmitted = false;
        }

        this.dialog.open(CouponErrorDialog, {
          data: {
            error: errorMessage
          },
        });
      }
    });


    if (this.couponId) {
      this.couponProvider.dispatchGetCouponById$(this.couponId);
      this.isEditMode = true;
      this.getCouponDetails = combineLatest([
        this.couponProvider.getSelectedCoupon$(),
        this.adminUsers$]).
        subscribe(([coupon, adminUsers]) => {

          // coupon is found in store
          if (coupon) {
            this.redeemState = this.calculateRedeemState(coupon);
            this.availableCodes = this.calculateAvailableCodes(coupon);
            this.initCouponForm(coupon);
            this.isCouponRedeemed = this.checkCouponRedeemedState(coupon)
            this.disableFieldsAccordingToType(coupon.couponType);
            this.initialCoupon = this.initialCouponObject(coupon);
            this.isCouponControlled = coupon?.controlled != null && coupon.controlled;
            this.isCouponArchived = coupon?.archived != null && coupon.archived;
            if (coupon.products.length === 0) {
              this.products.patchValue([0]);
            }

            if (adminUsers != null && adminUsers.length > 0) {
              this.adminUserDetails = {
                createdBy: adminUsers?.find((user) => user.id === coupon.createdBy)?.name,
                createdAt: coupon.createdAt,
                modifiedBy: adminUsers?.find((user) => user.id === coupon.updatedBy)?.name,
                modifiedAt: coupon.updatedAt
              }
            }

            const userIds = coupon.codes.filter(item => item.userId !== '').map((filtered) => filtered.userId);
            // generate a unique user tokens array from userIds using set
            this.userTokens = [...new Set(userIds)];

            if (this.userTokens.length !== 0) {
              this.usersProvider.dispatchGetUsersEmails$(this.userTokens);
            }
          }

        })

      this.usersEmailsSubscription = this.usersProvider.getUsersEmails$().pipe(filter(response => response != null)).subscribe((response) => {
        const codes = [];
        this.couponCodes.forEach((code) => {
          codes.push({ ...code, email: response[code.userId] })
        })
        this.couponCodes = [...codes];
      });


    } else {
      this.initCouponForm(couponInitValue);
    }

    this.productsSubscription = this.productsObservable$?.subscribe((products) => {

      if (products) {

        this.whiteListedProducts = [...products];
        // initially add all products to coupon products array
        if (!this.couponId || this.whiteListedProducts.length === this.products?.value.length) {
          // patch a '0' value for 'ALL' selection
          this.products.patchValue([0]);
        }
      }
    });

    this.couponProvider.dispatchGetFinancingParties();
    this.financingParties$ = this.couponProvider.selectFinancingParties().pipe(map(
      (financingParties) => {
        this.financingParties = financingParties;
        return financingParties.map((party) => party.name);
      }
    ));
  }


  initCouponForm(couponInit) {
    const coupon = { ...couponInit };
    this.couponForm = this.formBuilder.group({
      name: new FormControl(coupon.name, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      couponType: new FormControl(coupon.couponType, [Validators.required]),
      state: new FormControl(coupon.state),
      // to be changed after auto generation imp
      manualCodes: new FormControl(coupon.codes[0]?.code, [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$'), Validators.minLength(6), Validators.maxLength(20)]),
      noOfCodes: new FormControl(coupon.codes?.length !== 0 ? coupon.codes.length : '', [Validators.max(100000), Validators.min(1)]),
      effectType: new FormControl(coupon.effectType, [Validators.required]),
      effectValue: new FormControl(coupon.effectValue, [Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$'), Validators.min(0.01), Validators.required]),
      products: new FormControl(coupon.products),
      minPurchaseAmount: new FormControl(coupon.minPurchaseAmount, [Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$'), Validators.min(0.00), Validators.max(9999.99), Validators.required]),
      maxApplicationPerUser: new FormControl(coupon.maxApplicationPerUser, [Validators.min(0), Validators.max(9999999999), Validators.required]),
      maxApplicationOverAllUsers: new FormControl(coupon.maxApplicationOverAllUsers, [Validators.min(0), Validators.max(9999999999), Validators.required]),
      startFrom: new FormControl(coupon.startFrom, Validators.required),
      endAt: new FormControl(coupon.endAt, Validators.required),
      // Three direct financing parties
      costSplitType: new FormControl(coupon.costSplitType),
      financingPartyName1: new FormControl(coupon.financingParties?.[0]?.name),
      financingPartyAmount1: new FormControl(coupon.financingParties?.[0]?.amountOrPercentage, [Validators.min(0), Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      financingPartyName2: new FormControl(coupon.financingParties?.[1]?.name),
      financingPartyAmount2: new FormControl(coupon.financingParties?.[1]?.amountOrPercentage, [Validators.min(0), Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      financingPartyName3: new FormControl(coupon.financingParties?.[2]?.name),
      financingPartyAmount3: new FormControl(coupon.financingParties?.[2]?.amountOrPercentage, [Validators.min(0), Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      comment: new FormControl(coupon.comment)
    });
    this.changeCouponEffectType(coupon.effectType, true);

    // Define a custom validator that compares the values of the FormControls
    function validateFields(form: FormGroup) {
      const minPurchaseAmount = form.get('minPurchaseAmount').value;
      const effectValue = form.get('effectValue').value;
      const effectType = form.get('effectType').value;
      const startFrom = form.get('startFrom').value;
      const endAt = form.get('endAt').value;

      let invalidDates = false;
      if (startFrom && endAt) {
        if (!moment(endAt).isAfter(moment(startFrom))) {

          invalidDates = true;
        }
        if (moment(endAt).isAfter(moment("2032-12-31"))) {
          invalidDates = true;
        }
        if (moment(startFrom).isAfter(moment("2032-12-31"))) {

          invalidDates = true;
        }
        if (moment(startFrom).isBefore(moment("2022-12-31"))) {

          invalidDates = true;
        }
      }

      if (effectType === CouponEffectTypeEnum.Fixed) {
        // Compare the values of the fields and return an error if necessary
        if (minPurchaseAmount < effectValue) {
          return { invalidDates, minPurchaseAmountToSmall: true };
        }
      }

      if (invalidDates) {
        return { invalidDates }
      }
      return null

    }

    this.couponForm.setValidators(Validators.compose([validateFields]));


    if (this.isEditMode) {
      this.couponCodes = [...coupon.codes];
      if (this.couponType.value === CouponTypeEnum.Multi) {
        this.showInvalidateCodesBtn = this.couponCodes.filter((code) => code.state === CouponJournalEnum.CREATED || code.state === CouponJournalEnum.CANCELED).length !== 0;
      }
    }

    if (coupon.effectType === CouponEffectTypeEnum.Percentage) {
      this.costSplitType.setValue('Percentage');
      this.costSplitType.disable();
    }

    this.effectType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((effectType) => {
      if (effectType === CouponEffectTypeEnum.Percentage) {
        this.costSplitType.setValue('Percentage');
        this.costSplitType.disable();
      } else {
        this.costSplitType.enable();
      }
    });
    this.handleCostSplittingAmongFinancingParties(coupon);
  }

  private handleCostSplittingAmongFinancingParties(coupon: CouponDTO): void {
    if (coupon?.totalDiscount == null || coupon?.financingParties == null || coupon?.financingParties.length === 0 || coupon?.costSplitType == null) {
      return;
    }

    const totalDiscount = Math.round(coupon.totalDiscount * 100) / 100; // Ensures precision to two decimal places.

    // Check if effectType and costSplitType are the same
    if (coupon.effectType === coupon.costSplitType) {
      this.splitCost(coupon, totalDiscount, coupon.effectValue);
      return;
    }

    // Handle specific mismatched configurations
    const isFixedEffectType = coupon.effectType === CouponEffectTypeEnum.Fixed;
    const isPercentageCostSplitType = coupon.costSplitType === CouponEffectTypeEnum.Percentage;

    if (isFixedEffectType && isPercentageCostSplitType) {
      this.splitCost(coupon, totalDiscount, 100);
    } else if (!isFixedEffectType && !isPercentageCostSplitType) {
      throw new Error('Invalid Coupon Configuration');
    } else {
      throw new Error('Unhandled Coupon Configuration');
    }
  }


  private splitCost(coupon: CouponDTO, totalDiscount: number, denominator: number) {
    for (let i = 0; i < this.FINANCING_PARTIES_COUNT; i++) {
      const financingPartyName = this.couponForm.get(`financingPartyName${i + 1}`).value;
      const financingPartyAmount = this.couponForm.get(`financingPartyAmount${i + 1}`).value;

      if (
        financingPartyName != null && financingPartyAmount != null &&
        financingPartyName.trim() !== '' && financingPartyAmount >= 0
      ) {
        const amountOrPercentage = parseFloat((coupon.financingParties[i]?.amountOrPercentage ?? 0).toFixed(2));
        this.couponFinancingPartyTotal[i] = Math.round(((amountOrPercentage * totalDiscount) / denominator) * 100) / 100;
      }
    }
  }




  async confirmSubmit() {
    let data;

    let dialogText = "";

    if (this.noOfCodes.valid && this.noOfCodes.value > 500 && !this.isEditMode) {
      dialogText = `You have selected to generate ${this.noOfCodes.value} codes. Once the codes are generated this field will no longer be editable.<br>`;
    }
    if (this.effectType.value === CouponEffectTypeEnum.Percentage && this.effectValue.value > 10) {
      dialogText = `${dialogText}You have selected ${this.effectValue.value}% as a discount value.<br>`;
    }


    if (this.effectType.value === CouponEffectTypeEnum.Fixed && this.effectValue.value > 10) {
      dialogText = `${dialogText}<br>You have selected CHF ${this.effectValue.value} as a discount price amount.<br>`;
    }

    let costSplitTypeFlag = false;
    if (this.isAnyFinancingPartyInComplete()) {
      dialogText = `${dialogText}<br>One or more financing parties are incomplete. If you proceed, the coupon will be saved without the incomplete financing parties.<br>`;
    } else {
      // there are no incomplete financing parties
      // check if costSplitType is still null
      if (this.costSplitType.value == null && !this.areAllFinancingPartiesEmpty()) {
        dialogText = `${dialogText}<br>Please select a cost split type.<br>`;
        costSplitTypeFlag = true;
      }
    }

    if (dialogText !== "") {

      dialogText = `${dialogText}<br><br>Are you sure?`
      data = {
        text: dialogText,
        type: 1
      }
      if (costSplitTypeFlag) {
        data = {
          text: `Please select a cost split type.`,
          type: 2
        }
      }
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data
      });
      const result = await lastValueFrom(dialogRef.afterClosed().pipe(take(1)));
      if (!result) return;
    }
    this.submit();
  }

  submit() {

    this.error = undefined;
    this.isFormSubmitted = true;

    let coupon = {
      name: this.name?.value,
      couponType: this.couponType?.value,
      state: this.state.value,
      // remove the 'All' from products selection menu
      products: this.products?.value.filter(product => product !== 0),
      minPurchaseAmount: this.minPurchaseAmount?.value,
      startFrom: this.startFrom?.value,
      endAt: this.endAt?.value,
      effectType: this.effectType?.value,
      effectValue: this.effectValue.value,
      maxApplicationOverAllUsers: this.maxApplicationOverAllUsers?.value,
      maxApplicationPerUser: this.maxApplicationPerUser?.value,
      costSplitType: this.costSplitType?.value,
      comment: this.comment?.value,
    } as CouponDTO;

    coupon.financingParties = [];

    if (this.financingPartyName1.value && this.financingPartyAmount1) {
      coupon.financingParties[0] = {
        name: this.financingPartyName1?.value,
        amountOrPercentage: this.financingPartyAmount1?.value
      }
    }
    if (this.financingPartyName2.value && this.financingPartyAmount2) {
      coupon.financingParties[1] = {
        name: this.financingPartyName2?.value,
        amountOrPercentage: this.financingPartyAmount2?.value
      }
    }
    if (this.financingPartyName3.value && this.financingPartyAmount3) {
      coupon.financingParties[2] = {
        name: this.financingPartyName3?.value,
        amountOrPercentage: this.financingPartyAmount3?.value
      }
    }

    switch (this.couponType.value) {
      case CouponTypeEnum.Single:
        coupon.codes = [this.manualCodes.value.toLowerCase()];
        break;

      case CouponTypeEnum.Multi:
        coupon.noOfCodes = this.noOfCodes?.value
        break;
    }

    if (this.couponId) {
      coupon.id = this.couponId;
      if (!this.isViewMode && !equal(this.initialCoupon, coupon)) {
        console.log("coupon changed",
          this.initialCoupon,
          coupon);

        this.couponProvider.editCoupon(coupon);
      } else {
        // edit coupon effect redirects to navigate so will do an else check incase no edit happened
        this.navigateBackToList();
      }
    } else {
      this.couponProvider.createCoupon(coupon);
    }

  }

  private isAnyFinancingPartyInComplete(): boolean {

    // check if any financing party is incomplete (meaning it has a name but no amount or vice versa)
    for (let i = 0; i < this.FINANCING_PARTIES_COUNT; i++) {
      const financingPartyName = this.couponForm.get(`financingPartyName${i + 1}`)?.value;
      const financingPartyAmount = this.couponForm.get(`financingPartyAmount${i + 1}`)?.value;

      const isNameInvalid = financingPartyName == null || financingPartyName.trim() === '';
      const isAmountValid = financingPartyAmount != null && financingPartyAmount >= 0;
      if (isNameInvalid && isAmountValid) {
        return true;
      }
    }
    return false;
  }

  private areAllFinancingPartiesEmpty(): boolean {
    for (let i = 0; i < this.FINANCING_PARTIES_COUNT; i++) {
      const financingPartyName = this.couponForm.get(`financingPartyName${i + 1}`)?.value;
      const financingPartyAmount = this.couponForm.get(`financingPartyAmount${i + 1}`)?.value;

      const isNameEmpty = financingPartyName == null || financingPartyName.trim() === '';
      const isAmountEmpty = financingPartyAmount == null || financingPartyAmount === '';

      if (!isNameEmpty || !isAmountEmpty) {
        return false;
      }
    }
    return true;
  }

  toggleAllProductSelection() {
    if (this.allProductsSelected.selected) {
      this.products
        .patchValue([0]);
    } else {
      this.products.patchValue([...this.products.value]);
    }
  }

  unselectAllProductsIfNeeded() {
    if (this.allProductsSelected.selected) {
      this.allProductsSelected.deselect();
    }
  }

  changeCouponEffectType(couponEffectType, onInit = true) {
    // remove the effect value in case of toggling effect type only in add mode
    if (!onInit && !this.couponId && this.effectValue.value) {
      this.effectValue.setValue("")
    }
    if (couponEffectType === CouponEffectTypeEnum.Fixed) {
      this.effectValue.removeValidators(MAX100_Validator)
      this.effectValue.addValidators(MAX500_Validator);

    } else {
      // clear validator
      this.effectValue.removeValidators(MAX500_Validator)
      this.effectValue.addValidators(MAX100_Validator);
    }
    this.effectValue?.updateValueAndValidity();
  }

  confirmInputValue(control: string) {

    let data;

    switch (control) {

      case 'generatedCodes':
        data = {
          text: `You have selected to generate ${this.noOfCodes.value} codes. Once the codes are generated this field will no longer be editable. <br><br> Are you sure?`,
          type: 2
        }
        if (this.noOfCodes.valid && !this.isEditMode) {
          this.dialog.open(ConfirmationDialog, {
            data
          });
        }
        break;

      case 'discount':

        break;

      case 'fixedAmount':

        break;

      default:
        break;
    }

  }

  changeCouponType(couponType) {
    if (couponType === CouponTypeEnum.Multi) {
      this.maxApplicationOverAllUsers.setValue(1);
      this.maxApplicationPerUser.setValue(1);
      this.noOfCodes.addValidators(Validators.required)
      this.manualCodes.removeValidators(Validators.required)
      this.maxApplicationOverAllUsers.disable();
      this.maxApplicationPerUser.disable();

    } else {
      this.maxApplicationOverAllUsers.setValue(1)
      this.maxApplicationPerUser.setValue(0)
      this.noOfCodes.removeValidators(Validators.required)
      this.manualCodes.addValidators(Validators.required)
      this.maxApplicationPerUser.enable();
      this.maxApplicationOverAllUsers.enable();

    }
    this.manualCodes.updateValueAndValidity();
    this.noOfCodes.updateValueAndValidity();
  }

  invalidateCode(code) {
    const invalidateCodeObj = {
      couponId: this.couponId,
      codeId: code
    }
    this.couponProvider.invalidateCode(invalidateCodeObj);
  }

  invalidateCodes() {

    const dialogRef = this.dialog.open(ConfirmDeletionDialog, {
      data: {
        message: `Are you sure you want to invalidate all codes?`
      },
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
      if (result) {
        this.couponProvider.invalidateCodes(this.couponId);
      }
    });
  }

  downloadCodes() {
    const fileName = "Campaign_" + this.name.value + `_codes_${moment().format('YYYYMMDDHHmmss')}.xlsx`;
    const mappedCodes = this.couponCodes.map((code: any) => {
      let codeMap = { ...code }
      codeMap.state = this.couponJournalEnumMapper[code.state].toLowerCase()
      return codeMap;
    })
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedCodes);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'test');
    XLSX.writeFile(wb, fileName);
  }

  controlCoupon(): void {

    const dialogText = `Do you really want to set the status to controlled?`
    const data = {
      text: dialogText,
      type: 1
    }

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // only dispatch if result is true
      if (result === true) {
        this.couponProvider.controlCoupon(this.couponId);
      }
    });

  }

  navigateBackToList() {
    this.redirectToList.emit();
  }

  checkCouponRedeemedState(coupon): boolean {

    if (coupon.couponType === CouponTypeEnum.Multi) {
      return coupon.codes.length === coupon.usedCodes;
    }

    if (coupon.couponType === CouponTypeEnum.Single) {
      if (coupon.maxApplicationOverAllUsers === 0) {
        return false;
      } else {
        return coupon.maxApplicationOverAllUsers <= coupon.usedCodes;
      }
    }

    return false;

  }

  disableFieldsAccordingToType(couponType) {

    if (this.isEditMode) {
      this.couponType.disable();
      this.manualCodes.disable();
      this.noOfCodes.disable();
      this.effectType.disable();

      if (couponType === CouponTypeEnum.Multi) {
        this.maxApplicationPerUser.disable();
        this.maxApplicationOverAllUsers.disable();
      }

      if (this.isCouponRedeemed) {
        this.effectValue.disable();
        this.minPurchaseAmount.disable();
        this.maxApplicationPerUser.disable();
        this.maxApplicationOverAllUsers.disable();
        this.startFrom.disable();
        this.endAt.disable();
      }
    }
  }

  get name() {
    return this.couponForm.get('name');
  }
  get couponType() {
    return this.couponForm.get('couponType');
  }
  get manualCodes() {
    return this.couponForm.get('manualCodes');
  }
  get noOfCodes() {
    return this.couponForm.get('noOfCodes');
  }
  get effectType() {
    return this.couponForm.get('effectType');
  }
  get effectValue() {
    return this.couponForm.get('effectValue');
  }
  get products() {
    return this.couponForm?.get('products');
  }
  get minPurchaseAmount() {
    return this.couponForm.get('minPurchaseAmount');
  }
  get maxApplicationPerUser() {
    return this.couponForm.get('maxApplicationPerUser');
  }
  get maxApplicationOverAllUsers() {
    return this.couponForm.get('maxApplicationOverAllUsers');
  }
  get startFrom() {
    return this.couponForm.get('startFrom');
  }
  get endAt() {
    return this.couponForm.get('endAt');
  }
  get state() {
    return this.couponForm.get('state');
  }
  get costSplitType() {
    return this.couponForm.get('costSplitType');
  }
  get financingPartyName1() {
    return this.couponForm.get('financingPartyName1');
  }
  get financingPartyAmount1() {
    return this.couponForm.get('financingPartyAmount1');
  }
  get financingPartyName2() {
    return this.couponForm.get('financingPartyName2');
  }
  get financingPartyAmount2() {
    return this.couponForm.get('financingPartyAmount2');
  }
  get financingPartyName3() {
    return this.couponForm.get('financingPartyName3');
  }
  get financingPartyAmount3() {
    return this.couponForm.get('financingPartyAmount3');
  }
  get comment() {
    return this.couponForm.get('comment');
  }


  public get couponTypes(): typeof CouponTypeEnum {
    return CouponTypeEnum;
  }

  public get couponEffectTypes(): typeof CouponEffectTypeEnum {
    return CouponEffectTypeEnum;
  }

  public get couponJournalEnum(): typeof CouponJournalEnum {
    return CouponJournalEnum;
  }
  public get couponJournalEnumMapper(): typeof CouponJournalEnum {
    let journalEnum = { ...CouponJournalEnum };
    journalEnum[journalEnum.CANCELED] = 'PAYMENT CANCELLED';
    return journalEnum;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy() {
    if (this.getCouponDetails) {
      this.getCouponDetails.unsubscribe();
    }
    if (this.getErrors) {
      this.getErrors.unsubscribe();
      this.couponProvider.flushError();
    }

    if (this.productsSubscription) {
      this.productsSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();

    if (this.usersEmailsSubscription != null) {
      this.usersEmailsSubscription.unsubscribe();
    }
  }

  calculateRedeemState(coupon: CouponDTOExtended) {
    let redeemState;

    if (coupon.couponType === CouponTypeEnum.Multi) {
      redeemState = coupon.codes.length <= coupon.usedCodes
        ? RedeemStateEnum[RedeemStateEnum.REDEEMED]
        : RedeemStateEnum[RedeemStateEnum.OPEN];

    }
    else if (coupon.couponType === CouponTypeEnum.Single) {
      if (coupon.maxApplicationOverAllUsers === 0) {
        redeemState = RedeemStateEnum[RedeemStateEnum.OPEN];

      } else {
        redeemState = coupon.maxApplicationOverAllUsers <= coupon.usedCodes
          ? RedeemStateEnum[RedeemStateEnum.REDEEMED]
          : RedeemStateEnum[RedeemStateEnum.OPEN];

      }
    }
    return redeemState;
  }

  calculateAvailableCodes(coupon: CouponDTOExtended) {
    let countTotal;

    if (coupon.couponType == CouponTypeEnum.Single) {
      if (coupon.maxApplicationOverAllUsers == 0) {
        return null;
      }
      else {
        countTotal = coupon.maxApplicationOverAllUsers;
      }

    } else if (coupon.couponType === CouponTypeEnum.Multi) {
      countTotal = coupon.codes.length;

    }
    const countAvailable = countTotal - coupon.usedCodes;
    return countAvailable;
  }

  addFinanceParty(): void {
    // show a dialog to add a new financing name to the list of financing parties

    const dialogRef = this.dialog.open(AddFinancingPartyDialog);

    dialogRef.afterClosed()
      .pipe(
        filter((result) => result != null),
        take(1)
      )
      .subscribe((result) => {
        this.checkIfNewFinancingPartyAlreadyExists(result);
      });
  }

  private checkIfNewFinancingPartyAlreadyExists(name: string): void {
    // make the equality check case insensitive
    if (this.financingParties.some((party) => party.name.toLowerCase() === name.toLowerCase())) {
      // show an error dialog
      this.dialog.open(CouponErrorDialog, {
        data: {
          error: `The financing party '${name}' already exists.`
        },
      });
    } else {
      this.couponProvider.addFinanceParty(name);
      // show a success dialog
      this.dialog.open(ConfirmationDialog, {
        data: {
          text: `Finance party added successfully.`,
          type: 2
        },
      });

    }
  }

  private initialCouponObject(coupon: CouponDTO): Partial<CouponDTO> {
    const initialCoupon: CouponDTO = {
      couponType: coupon.couponType,
      effectType: coupon.effectType,
      effectValue: coupon.effectValue,
      endAt: coupon.endAt,
      id: coupon.id,
      maxApplicationOverAllUsers: coupon.maxApplicationOverAllUsers,
      maxApplicationPerUser: coupon.maxApplicationPerUser,
      minPurchaseAmount: coupon.minPurchaseAmount,
      name: coupon.name,
      startFrom: coupon.startFrom,
      state: coupon.state,
      financingParties: coupon.financingParties == null ? [] : [...coupon.financingParties],
      costSplitType: coupon.costSplitType == null ? null : coupon.costSplitType,
      comment: coupon.comment == null ? null : coupon.comment,
    } as CouponDTO;

    initialCoupon.products = coupon.products != null ? [...coupon.products] : [];

    if (coupon.couponType === CouponTypeEnum.Multi) {
      initialCoupon.noOfCodes = coupon.codes.length
    } else {
      initialCoupon.codes = [coupon.codes[0].code] as unknown as CouponCodeDTO[];
    }

    return initialCoupon;

  }
}
