<div class="dgoods-email-confirmation" [hidden]="hidden" [style]="style">
  <span id="sender" *ngIf="isProductPage">
    {{ "dgoods_shared_emailconfirmation_receiver" | injectedText | async }}
  </span>
  <dgoods-input #input1 class="margin" [caption]="caption" id="email" [delegate]="this"
  [value]="value1">
  </dgoods-input>
  <dgoods-input #input2 *ngIf="!isProductPage" [caption]="captionConfirm" id="email" [delegate]="this"
  [value]="value2">
  </dgoods-input>
  <div *ngIf="isProductPage">
    <div>
      <span id="sender">
        {{ "dgoods_shared_emailconfirmation_sender" | injectedText | async }}
      </span>
      <dgoods-input #input3 class="margin" [caption]="yourName" id="email" [delegate]="this">
      </dgoods-input>
    </div>
    <span id="sendMessage">
       {{ "dgoods_shared_emailconfirmation_message" | injectedText | async }}
    </span>
    <dgoods-textarea #input4 [caption]="yourMessage" id="email" [delegate]="this">
    </dgoods-textarea>
  </div>

</div>
