import { Component, ElementRef, Input, Output, ViewChild , EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith, tap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MultiSelect } from '../../models/interfaces/multi-select.interface';

@Component({
  selector: 'app-multi-select-component',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent {

  chipControl = new FormControl();
  filteredList: Observable<MultiSelect[]>;
  selectedList = new Set<MultiSelect>();
  @Input() list: MultiSelect[] = [];
  @Input() title: string = 'Select';
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @Output() selectedListChange = new EventEmitter<Set<MultiSelect>>();


  constructor() {
    this.filteredList = this.chipControl.valueChanges.pipe(
      startWith(null),
      map((value: string | MultiSelect | null) => 
        (value != null && (typeof value !== 'string' || value.trim() !== '') ? this.filterSelectionList(value) : this.list.slice())),
    );
  }

  remove(item: MultiSelect): void {
    this.selectedList.delete(item);
    this.selectedListChange.emit(this.selectedList);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedList.add(event.option.value); 
    this.selectedListChange.emit(this.selectedList);
    this.chipInput.nativeElement.value = '';
    this.chipControl.setValue(null);
  }

  private filterSelectionList(value: string | MultiSelect): MultiSelect[] {

    let filterValue: string;

    // on searching the value is sent as string ( for ex when u type r)
    if(typeof value === 'string'){
      filterValue = value.toLowerCase();
      return this.list.filter(item => item.name.toLowerCase().includes(filterValue));
    }
    // on selecting the value is sent as Multiselect Obj
    filterValue = value.name.toLowerCase();
    return this.list.filter(item => item.name.toLowerCase().includes(filterValue))
  }

}
