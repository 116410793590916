import { Component, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductLineForManagementDto } from '@modeso/types__dgoods-products';
import Debug from 'debug';
import { AngularEditorConfig } from '@kolkov/angular-editor';

const debug = Debug('modeso:dgoods-admin:UpdateContentByProductLineDialog');

export class UpdateProductContentDialogData {
    constructor(public product: ProductLineForManagementDto, public locale) {}
}

export class TranslationsByKeyResponseDetails {
    public key: string;
    public text: string;
    public locale: string;
}


class FormDataObject {
    public productLineName: string;
    public text: string;
    public defaultText: string;
    public locale: string;

    constructor(public product: ProductLineForManagementDto, locale: string) {
        this.productLineName = product.productLineName;
        this.text = this.getTwintDescription(product, locale);
        this.defaultText = this.getDefaultDescription(product, locale);
    }

    private getTwintDescription(product: ProductLineForManagementDto, locale: string) {
        const localizedContent = product.content.find(content => content.locale === locale);
        if (localizedContent) {
            return localizedContent.twintDescription;
        } else {
            return '';
        }
    }
    private getDefaultDescription(product: ProductLineForManagementDto, locale: string) {
        const localizedContent = product.content.find(content => content.locale === locale);
        if (localizedContent) {
            return localizedContent.defaultDescription;
        } else {
            return '';
        }
    }
}
@Component({
    selector: 'app-update-content-by-product-line-dialog',
    templateUrl: 'updateContentByProductLineDialog.html',
    styleUrls: ['./updateContentByProductLineDialog.scss'],
})
export class UpdateContentByProductLineDialog {
    updateContentByProductLineDialogForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<UpdateContentByProductLineDialog>,
        @Inject(MAT_DIALOG_DATA) public data: UpdateProductContentDialogData,
        private renderer: Renderer2) {

        const formdata = new FormDataObject(data.product, data.locale);
        debug(formdata);
        this.updateContentByProductLineDialogForm = new FormGroup({

            key: new FormControl({
                value: formdata.productLineName,
                disabled: true
            }, { validators: [Validators.required] }),
            text: new FormControl(formdata.text, { validators: [Validators.required] }),
            defaultText: new FormControl({
                value: formdata.defaultText,
                disabled: true
            }, { validators: [Validators.required] }),
        });
    }


    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: false,
        height: 'auto',
        minHeight: '200px',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '300px',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [

          ],
        customClasses: [
        ],
        uploadUrl: 'v1/image',
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            [
                'heading',
                'fontName'
              ],
              [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                'insertImage',
                'insertVideo',
                'removeFormat',
                'toggleEditorMode'
              ]

        ]
    };

    onNoClick(): void {
        this.dialogRef.close();
    }

    decodeHTML(input: string): string{
        const textAreaElement = this.renderer.createElement('textarea');
        textAreaElement.innerHTML = input;
        return textAreaElement.childNodes[0].nodeValue;
    }

    save() {
        if (this.updateContentByProductLineDialogForm.invalid) {
            return;
        } else {
            const localization: TranslationsByKeyResponseDetails = {
                key: this.data.product.productLineName,
                locale: this.data.locale,
                text: this.decodeHTML(this.updateContentByProductLineDialogForm.value['text']),
            };
            debug(localization);
            this.dialogRef.close(localization);
        }
    }

    buttonStatus() {
        return this.updateContentByProductLineDialogForm.invalid;
    }

}
