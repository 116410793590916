<div class="container" *ngIf="!hasReadPermission()">
    Sorry you don't have read permission.
</div>
<div class="container" *ngIf="hasReadPermission()">
    <div class="alert alert-danger" *ngIf="errorInUnWhiteListProduct">
        {{errorInUnWhiteListProductMessage}}
    </div>
    <div class="alert alert-danger" *ngIf="errorInWhiteListProduct">
        {{errorInWhiteListProductMessage}}
    </div>
    <div class="alert alert-danger" *ngIf="errorInSynchronizeProducts">
        {{errorInSynchronizeProductMessage}}
    </div>
    <div class="alert alert-danger" *ngIf="errorInGetProducts">
        {{errorInGetProductsMessage}}
    </div>
    <div class="alert alert-danger" *ngIf="errorInSavingSortingOrder">
        {{errorInSavingSortingOrderMessage}}
    </div>
    <div class="alert alert-success" *ngIf="succesfullyWhiteListedProduct">
        {{successfullyWhiteListedProductMessage}}
    </div>
    <div class="alert alert-success" *ngIf="succesfullyUnWhiteListedProduct">
        {{successfullyUnWhiteListedProductMessage}}
    </div>
    <div class="alert alert-success" *ngIf="succesfullySynchronizedProduct">
        {{successfullySynchronizeProductMessage}}
    </div>
    <div class="alert alert-success" *ngIf="successfullySavedSortingOrder">
        {{successfullySavedSortingOrderMessage}}
    </div>

    <h3 class="available-products-title">Public available Products</h3>
    <button type="button" (click)="saveOrder()">Save Order</button>
    
    <div class="table-layout" *ngFor="let whiteListedProduct of whiteListedProducts;let id=index" id="id{{id}}">
        <table class="table table-striped table-bordered table-hover table-flex-style" >

            <thead>
                <tr>
                    <th scope="col">{{whiteListedProduct.productLineName}} ({{ whiteListedProduct.merchantType }})</th>
                    <th scope="col">Locale</th>
                    <th scope="col">Available Prices</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let availablePrice of whiteListedProduct.availablePrices; let availablePricesId=index">
                    <th scope="row">{{availablePricesId}}</th>
                    <td>{{availablePrice.locale}}</td>
                    <td>{{availablePrice.prices}}</td>
                </tr>
            </tbody>
           
            <div class="buttons-container">
                <button *ngIf="hasWritePermission()"
                (click)="unWhiteListProduct(whiteListedProduct.productLineName)">Unpublish</button>
                <button *ngIf="hasWritePermission()"
                [ngClass]="{'btn_configure-digital-voucher': true, hasConfig: whiteListedProduct.hasDigitalVocherConfig, hasNoConfig: !whiteListedProduct.hasDigitalVocherConfig}"
                routerLink="../digitalvoucher/{{whiteListedProduct.productLineName}}/{{whiteListedProduct.merchantType}}">Configure Digital Voucher</button>
            </div>

        </table> 

        <div class="div-flex-style">
            <div class="cursor-buttons-style">
                <div (click)="sortProducts('top',id)">
                    <span class="material-symbols-outlined cursor-top">double_arrow</span>
                </div>
                <div (click)="sortProducts('up',id)">
                    <span class="material-icons cursor-top"> keyboard_arrow_right </span>
                </div>
                <div (click)="sortProducts('down',id)">
                    <span class="material-icons cursor-down"> keyboard_arrow_right </span> 
                </div>
                <div (click)="sortProducts('bottom',id)">
                    <span class="material-symbols-outlined cursor-down" >double_arrow</span>
                </div>   
            </div>
        </div>
    </div>
   

    <h3 id="test">Unpublished Products</h3>
    <table *ngFor="let unWhiteListedProduct of unWhiteListedProducts;let id=index"
        class="table table-striped table-bordered table-hover ">
        <thead>
            <tr>
                <th scope="col">{{unWhiteListedProduct.productLineName}} ({{ unWhiteListedProduct.merchantType }})</th>
                <th scope="col">Locale</th>
                <th scope="col">Available Prices</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let availablePrice of unWhiteListedProduct.availablePrices; let availablePricesId=index">
                <th scope="row">{{availablePricesId}}</th>
                <td>{{availablePrice.locale}}</td>
                <td>{{availablePrice.prices}}</td>
            </tr>
        </tbody>
        <div style="display: flex;">
            <button *ngIf="hasWritePermission()"
            (click)="whiteListProduct(unWhiteListedProduct.productLineName)"
            [disabled]="!unWhiteListedProduct.hasDigitalVocherConfig"
            >Publish</button>
            <button *ngIf="hasWritePermission()"
            [ngClass]="{'btn_configure-digital-voucher': true, hasConfig: unWhiteListedProduct.hasDigitalVocherConfig, hasNoConfig: !unWhiteListedProduct.hasDigitalVocherConfig}"
             routerLink="../digitalvoucher/{{unWhiteListedProduct.productLineName}}/{{unWhiteListedProduct.merchantType}}">Configure Digital Voucher</button>
        </div>

    </table>

    <div *ngIf="hasWritePermission()">
        <h4>Synchronize Products</h4>
        <div class="row" >
            <button (click)="synchronizeProducts()">Synchronize Products</button>
            <mat-spinner value=25 diameter="40" *ngIf="synchronizationInProgress"></mat-spinner>
        </div>
    </div>
