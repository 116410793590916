<div class="dgoods-navigation-container">
  <div class="back-container" (click)="onBackClick()">
    <img class="back-icon" src="assets/icon-chevron_back.svg" />
    <div class="back-text">{{ "dgoods_shared_navigationcompontent_back" | injectedText | async }}</div>
  </div>
  <div class=" account-container" (click)="onAccountClick()" *ngIf="account">
    <div class="account-text">{{ "dgoods_shared_navigationcompontent_account" | injectedText | async }} </div>
    <img class="account-icon" src="assets/icon-Account@2x.svg" />
  </div>
</div>
