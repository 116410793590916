import { Component, OnInit, Input } from '@angular/core';
import { IDGoodsButtonDelegate, DGoodsButtonComponent } from '../dgoods-button/dgoods-button.component';

@Component({
  selector: 'dgoods-terms-of-service',
  templateUrl: './dgoods-terms-of-service.component.html',
  styleUrls: ['./dgoods-terms-of-service.component.scss']
})
export class DgoodsTermsOfServiceComponent implements OnInit, IDGoodsButtonDelegate {
  @Input() delegate?: IDGoodsButtonDelegate;
  constructor() { }

  ngOnInit() {
  }
  /**
   * IDGoodsTextButtonDelegate
   */
  onButtonClicked(sender: DGoodsButtonComponent): void {
    this.delegate.onButtonClicked(sender);
  }


}
