import { Injectable } from '@angular/core';
import * as fromActions from './../actions/admin-manageProductContent.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductContentUpdateRequest, ProductContentUpdateResponse } from '../models/manageProductContent/updateProductContent.model';
import { ManageProductsResponse } from '../models/manageProductContent/managedProductsResponse.model';
import { UpdateProductSettingsRequest } from '../models/manageProductContent/updateProductSettings.model';
import { ProductCategoriesUpdateRequest } from '../models/manageProductContent/updateProductCategories.model';
import { CategoryDto } from '@modeso/types__dgoods-products';


const debug = Debug('modeso:dgoods-lib-admin-fe:AdminManageProductContentProvider');
@Injectable({
    providedIn: 'root'
})
export class AdminManageProductContentProvider {

    constructor(private store: Store<fromFeature.AppState>) { }

    public getAllProducts$(): Observable<ManageProductsResponse> {
        debug("Get All products called");
        this.store.dispatch(fromActions.getAllProductContent());
        return this.store.pipe(select(fromFeature.selectFeatureProductContent));
    }

    public updateContentByProductLine$(content: ProductContentUpdateRequest): Observable<ManageProductsResponse> {
        this.store.dispatch(fromActions.updateContentByProductLine({ payload: content }));
        return this.store.pipe(select(fromFeature.selectFeatureProductContent));
    }

    public updateSettingsByProductLine$(activateRequest: UpdateProductSettingsRequest): Observable<ManageProductsResponse> {
        this.store.dispatch(fromActions.onUpdateSettingsByProductLine({ payload: activateRequest }));
        return this.store.pipe(select(fromFeature.selectFeatureProductContent));
    }

    public updateProductCategories(productLineName: string, categories: string[]) {
        const update: ProductCategoriesUpdateRequest = {
          productLineName,
          categories,
        }
        this.store.dispatch(fromActions.updateProductCategories({ payload: update }));
    }

    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(select(fromFeature.featureError),
            filter((error: HttpErrorResponse) => error != null)
        );
    }


}
